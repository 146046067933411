import classNames from 'classnames';
import useOutsideClick from 'hooks/useClickOutsideHook';
import { BsThreeDotsVertical } from 'react-icons/bs';

type Column = {
  id: string;
  label: string;
  visible: boolean;
  disable: boolean;
};

interface ColumnVisibilityProps {
  columns: Column[];
  handleChangeColumnVisibility: (id: string) => void;
  isColumnVisibility: boolean;
  setIsColumnVisibility: (val: boolean) => void;
}

export const ColumnVisibility = ({ columns, handleChangeColumnVisibility, isColumnVisibility, setIsColumnVisibility }: ColumnVisibilityProps) => {
  const columnVisibilityRef = useOutsideClick(() => {
    setIsColumnVisibility(false);
  });

  return (
    <>
      <div ref={columnVisibilityRef} className={`relative cursor-pointer rounded-md bg-white dark:bg-darkTwo  ${isColumnVisibility ? 'outline-menuBorder dark:outline-white' : 'outline-gray-300'}`}>
        <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setIsColumnVisibility(!isColumnVisibility)}>
          <BsThreeDotsVertical className="text-gray-700" size={14} />
        </button>
        {isColumnVisibility && (
          <div className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md border bg-white px-3 py-2 dark:border-darkBorder dark:bg-darkOne">
            {columns?.map((col: any, index: number, arr: any) => (
              <>
                <div key={index} className="flex items-center justify-between p-2">
                  <h5 className="text-xs dark:text-white">{col.label}</h5>
                  <label className="inline-flex cursor-pointer items-center">
                    <input type="checkbox" value="" className="peer sr-only" checked={col.visible} disabled={col?.disable} onChange={() => handleChangeColumnVisibility(col.id)} />
                    <div
                      className={classNames(
                        `peer relative h-4 w-7 rounded-full bg-gray-200 
                          after:absolute after:start-[2px] after:top-[2px] after:h-3 after:w-3
                          after:rounded-full after:border after:border-gray-300 
                          after:bg-white after:transition-all after:content-[''] 
                          peer-checked:after:translate-x-full 
                          peer-checked:after:border-white peer-focus:outline-none
                          peer-focus:ring-4 dark:border-gray-600 dark:bg-gray-700 
                          dark:peer-focus:ring-blue-800 rtl:peer-checked:after:-translate-x-full`,
                        {
                          'cursor-pointer': col?.visible && !col?.disable,
                          'cursor-not-allowed': col?.visible && col?.disable,
                          'peer-checked:bg-blue-600': col?.visible && !col?.disable,
                          'peer-checked:bg-blue-300': col?.visible && col?.disable,
                        }
                      )}
                    ></div>
                  </label>
                </div>
                {arr?.length - 1 !== index && <hr />}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
