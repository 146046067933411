import { HighChart } from 'components/common/Chart/HighChart';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import React, { useState } from 'react';
import { LeadContactsOverTime, EngagementRateSecondChart, HeatMapFun } from 'dto/initial-value/views/campaign-analytics';
import { useAppSelector } from 'redux-store';
import { BiChevronDown } from 'react-icons/bi';
import { CustomMenu } from 'components/common/customMenu';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import TimeZoneList from 'helpers/json/world-timezone-all-list.json';
import { FiSearch } from 'react-icons/fi';
import { _removeDuplicates } from 'utility/utils';
import { ColumnVisibility } from 'components/common/columnVisibility';
import moment from 'moment';

type DropdownItem = {
  name: string;
  icon: React.ReactNode | null;
  className: string;
  value: string;
};

const dropDownData: DropdownItem[] = [
  { name: 'Reply Rate', icon: null, className: '', value: 'repliedPct' },
  { name: 'Open Rate', icon: null, className: '', value: 'openedPct' },
];

const Engagement = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const heat_map_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.heat_map_data);
  const engagement_rate_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.engagement_rate_data);
  const engagement_total_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.engagement_total_data);
  const engagement_crm_total_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.engagement_crm_total_data);
  const engagement_crm_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.engagement_crm_data);
  const [cards, setCards] = React.useState([]);
  const [columns, setColumns] = useState([
    { id: 'totalSent', label: 'Total Sent', visible: true, disable: true },
    { id: 'totalOpened', label: 'Total Opened', visible: true, disable: true },
    { id: 'totalReplied', label: 'Total Replied', visible: true, disable: true },
    { id: 'totalUnsubscribed', label: 'Total Unsubscribed', visible: true, disable: false },
    { id: 'totalBounced', label: 'Total Bounced', visible: true, disable: false },
  ]);

  const getLeadContactSeries = () => {
    const crm_data =
      engagement_crm_total_data?.map((item: any) => ({
        id: `Stage - ${item?.stage_name}`,
        name: `Stage - ${item?.stage_name}`,
        data: engagement_crm_data?.map((crmData: any) => [moment(crmData?.date).format('YYYY/MM/DD'), crmData?.stages?.find((data: any) => data?.stage_name === item?.stage_name)?.stage_count || 0]) || [],
        color: item?.color,
      })) || [];
    return [
      {
        id: 'Total Sent',
        name: 'Total Sent',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.sent_count || 0])?.filter((item: any) => item[1]) || [],
        color: '#00D8B6',
      },
      {
        id: 'Total Opened',
        name: 'Opened',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.open_count || 0])?.filter((item: any) => item[1]) || [],
        color: '#008FFB',
      },
      {
        id: 'Total Replied',
        name: 'Replied',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.reply_count || 0])?.filter((item: any) => item[1]) || [],
        color: '#FFB01A',
      },
      {
        id: 'Total Unsubscribed',
        name: 'Unsubscribed',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.unsub_count || 0])?.filter((item: any) => item[1]) || [],
        color: '#775DD0',
      },
      {
        id: 'Total Bounced',
        name: 'Bounced',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.bounce_count || 0])?.filter((item: any) => item[1]) || [],
        color: '#9B59B6',
      },
      ...(crm_data && crm_data),
    ]?.filter((card) => columns.find((option) => option.label === card.id && option.visible));
  };

  const getEngagementSeries = () => {
    return [
      {
        name: 'Open Rate',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.open_pct || 0])?.filter((item: any) => item[1]) || [],
        color: '#10B981',
      },
      {
        name: 'Reply Rate',
        data: engagement_rate_data?.map((item: any) => [moment(item?.date).format('YYYY/MM/DD'), item?.replied_pct || 0])?.filter((item: any) => item[1]) || [],
        color: '#EF4444',
      },
    ];
  };

  const heatdata = () => {
    return [
      {
        borderWidth: 0,
        data: heat_map_data?.map((data: any) => [data?.dayOfWeek - 1, data?.timeSlot, data?.[dropDownData?.[socialState?.selectedSocialIndex]?.value]]),
        dataLabels: {
          enabled: true,
          color: '#000000',
          format: '{point.value}%',
        },
      },
    ];
  };

  const visibleCards = cards.filter((card) => columns.find((option) => option.label === card.label && option.visible));

  function handleChangeColumnVisibility(id: string) {
    setColumns((prevColumns) => prevColumns.map((col) => (col.id === id && !col.disable ? { ...col, visible: !col.visible } : col)));
  }

  const [isColumnVisibility, setIsColumnVisibility] = useState(false);

  const [socialState, setSocialState] = useState({
    open: false,
    selectedSocialIndex: 0,
  });

  const [timeZoneState, setTimeZoneState] = useState({
    open: false,
    selectedTimeZoneIndex: 0,
  });

  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    const crm_card_data = engagement_crm_total_data?.map((item: any) => ({ label: `Stage - ${item?.stage_name}`, value: item?.stage_count || 0, percentage: item?.stage_pct || 0, color: item?.color || '#00D8B6' })) || [];

    setCards([
      { label: 'Total Sent', value: engagement_total_data?.sent_count, percentage: engagement_total_data?.sent_pct || 0, color: '#00D8B6' },
      { label: 'Total Opened', value: engagement_total_data?.total_opened, percentage: engagement_total_data?.open_pct || 0, color: '#008FFB' },
      { label: 'Total Replied', value: engagement_total_data?.total_replied, percentage: engagement_total_data?.replied_pct || 0, color: '#FFB01A' },
      { label: 'Total Unsubscribed', value: engagement_total_data?.total_unsubscribed, percentage: engagement_total_data?.unsub_pct || 0, color: '#775DD0' },
      { label: 'Total Bounced', value: engagement_total_data?.total_bounced, percentage: engagement_total_data?.bounce_pct || 0, color: '#9B59B6' },
      ...crm_card_data,
    ]);
    const crm_toggle_data = engagement_crm_total_data?.map((item: any) => ({ id: item?.stage_name, label: `Stage - ${item?.stage_name}`, visible: false, disable: false })) || [];
    setColumns([
      { id: 'totalSent', label: 'Total Sent', visible: true, disable: true },
      { id: 'totalOpened', label: 'Total Opened', visible: true, disable: true },
      { id: 'totalReplied', label: 'Total Replied', visible: true, disable: true },
      { id: 'totalUnsubscribed', label: 'Total Unsubscribed', visible: true, disable: false },
      { id: 'totalBounced', label: 'Total Bounced', visible: true, disable: false },
      ...crm_toggle_data,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagement_rate_data, engagement_crm_total_data]);

  return (
    <div className={`grid grid-cols-6 gap-4 overflow-y-auto dark:bg-darkTwo`}>
      <div className={`col-span-6 flex h-[550px] flex-col rounded-md border-2 border-graybg p-3 dark:border-darkThree dark:text-white`}>
        <div className="flex items-center justify-between">
          <p className="pb-4 pl-[6px] pr-4 pt-4 text-xs font-medium dark:text-white">Lead Contacts Over Time</p>
          <ColumnVisibility columns={columns} handleChangeColumnVisibility={handleChangeColumnVisibility} isColumnVisibility={isColumnVisibility} setIsColumnVisibility={setIsColumnVisibility} />
        </div>
        <div className="mb-4 flex min-h-32 flex-nowrap gap-4 overflow-x-scroll dark:bg-darkTwo">
          {visibleCards.map((card, index) => (
            <div key={index} className="mb-2 flex w-full items-center justify-between rounded-lg border-2 border-graybg bg-white p-4 shadow-md dark:border-darkThree dark:bg-darkTwo dark:text-white" style={{ minWidth: 250 }}>
              <div className="mt-2 text-sm text-[#667085] dark:text-white">{card.label}</div>
              <CircularProgress value={card.percentage} size="90px" color={card.color}>
                <CircularProgressLabel className="flex flex-col items-center justify-center">
                  <span className="text-xs text-[#667085] dark:text-gray-400" style={{ fontSize: '10px', fontWeight: 500 }}>
                    {card.percentage}%
                  </span>
                  <div className="text-sm dark:text-white" style={{ fontSize: '14px', fontWeight: 700 }}>
                    {card.value}
                  </div>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          ))}
        </div>
        <HighChart title="" chartOptions={LeadContactsOverTime(theme)} series={getLeadContactSeries()} isDataNotExist={getLeadContactSeries()?.every((item) => item?.data?.length === 0)} />
      </div>
      <div className="col-span-2 flex h-[21.875rem]  flex-col rounded-md border-2 border-graybg px-3 pt-3 dark:border-darkThree dark:bg-darkTwo dark:text-white">
        <HighChart title="Engagement rate" chartOptions={EngagementRateSecondChart(theme)} series={getEngagementSeries()} isDataNotExist={getEngagementSeries()?.every((item: any) => item?.data?.length === 0)} />
      </div>
      <div className={`col-span-4 flex  h-[21.875rem] flex-col rounded-md border-2 border-graybg px-3 pb-0 pt-3 dark:border-darkThree dark:text-white`}>
        <div className="discla">
          <div className="disclaimer-container mb-2 flex items-center justify-between">
            <div className="flex items-center gap-1">
              <h2 className="text-xs font-medium dark:text-white">Engagement Heatmap by Day & Time</h2>
              <div className="group relative">
                <IoMdInformationCircleOutline className="text-column" />
                <div className="absolute left-1/2  top-full z-30 mt-1 hidden w-max -translate-x-1/2 transform rounded-md bg-heading px-3 py-2 text-xs text-white shadow-md group-hover:block">Displays peak engagement times throughout your week. Darker colors show higher activity levels.</div>
              </div>
            </div>
            <div className="doprdwonn flex gap-1">
              <CustomMenu
                open={socialState.open}
                onOpen={() => setSocialState((prev) => ({ ...prev, open: true }))}
                onClose={() => setSocialState((prev) => ({ ...prev, open: false }))}
                btnContent={
                  <div className="flex select-none items-center gap-2">
                    <div className="text-xs font-medium text-column">{dropDownData[socialState.selectedSocialIndex]?.name}</div>
                  </div>
                }
                btnClassName="text-nowrap !h-8"
                menuListClassName="!p-0.5 !h-auto overflow-y-auto w-full !mt-1"
                rightIcon={<BiChevronDown size={18} />}
              >
                {dropDownData.map((ele, index) => (
                  <div key={index} className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() => setSocialState((prev) => ({ ...prev, open: false, selectedSocialIndex: index }))}>
                    <div className="text-xs font-medium text-column">{ele?.name}</div>
                  </div>
                ))}
              </CustomMenu>
              <CustomMenu
                open={timeZoneState.open}
                onOpen={() => setTimeZoneState((prev) => ({ ...prev, open: true }))}
                onClose={() => setTimeZoneState((prev) => ({ ...prev, open: false }))}
                btnContent={
                  <div className="flex select-none items-center gap-2">
                    <div className="text-xs font-medium text-column">{TimeZoneList[timeZoneState.selectedTimeZoneIndex]?.utc_offset}</div>
                  </div>
                }
                btnClassName="text-nowrap !h-8 w-36 justify-between"
                menuListClassName="!p-0.5 !h-36 overflow-y-auto w-full !mt-1"
                rightIcon={<BiChevronDown size={18} />}
              >
                <div className="sticky top-0 m-0.5 flex w-full items-center rounded-md border">
                  <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
                  <input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} className="input input-bordered w-full rounded-md  p-1 pl-6 text-sm focus:outline-none dark:bg-darkTwo dark:text-white" />
                </div>
                {_removeDuplicates(TimeZoneList, ['utc_offset'])
                  ?.filter((zone) => zone?.utc_offset?.toLowerCase().includes(search.toLowerCase()))
                  .map((ele, index) => (
                    <div key={index} className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() => setTimeZoneState((prev) => ({ ...prev, open: false, selectedTimeZoneIndex: index }))}>
                      <div className="text-xs font-medium text-column">{ele?.utc_offset}</div>
                    </div>
                  ))}
              </CustomMenu>
            </div>
          </div>

          <HighChart title="" chartOptions={HeatMapFun(theme)} series={heatdata()} isDataNotExist={heatdata()?.[0]?.data?.length === 0} />
        </div>
      </div>
    </div>
  );
};

export default Engagement;
