import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { HighChart } from 'components/common/Chart/HighChart';
import Table from 'components/common/table/Table';
import TableSearchFilter from 'components/common/table/TableSearchFilter';
import { CampaignAnalyticsEngagementVSWordCountChartOption } from 'dto/initial-value/views/campaign-analytics';
import React from 'react';
import { useAppSelector } from 'redux-store';
import { channelData, tableData } from './Overview';
import { FaCodeBranch } from 'react-icons/fa';

const Content = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const columnHelper = createColumnHelper<any>();
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });
  const [height, setHeight] = React.useState(window.innerHeight);
  const [abTestingTooltip, setAbTestingTooltip] = React.useState(false);
  const [state, setState] = React.useState<{ trigger_type_obj: { [key: string]: 'yes' | 'no' }; sequence_data: any[]; sequence_table_data: any[] }>({
    trigger_type_obj: {},
    sequence_data: tableData,
    sequence_table_data: [],
  });

  const { trigger_type_obj, sequence_data, sequence_table_data } = state;

  const data = React.useMemo(
    () => [
      { id: 1, subject_line: '[Name], quick question about [Company]', sentRate: '45%', openRate: '45%', replyRate: '45%' },
      { id: 2, subject_line: '[Name], quick question about [Company]', sentRate: '45%', openRate: '45%', replyRate: '45%' },
      { id: 3, subject_line: '[Name], quick question about [Company]', sentRate: '45%', openRate: '45%', replyRate: '45%' },
      { id: 4, subject_line: '[Name], quick question about [Company]', sentRate: '45%', openRate: '45%', replyRate: '45%' },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('subject_line', {
        header: 'Subject Line Performance',
        cell: (info) => info.getValue(),
        meta: { customHeaderClass: 'text-left pl-4 text-xs font-medium w-40p', customBodyClass: 'text-left pl-4 text-[13px] font-medium w-40p' },
      }),
      columnHelper.accessor('openRate', {
        header: 'Open Rate',
        cell: (info) => {
          const value = info.getValue();
          const percentage = Number(value.replace('%', ''));
          return (
            <CircularProgress value={percentage} size={'40px'} color="#2E90FA">
              <CircularProgressLabel>{percentage}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-xs font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('replyRate', {
        header: 'Reply Rate',
        cell: (info) => {
          const value = info.getValue();
          const percentage = Number(value.replace('%', ''));
          return (
            <CircularProgress value={percentage} size={'40px'} color="#00D8B6">
              <CircularProgressLabel>{percentage}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-xs font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useReactTable<any>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getTableData = (sequences: any) => {
    const finalTableData: any[] = [];
    if (sequences?.length) {
      finalTableData?.push(sequences[0]);
      if (sequences?.length > 1) {
        const recursiveFnToGetOrderSequenceData = (next: string, yes_trigger: string, no_trigger: string, trigger_type: 'yes' | 'no') => {
          if (!next && !yes_trigger && !no_trigger) return;
          if (next) {
            const findSequence = sequences?.find((sequence: any) => sequence?.id === next);
            if (findSequence) {
              finalTableData?.push(findSequence);
              recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
            }
          } else if (trigger_type === 'yes') {
            if (yes_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === yes_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          } else if (trigger_type === 'no') {
            if (no_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === no_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          }
        };
        recursiveFnToGetOrderSequenceData(sequences[0]?.next, sequences[0]?.trigger?.yes, sequences[0]?.trigger?.no, state?.trigger_type_obj[sequences[0]?.id] || 'yes');
      }
    }

    return finalTableData;
  };

  React.useEffect(() => {
    const sequence = getTableData(sequence_data);
    const sequence_trigger_type_obj = sequence_table_data?.reduce((acc, item) => {
      if (item?.trigger) acc[item?.id] = trigger_type_obj[item?.id] || 'yes';
      return acc;
    }, {});

    setState((prev) => {
      const prev_trigger_obj = JSON.stringify(prev?.trigger_type_obj);
      const new_trigger_obj = JSON.stringify(sequence_trigger_type_obj);
      const final_trigger_obj = prev_trigger_obj === new_trigger_obj ? prev?.trigger_type_obj : sequence_trigger_type_obj;

      return { ...prev, sequence_table_data: sequence, trigger_type_obj: final_trigger_obj };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger_type_obj]);

  const handleMouseMove = (e: any) => {
    setAbTestingTooltip(true);
    setCoords({ x: e.clientX, y: e.clientY });
  };
  const handleMouseLeave = () => {
    setAbTestingTooltip(false);
    setCoords({ x: 0, y: 0 });
  };

  React.useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log('coords', coords);

  const tooltipCondition = height < coords?.y + 140;
  return (
    <div className={`grid grid-cols-2 gap-4 overflow-y-auto`}>
      <div className="col-span-2">
        <h4 className="px-1 text-[13px] font-bold">A/B testing</h4>
      </div>
      <div className="col-span-1 flex h-[21.875rem] flex-col overflow-y-auto rounded-md border-2 border-graybg">
        <table className="w-full rounded-md">
          <thead className="border-b bg-grayBorder text-[11px] text-column">
            <td className="w-52 bg-grayBorder p-3">Channel</td>
            <td className="w-20 bg-grayBorder p-3">Step no</td>
            <td className="bg-grayBorder p-3">Replied Rate</td>
          </thead>
          <tbody>
            {sequence_table_data?.map((ele: any, index: number) => {
              const channel = channelData?.find((item) => item.id === ele?.type);
              return (
                <>
                  <tr className="h-12 border-b">
                    <td className="p-4 text-xs">
                      <div className="flex w-full gap-1">
                        <div className={`${channel?.color} text-[14px]`}>{channel?.icon}</div>
                        <div className="text-[12px] font-medium text-[#101828]">{channel?.name}</div>
                      </div>
                    </td>
                    <td className="p-4 text-[12px] font-medium text-[#101828]">Step {index + 1}</td>
                    <td className="py-2 text-xs">
                      <div className="px-4" style={{ width: '70%' }} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                        <div className="mt-1.5 h-4 w-full bg-skyBlue">
                          <span className="pl-3 font-medium">A</span>
                        </div>
                      </div>
                      <div className="mt-1.5 border"></div>
                      <div className="px-4" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                        <div className="mt-1.5 h-4 w-full bg-darkSkyBlue">
                          <span className="pl-3 font-medium">B</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {ele?.trigger && (
                    <tr className="h-12 border-b">
                      <td className="bg-gray-750 p-4">
                        <div className="flex w-full items-center justify-start gap-3">
                          <div className={`flex justify-center text-xs`}>
                            <FaCodeBranch />
                          </div>
                          <div className="text-center text-[12px] font-medium text-[#101828]">Cond.</div>
                          <div className="w-full">
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'yes' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-white text-column' : 'bg-blueSecondary text-white'}`}>
                              Yes
                            </button>
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'no' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-blueSecondary text-white' : 'bg-white text-column'}`}>
                              No
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="bg-gray-750"></td>
                      <td className="bg-gray-750"></td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-span-1 flex h-[21.875rem] flex-col rounded-md border-2 border-graybg p-3">
        <HighChart
          title="Engagement vs word count"
          chartOptions={CampaignAnalyticsEngagementVSWordCountChartOption(theme)}
          isDataNotExist={false}
          series={[
            {
              name: 'Engagement',
              data: [
                [10, 20],
                [20, 50],
                [40, 50],
                [60, 60],
                [80, 90],
              ],
            },
          ]}
        />
      </div>
      <div className="col-span-2 h-[26.875rem] overflow-y-auto rounded-md border-2 border-graybg">
        <div className="mb-4 ml-4 mr-0 mt-4">
          <TableSearchFilter />
        </div>
        <Table customClass="h-[22.5rem]" table={table} data={data} tableDataListStatus={true} />
      </div>
      {abTestingTooltip && (
        <div className="absolute z-50 h-[115px] w-[7.5rem] rounded-md bg-heading p-2 text-[10px] font-normal text-white" style={{ left: coords?.x - 60, top: tooltipCondition ? coords?.y - 125 : coords?.y + 15 }}>
          <div className="mt-2 flex justify-between">
            <span>Channel</span>
            <span>Email</span>
          </div>
          <div className="mt-2 flex justify-between">
            <span>Step</span>
            <span>1</span>
          </div>
          <div className="mt-2 flex justify-between border-b border-[#FFFFFF29]"></div>
          <div className="mt-2 flex justify-between">
            <span>Variation</span>
            <span>A</span>
          </div>
          <div className="mt-2 flex justify-between">
            <span>Reply Rate</span>
            <span>70%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
