import Tabs from 'components/common/Tabs';
import React from 'react';
import { MdCheck, MdOutlineHourglassEmpty, MdOutlineWatchLater, MdPauseCircle } from 'react-icons/md';
import moment from 'moment';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux-store';
import { Spinner } from '@chakra-ui/react';
import { BaseApi } from 'api/services/base-api';
import { useDispatch } from 'react-redux';
import { clearAllData, setCampaignDetilsInANalytics, setCommonDetailsInAnalytics, setGraphDataInAnalytics, setInitiallyLoadedComponentDataInAnalytics } from 'redux-store/campaign-analytics';

const CampaignAnalyticsHomePage = () => {
  const campaign_details = useAppSelector((state) => state?.campaignAnalytics?.campaign_details);
  const initially_loaded = useAppSelector((state) => state?.campaignAnalytics?.common?.initially_loaded);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaign_id } = useParams();
  const location = useLocation();
  const { pathname } = location;

  const [state, setState] = React.useState({
    isInitiallyDataFetching: false,
  });

  const { isInitiallyDataFetching } = state;

  const tabsData = [
    { id: 'analytics', name: 'Analytics' },
    { id: 'prospects', name: 'Prospects' },
    { id: 'inbox', name: 'Inbox' },
    { id: 'sequence', name: 'Sequence' },
    { id: 'settings', name: 'Settings' },
  ];

  React.useEffect(() => {
    (async () => {
      try {
        setState((prev) => ({ ...prev, isInitiallyDataFetching: true }));
        const response = await BaseApi.get(`/campaign/v2/list/single/${campaign_id}`);
        if (response?.data) {
          dispatch(setCampaignDetilsInANalytics(response?.data));
        } else {
          navigate('/campaigns');
        }
      } catch (error) {
        console.log('CampaignAnalyticsHomePage campaign details fetching error: ', error);
      } finally {
        setState((prev) => ({ ...prev, isInitiallyDataFetching: false }));
      }
    })();

    return () => {
      dispatch(clearAllData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const end_date = moment().endOf('day').utc().format();
    const rout_url = pathname.split('/').filter(Boolean).pop();
    (async () => {
      if (!campaign_details?.campaign_start_date) return;
      const initially_loaded_data = [];
      const graph_data: any = {};
      try {
        dispatch(setCommonDetailsInAnalytics({ loading: true }));
        // Overview Api
        // 1) Funnel Api && CRM Stage Progression
        if (rout_url === 'overview' && !initially_loaded?.includes('overview')) {
          const params = {
            campaign_id,
            from_date: moment.utc(campaign_details?.campaign_start_date).startOf('day').format(),
            to_date: end_date,
          };
          const response = await Promise.all([BaseApi.post('campaign/analytics/crm-count', params), BaseApi.post('campaign/analytics/sequence-data', params)]);
          initially_loaded_data?.push('overview');
          if (response?.length) {
            graph_data['funnel'] = response[0]?.data?.total || null;
            graph_data['crm_stage_progression'] = response[0]?.data?.date_wise || null;
            graph_data['multisequence_flow_data'] = response[1]?.data || null;
          }
        }
        // 1) Sender Account
        if (rout_url === 'sender-account' && !initially_loaded?.includes('sender-account')) {
          const params = {
            campaign_id,
            from_date: moment.utc(campaign_details?.campaign_start_date).startOf('day').format(),
            to_date: end_date,
            variant_type: 'email',
            list_type: 'domain',
          };
          const response = await BaseApi.post('campaign/analytics/domain-sender-data', params);
          initially_loaded_data?.push('sender-account');
          if (response?.data) {
            graph_data['sender_account_graph_data'] = response?.data || null;
          }
        }

        // 1) Engagement Rate Api
        if (rout_url === 'engagement' && !initially_loaded?.includes('engagement')) {
          const params = {
            campaign_id,
            from_date: moment.utc(campaign_details?.campaign_start_date).startOf('day').format(),
            to_date: end_date,
            crm_chart: 'true',
          };
          const params2 = {
            campaign_id: campaign_details?.uuid,
            from_date: moment.utc(campaign_details?.campaign_start_date).startOf('day').format(),
            to_date: end_date,
          };

          const response = await Promise.all([BaseApi.post('campaign/analytics/lead-contact', params), BaseApi.post('campaign/analytics/heat-map', params2)]);
          initially_loaded_data?.push('engagement');
          if (response?.length) {
            graph_data['engagement_total_data'] = response[0]?.data?.leads?.total || null;
            graph_data['engagement_rate_data'] = response[0]?.data?.leads?.date_wise || null;
            graph_data['engagement_crm_data'] = response[0]?.data?.crm?.date_wise || null;
            graph_data['engagement_crm_total_data'] = response[0]?.data?.crm?.total || null;
            graph_data['heat_map_data'] = response[1]?.data || null;
          }
        }

        // 1) Content Api
        if (rout_url === 'content' && !initially_loaded?.includes('content')) {
          const params = {
            campaign_id,
            from_date: moment.utc(campaign_details?.campaign_start_date).startOf('day').format(),
            to_date: end_date,
            variant_type: 'email',
          };

          const response = await Promise.all([BaseApi.post('campaign/analytics/sequence-data-version', params), BaseApi.post('campaign/analytics/word-count', params), BaseApi.post('campaign/analytics/subject-count', params)]);
          initially_loaded_data?.push('content');
          if (response?.length) {
            graph_data['ab_testing_data'] = response[0]?.data || null;
            graph_data['engagement_vs_word_count_data'] = response[1]?.data || null;
            graph_data['subject_table_data'] = response[2]?.data || null;
          }
        }
      } catch (error) {
        console.log('CampaignAnalyticsHomePage campaign details fetching error: ', error);
      } finally {
        dispatch(setGraphDataInAnalytics(graph_data));
        dispatch(setInitiallyLoadedComponentDataInAnalytics(initially_loaded_data));
        dispatch(setCommonDetailsInAnalytics({ loading: false }));
      }
    })();
    dispatch(setCommonDetailsInAnalytics({ path: pathname, children_path: rout_url }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, campaign_details]);

  return (
    <>
      <div className={`${isInitiallyDataFetching ? 'pointer-events-none blur-sm' : ''}`}>
        <div className="flex justify-between">
          <h4 className="py-3 pl-3 pr-1 text-[1.01rem] font-bold text-heading dark:text-white">{campaign_details?.campaign_name || ''}</h4>
          <div className="flex items-center gap-3">
            <span className="text-[13px] font-bold text-heading dark:text-white">Created on : {moment(campaign_details?.cdate || null).format('DD MMMM YYYY hh:mm A')} </span>
            <>
              {campaign_details?.campaign_status === 0 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1 dark:bg-darkDraft-bg">
                  <MdOutlineWatchLater className="text-xs text-draft-color dark:text-darkDraft-color" />
                  <h5 className={`text-xs font-semibold text-draft-color dark:text-darkDraft-color`}>Draft</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1 dark:bg-darkActive-bg">
                  <MdOutlineHourglassEmpty className="text-xs text-active-color dark:text-darkActive-color" />
                  <h5 className={`text-xs font-semibold text-active-color dark:text-darkActive-color`}>Active</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1 dark:bg-darkRejected-bg">
                  <MdOutlineWatchLater className="text-xs text-rejected-color dark:text-darkRejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color dark:text-darkRejected-color`}>Rejected</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-paused-bg px-2 py-1 dark:bg-darkPaused-bg">
                  <MdPauseCircle className="text-xs text-paused-color dark:text-darkPaused-color" />
                  <h5 className={`text-xs font-semibold text-paused-color dark:text-darkPaused-color`}>Paused</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 4 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1 dark:bg-darkCompleted-bg">
                  <MdCheck className="text-xs text-completed-color dark:text-darkCompleted-color" />
                  <h5 className={`text-xs font-semibold text-completed-color dark:text-darkCompleted-color`}>Completed</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 5 && (
                <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-yellow-100 px-2 py-1 dark:bg-yellow-700">
                  <MdOutlineWatchLater className="text-xs text-yellow-700 dark:text-yellow-100" />
                  <h5 className={`text-xs font-semibold text-yellow-700 dark:text-yellow-100`}>In Progress</h5>
                </div>
              )}
              {campaign_details?.campaign_status === 6 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1 dark:bg-darkRejected-bg">
                  <MdPauseCircle className="text-xs text-rejected-color dark:text-darkRejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color dark:text-darkRejected-color`}>Error</h5>
                </div>
              )}
            </>
          </div>
        </div>
        <Tabs tabs={tabsData} />
        <>
          <Outlet />
        </>
      </div>
      {isInitiallyDataFetching ? (
        <div className="absolute left-1/2 top-1/2">
          <Spinner size={'lg'} textColor={'GrayText'} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CampaignAnalyticsHomePage;
