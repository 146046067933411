import React from 'react';
import { MdOutlineCalendarToday } from 'react-icons/md';
import DateRangeSelector from './DateRangeSelector';
import moment from 'moment';
import { Spinner } from '@chakra-ui/react';

const CustomDatePicker = (props: any) => {
  const { customClass = '', customSelectedDateClass = '', onChange = (date: any) => {}, loading = false, initial_date = [] } = props;
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [selectedDate, setDate] = React.useState(initial_date);

  React.useEffect(() => {
    selectedDate?.length && onChange?.(selectedDate, setShowCalendar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  React.useEffect(() => {
    initial_date?.length && setDate(initial_date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial_date]);

  const handleClick = () => {
    !loading && setShowCalendar(true);
  };
  return (
    <div className={`min-w-28: relative ${customClass}`}>
      <div className={`border-2 ${showCalendar ? 'border-menuBorder' : 'border-gray-100'} ${loading ? '' : 'cursor-pointer hover:border-menuBorder'} flex h-8 cursor-pointer  items-center gap-2 rounded-md bg-white px-3 py-3.5 shadow-bottom dark:border-darkThree dark:bg-darkOne  dark:hover:border-darkTwo  `} onClick={handleClick}>
        <MdOutlineCalendarToday className="size-4 text-[#667085]" />
        <h5 className={`font-medium text-[#667085] ${customSelectedDateClass} select-none`}>{selectedDate?.length ? `${moment(selectedDate[0]).format('YYYY/MM/DD')} - ${moment(selectedDate[1]).format('YYYY/MM/DD')}` : 'Select Date'}</h5>
        {/* <>{ queryParams?.start_date || queryParams?.end_date ? <></>: <RxCross2 className="size-4 text-[#667085]" onClick={()=>{}}/>}</> */}
        {loading ? <Spinner size={'sm'} textColor={'GrayText'} /> : <></>}
      </div>
      {!loading && showCalendar && <DateRangeSelector selectedDate={selectedDate?.length ? selectedDate : [new Date(), new Date()]} setSelectedDate={setDate} setShowCalendar={setShowCalendar} right="right-0" top="top-9" />}
    </div>
  );
};

export default CustomDatePicker;
