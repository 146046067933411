import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import SkeletonLoader from './SkeletonLoader';
import InfiniteScrollSkeletonLoader from './InfiniteScrollSkeletonLoader';

const Table = ({ table, data, customClass = 'h-[75vh]', numColumns = 10, numRowsLoader = 5, numRowsScrollLoader = 3, source = '', tableDataListStatus = false, onRowClick }: any) => {
  const tableRef = React.useRef(null);
  return (
    <div className={`w-full rounded-lg bg-white shadow-sm dark:bg-darkThree  dark:text-white sm:overflow-x-auto`}>
      <div className={`${customClass} overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden`} ref={tableRef}>
        <table className=" border-collapse sm:w-[150%] lg:w-[120%] xl:w-full">
          <TableHeader table={table} />
          {tableDataListStatus ? <TableBody data={data || []} table={table} handleClickAction={onRowClick} /> : <SkeletonLoader numColumns={numColumns} numRows={numRowsLoader} />}
          <InfiniteScrollSkeletonLoader numColumns={numColumns} numRows={numRowsScrollLoader} tableRef={tableRef} source={source} />
        </table>
      </div>
    </div>
  );
};

export default Table;
