/* eslint-disable @typescript-eslint/no-unused-vars */

import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, useDisclosure } from '@chakra-ui/react';
import Button from 'components/common/Button';
import TextEditor from 'components/common/TextEditor';
import Toggle from 'components/common/Toggle';
import InputField from 'components/forms/InputField';
import React, { useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaSignature } from 'react-icons/fa6';
import { IoMdSettings, IoIosHelpCircleOutline } from 'react-icons/io';
import { MdAttachEmail, MdAccountTree, MdCopyAll } from 'react-icons/md';
import { useAppDispatch } from 'redux-store';
import { updateTableQueryState } from 'redux-store/common-table';
import { UpdateSingleSenderAccountApi } from 'shared/sender-accounts/api-handler/email-account-api';
import { _emptyObject } from 'utility/utils';

const DomainDrawer = ({ isOpen, onClose , data }: { isOpen: boolean;data:any; onClose: () => void }) => {
//   const dispatch = useAppDispatch();
  const onCloseEvent = () => {
    onClose();
  };

  // const drawerData: { record_type: string; current_policy: string; status: string }[] = [
  //   { record_type: 'DMARC', current_policy: 'Not Listed', status: '' },
  //   { record_type: 'DKIM', current_policy: 'Not Listed', status: '' },
  //   { record_type: 'SPF', current_policy: 'Not Listed', status: '' },
  //   { record_type: 'MX', current_policy: 'Listed', status: 'Delist' },
  //   { record_type: 'MTA-STS', current_policy: 'Listed', status: 'Delist' },
  // ];

  return (
    <>
      {/* Right Drawer for 3 widget */}
      <Drawer placement="right" onClose={onCloseEvent} isOpen={true} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          {/* <DrawerHeader borderBottomWidth="1px" className="!px-3 !py-4 ">
                       
                    </DrawerHeader> */}

          <DrawerBody className="!px-0 !py-0 ">
            <div className="mt-4 flex flex-col items-center justify-between ">
              <div className="flex h-[40px]  w-full flex-row rounded-t-md items-center justify-between bg-[#F9FAFB] px-6">
                <h5 className="flex w-2/4 items-start whitespace-pre text-xs text-[#667085]">Record Type</h5>
                <h5 className="w-1.5/4 flex justify-center whitespace-pre text-xs text-[#667085]">Current Policy</h5>
                <h5 className="w-0.5/4 flex justify-end whitespace-pre text-end text-xs text-[#667085]">Status</h5>
              </div>
              {data?.blacklisted_result?.map((item:{blacklist_domain:string,status:string}, index:number) => (
                <div key={index} className="flex h-[45px] w-full flex-row items-center justify-between border border-b px-6">
                  <h5 className="flex w-[405px] items-start text-xs font-semibold text-[#2B3674]">{item?.blacklist_domain}</h5>
                  <h5 className="flex w-[100px] justify-start p-1  px-2 py-1  text-center text-xs text-[#667085]">
                  <span className={`rounded-full ${item?.status === 'Not Listed' ? ' bg-green-100 text-green-800' : ' bg-red-100 text-red-800'} px-1.5 py-0.5  !text-xs`}>{item?.status}</span>
                  </h5>
                  <h5 className="flex w-[100px] justify-end text-end text-xs font-medium text-[#2B3674]">{item?.status==="Not Listed"? "":"Delist"}</h5>
                </div>
              ))}
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DomainDrawer;
