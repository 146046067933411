import { createSlice } from '@reduxjs/toolkit';
import { EmailVerifierState } from 'dto/types/redux-store/email-verifier';

const initialState: EmailVerifierState = {
  list_name: '',
  email_list: [],
  listType: 'upload_csv',
  integrationName: 'mailchimp',
  integrationList: [],
  accountId: '',
  subscriberListId: '',
  accountList: [],
  totalSubscriberList: [],
  stepCount: 1,
  tempFileData: {
    file_name: '',
    file_data: [],
  },
  verificationModeId: 1
};
const emailVerifierSlice = createSlice({
  name: 'emailVerifier',
  initialState,
  reducers: {
    setListName: (state, action) => {
      state.list_name = action.payload;
    },
    setEmailVerifierList: (state, action) => {
      state.email_list = action.payload;
    },
    setTempFileData: (state, action) => {
      state.tempFileData = action.payload;
    },
    setListType: (state, action) => {
      state.listType = action.payload;
    },
    setStepCount: (state, action) => {
      state.stepCount = action.payload;
    },
    setIntegrationName: (state, action) => {
      state.integrationName = action.payload;
    },
    setIntegrationList: (state, action) => {
      state.integrationList = action.payload;
    },
    setTotalSubscriberList: (state, action) => {
      state.totalSubscriberList = action.payload;
    },
    setAccountList: (state, action) => {
      state.accountList = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setSubscriberListId: (state, action) => {
      state.subscriberListId = action.payload;
    },
    setVerificationModeId: (state, action) => {
      state.verificationModeId = action.payload;
    },

    clearEmailVerifierData: (state) => {
      state.listType = 'upload_csv';
      state.list_name = '';
      state.accountId = '';
      state.subscriberListId = '';
      state.accountList = [];
      state.totalSubscriberList = [];
      state.email_list = [];
      state.stepCount = 1
      state.tempFileData = {
        file_name: '',
        file_data: [],
      }
    },
  },
});

export const { setEmailVerifierList, setListName, clearEmailVerifierData, setTempFileData, setListType, setStepCount, setAccountList, setIntegrationName, setIntegrationList, setSubscriberListId, setTotalSubscriberList, setAccountId,setVerificationModeId } = emailVerifierSlice.actions;

export default emailVerifierSlice.reducer;
