import Tabs from 'components/common/Tabs';
import React from 'react';
import { MdCheck, MdOutlineHourglassEmpty, MdOutlineWatchLater, MdPauseCircle } from 'react-icons/md';
import AnalyticsTab from '../components/analytics';
import moment from 'moment';

const CampaignAnalyticsHomePage = () => {
  const [state, setState] = React.useState({
    tab: 0,
  });
  const { tab } = state;

  React.useEffect(() => {
    return () => {
      sessionStorage?.removeItem('campaign_details');
    };
  }, []);

  const tabsData = [
    { id: 'analytics', name: 'Analytics' },
    { id: 'prospects', name: 'Prospects' },
    { id: 'inbox', name: 'Inbox' },
    { id: 'sequence', name: 'Sequence' },
    { id: 'settings', name: 'Settings' },
  ];

  const campaign_details = JSON.parse(sessionStorage?.getItem("campaign_details"));
  console.log("campaign_details===============================>",campaign_details)
  return (
    <div>
      {/* Campaign name */}
      <div className="flex justify-between">
        <h4 className="py-3 pl-3 pr-1 text-[1.01rem] font-bold text-heading">{campaign_details?.campaign_name}</h4>
        <div className="flex items-center gap-3">
          <span className="text-[13px] font-bold text-heading">Created on : {moment(campaign_details?.cdate).format("DD MMMM YYYY HH:MM A")} </span>
          <>
            {campaign_details?.campaign_status === 0 && (
               <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                 <MdOutlineWatchLater className="text-xs text-draft-color" />
                 <h5 className={`text-xs font-semibold text-draft-color`}>Draft</h5>
               </div>
             )}
            {campaign_details?.campaign_status === 1 && (
            <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1">
              <MdOutlineHourglassEmpty className="text-xs text-active-color" />
              <h5 className={`text-xs font-semibold text-active-color`}>Active</h5>
            </div>
            )}
            {campaign_details?.campaign_status === 2 && (
               <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                 <MdOutlineWatchLater className="text-xs text-rejected-color" />
                 <h5 className={`text-xs font-semibold text-rejected-color`}>Rejected</h5>
               </div>
             )}
             {campaign_details?.campaign_status === 3 && (
               <div className="flex w-min items-center gap-0.5 rounded-full bg-paused-bg px-2 py-1">
                 <MdPauseCircle className="text-xs text-paused-color" />
                 <h5 className={`text-xs font-semibold text-paused-color`}>Paused</h5>
               </div>
             )}
             {campaign_details?.campaign_status === 4 && (
               <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                 <MdCheck className="text-xs text-completed-color" />
                 <h5 className={`text-xs font-semibold text-completed-color`}>Completed</h5>
               </div>
             )}
             {campaign_details?.campaign_status === 5 && (
               <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-yellow-100 px-2 py-1">
                 <MdOutlineWatchLater className="text-xs text-yellow-700" />
                 <h5 className={`text-xs font-semibold text-yellow-700`}>In Progress</h5>
               </div>
             )}
             {campaign_details?.campaign_status === 6 && (
               <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                 <MdPauseCircle className="text-xs text-rejected-color" />
                 <h5 className={`text-xs font-semibold text-rejected-color`}>Error</h5>
               </div>
             )}
          </>
        </div>
      </div>

      {/* Tabs Header*/}
      <Tabs tabs={tabsData} onChange={(tabIndex: number) => setState((prev) => ({ ...prev, tab: tabIndex }))} />

      {/* Tabs */}
      {tab === 0 && <AnalyticsTab />}
    </div>
  );
};

export default CampaignAnalyticsHomePage;
