import { DOWNLOAD_ED_DOMAINIP_EXCELDATA, ED_IPDOMAIN_DELETE, ED_IPDOMAIN_VALIDATE, ED_RECAPTCHA_VALIDATE, GET_ONE_ED_IPDOMAIN } from 'api/endpoints/edIpDomainEndpoints';
import { BaseApi } from 'api/services/base-api';
import { AxiosResponse } from 'axios';

export const EDSingleValidateAPI = async (data: any) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(ED_IPDOMAIN_VALIDATE, data);
    return response;
  } catch (error) {
    console.error('ED ipdomain singlevalidation error occured ', error);
    throw error;
  }
};

export const GetOneEDSingleValidateAPI = async (data: string) => {
  try {
    const getUrl = GET_ONE_ED_IPDOMAIN(data);
    const response = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('EdSingkeIpDomainListGetOneApi Error:', error);
    throw error;
  }
};

export const DeleteEDIPDomainData = async (uuids: string[]) => {
  try {
    const getUrl = ED_IPDOMAIN_DELETE;
    const response = await BaseApi.delete(getUrl, {
      data: { delete_uuid: uuids },
    });
    return response;
  } catch (error) {
    console.error('ED IP Domain List DeleteApi Error:', error);
    throw error;
  }
};

export const GetDomainIPDataDownload = async (rowDetails: any) => {
  try {
    const getUrl = DOWNLOAD_ED_DOMAINIP_EXCELDATA;
    const response = await BaseApi.post(getUrl, { uuids: rowDetails });

    return response;
  } catch (error) {
    console.error('ED Domain IP Download API Error:', error);
    throw error;
  }
};

export const EDSingleRECPATCHAValidateAPI = async (token: any) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(ED_RECAPTCHA_VALIDATE, { token });
    return response;
  } catch (error) {
    console.error('ED ipdomain singlevalidation error occured ', error);
    throw error;
  }
};
