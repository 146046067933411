/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { _getQueryParams } from 'utility/utils';
import { IoIosArrowRoundBack, IoMdCloudDone, IoMdLock } from 'react-icons/io';
import Button from 'components/common/Button';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { ReportAccordationPopupStateIV } from 'dto/initial-value/views/health-check';
import { AccordionState } from 'dto/types/views/notification';
import React, { useEffect, useState } from 'react';
import { BiChevronDown, BiShieldQuarter } from 'react-icons/bi';
import { PiGlobe } from 'react-icons/pi';
import { MdMailLock } from 'react-icons/md';
import { FaMedal } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { GetOneEDSingleValidateAPI } from 'shared/ed-ipdomain/api-hanlder';
import { getDnsStatus, validateDKIM, validateDMARC, validateMX, validateSPF } from 'shared/ed-ipdomain/event-handler';
import DomainIPDrawer from './DomainIP_BlackList_Drawer';

const DomainMonitoring = () => {
  const [domainName, setDomainName] = React.useState<string>('');
  const [report, setReport] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [domain_healthscore, setDomainHealthScore] = useState<number>(0);

  const navigate = useNavigate();

  const [openAccordion, setOpenAccordion] = React.useState<AccordionState>(ReportAccordationPopupStateIV);

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState?.activeIndex === index ? null : index,
    }));
  };

  const iconMapping = {
    DMARC: PiGlobe,
    DKIM: MdMailLock,
    SPF: IoMdLock,
    MX: FaMedal,
    'MTA-STS': BiShieldQuarter,
  };

  const statusIconMapping = {
    pass: IoMdCloudDone,
    warning: RiErrorWarningFill,
    'no records': IoCloseCircle,
  };

  const statusColorMapping = {
    pass: { bg: 'bg-green-100', text: 'text-green-600', icon: '#16A34A' }, // Green
    warning: { bg: 'bg-yellow-100', text: 'text-yellow-600', icon: '#F59E0B' }, // Yellow
    'no records': { bg: 'bg-red-100', text: 'text-red-600', icon: '#DC2626' }, // Red
  };

  type Status = 'pass' | 'no records' | 'warning';

  const mapDnsStatusToUIStatus = (dnsStatus: 'success' | 'error' | 'medium'): Status => {
    switch (dnsStatus) {
      case 'success':
        return 'pass';
      case 'error':
        return 'no records';
      case 'medium':
      default:
        return 'warning';
    }
  };

  const calculateDmarcStatus = (dmarcArr: any): Status => {
    const dmarcRecord = Array.isArray(dmarcArr) && dmarcArr.length > 0 ? dmarcArr[0] : '';
    const rawDmarcStatus = getDnsStatus(dmarcRecord, validateDMARC);
    let dmarcStatus = mapDnsStatusToUIStatus(rawDmarcStatus);

    if (rawDmarcStatus === 'success' && Array.isArray(dmarcArr) && dmarcArr.length > 1) {
      dmarcStatus = 'warning';
    }

    return dmarcStatus;
  };

  // const calculateDkimStatus = (dkimArr: any): Status => {
  //   const dkimRecord = Array.isArray(dkimArr) && dkimArr.length > 0 ? dkimArr[0]?.records : '';
  //   const rawDkimStatus = getDnsStatus(dkimRecord, validateDKIM);
  //   let dkimStatus = mapDnsStatusToUIStatus(rawDkimStatus);

  //   if (rawDkimStatus === 'success' && dkimArr[0]?.records?.[0]?.[0]?.length < 255) {
  //     dkimStatus = 'warning';
  //   }

  //   return dkimStatus;
  // };

  const calculateDkimStatus = (dkimArr: any): Status => {
    if (!Array.isArray(dkimArr) || dkimArr.length === 0) {
      return 'no records';
    }

    dkimArr.map((dkimItem) => {
      // Each item has a 'records' array (possibly an array of arrays)
      const records = dkimItem?.records || [];
      records.map((recordItem: any) => {
        if (Array.isArray(recordItem)) {
          // recordItem is an array of strings
          recordItem.map((str) => {
            if (str && str.length < 255) {
              return 'warning';
            }
          });
        } else {
          // recordItem is a single string
          if (recordItem && recordItem?.length < 255) {
            return 'warning';
          }
        }
      });
    });

    return 'pass';
  };

  const calculateSpfStatus = (spfArr: any): Status => {
    const spfRecord = Array.isArray(spfArr) && spfArr.length > 0 ? spfArr[0] : '';
    const rawSpfStatus = getDnsStatus(spfRecord, validateSPF);
    let spfStatus = mapDnsStatusToUIStatus(rawSpfStatus);

    if (rawSpfStatus === 'success' && Array.isArray(spfArr) && spfArr.length > 1) {
      spfStatus = 'warning';
    }

    return spfStatus;
  };

  const calculateMxStatus = (mxArr: any[]): any => {
    let mxStatus = 'warning';

    if (Array.isArray(mxArr) && mxArr.length > 0) {
      const allValid = mxArr.every((mxObj: any) => {
        const mxString = `${mxObj.exchange}`;
        return validateMX(mxString);
      });

      mxStatus = allValid ? 'pass' : 'no records';
    }

    return mxStatus;
  };

  React.useEffect(() => {
    const storedUuid = sessionStorage.getItem('healthCheckUUID');
    const fetchData = async () => {
      const response = await GetOneEDSingleValidateAPI(storedUuid);
      if (!response?.error) {
        setReport(response?.data);
        setDomainName(response?.data?.blacklist_name);
        let healthScore = 0;
        if (response?.data?.dns_records) {
          const dmarcArr = response.data.dns_records.dmarc;
          const dkimArr = response.data.dns_records.dkim;
          const spfArr = response.data.dns_records.spf;
          const mxArr = response.data.dns_records.mx;

          const dmarcStatus = calculateDmarcStatus(dmarcArr);
          const dkimStatus = calculateDkimStatus(dkimArr);
          const spfStatus = calculateSpfStatus(spfArr);
          const mxStatus = calculateMxStatus(mxArr);

          if (dkimStatus === 'pass') {
            healthScore += 20;
          } else if (dkimStatus === 'warning') {
            healthScore += 10;
          }

          if (dmarcStatus === 'pass') {
            healthScore += 20;
          } else if (dmarcStatus === 'warning') {
            healthScore += 10;
          }

          if (spfStatus === 'pass') {
            healthScore += 20;
          } else if (spfStatus === 'warning') {
            healthScore += 10;
          }

          if (mxStatus === 'pass') {
            healthScore += 20;
          } else if (mxStatus === 'warning') {
            healthScore += 10;
          }

          setDomainHealthScore(healthScore + (20 - 0.666 * response?.data?.no_of_blacklist_count));
        }
      } else {
        setReport({});
      }
    };
    storedUuid && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accordionData: {
    title: keyof typeof iconMapping;
    index: number;
    status: Status;
    current_policy: any;
  }[] = [
    {
      title: 'DMARC',
      index: 0,
      status: calculateDmarcStatus(report?.dns_records?.dmarc),
      current_policy: report?.dns_records?.dmarc,
    },
    {
      title: 'DKIM',
      index: 1,
      status: calculateDkimStatus(report?.dns_records?.dkim),
      current_policy: report?.dns_records?.dkim?.map((item: any) => item?.records) || [],
    },
    {
      title: 'SPF',
      index: 2,
      status: calculateSpfStatus(report?.dns_records?.spf),
      current_policy: report?.dns_records?.spf,
    },
    {
      title: 'MX',
      index: 3,
      status: calculateMxStatus(report?.dns_records?.mx),
      current_policy: (report?.dns_records?.mx || []).map((item: any) => item?.exchange),
    },
  ];

  const handleBlacklistDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <div className="my-3 min-h-[88vh] w-full rounded-lg bg-white shadow-md">
      <div className="mb-4 flex items-center justify-between  px-5 pt-3">
        {/* Back Arrow and Title */}
        <div className="flex items-center space-x-3">
          <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-purple-100" style={{ color: '#5A33FF', backgroundColor: '#F6F2FF' }}>
            <IoIosArrowRoundBack
              size={24}
              onClick={() => {
                sessionStorage.setItem('healthCheck_type', 'domain');
                navigate('/ed-ipdomain-tracker');
              }}
            />
          </div>
          <div className="text-sm font-semibold" style={{ color: '#2B3674' }}>
            {domainName}
          </div>
        </div>

        {/* Button */}
        <div>
          <Button id="add_run_test" name="Manage Domain with Sparkle" color="purple-600" customClass="text-xs border border-purple-600 bg-purple-50 px-4 py-2 rounded-lg" />
        </div>
      </div>
      <hr />
      <div className="flex flex-row">
        <div className=" my-5 ml-4 mr-4 w-[25%]">
          <div className="h-[15.8rem] w-[19.5rem] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <h5 className="py-4 pl-5 font-medium text-[#101828] ">Domain health score</h5>
            <hr />
            <div className="flex h-[12rem] items-center justify-center">
              <CircularProgress
                value={domain_healthscore}
                size={'165px'}
                color={
                  domain_healthscore >= 80
                    ? '#16A34A' // green
                    : domain_healthscore >= 51
                    ? '#F59E0B' // orange
                    : '#DC2626' // red
                }
              >
                <CircularProgressLabel top={75}>
                  <span className="text-2xl font-medium">{Math.floor(domain_healthscore)}/ 100</span>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          </div>
          <div className="mt-5 h-[9rem] w-[19.5rem] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <div className="flex flex-row items-center justify-between">
              <h5 className="py-4 pl-5 font-medium text-[#101828]">Blacklist</h5> <IoIosArrowRoundForward size={42} color="#4F26FF" className="pr-[16px]" onClick={handleBlacklistDrawer} />
            </div>
            <hr />
            <div className="flex h-[85px] items-center pl-4" onClick={handleBlacklistDrawer}>
              <h5 className="text-center text-xl font-semibold">
                <span className="text-3xl">{report?.no_of_blacklist_count}</span> / {report?.total_count}
              </h5>
            </div>
          </div>
        </div>
        <div className="mt-5 flex h-[75vh] flex-col items-center gap-1 overflow-auto">
          {accordionData?.map((item, accordionIndex) => (
            <div key={accordionIndex} className="mb-4 w-[58.125rem] rounded-lg border border-[#E5E5E5] bg-white dark:border-darkBorder dark:bg-darkBg">
              {/* Accordion Header */}
              <div
                className="flex cursor-pointer items-center justify-between px-4 py-4"
                onClick={() => {
                  handleAccordionClick(item?.index);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex w-[95px] flex-row items-center">
                    {React.createElement(iconMapping[item.title], { color: '#667085' })}
                    <p className="ml-2 text-sm font-medium text-[#667085] dark:text-white"> {item.title}</p>
                  </div>
                  <div>
                    <div className={`flex h-[20px] flex-row items-center rounded-full px-2 py-1 ${statusColorMapping[item.status]?.bg}`}>
                      {React.createElement(statusIconMapping[item.status], {
                        color: statusColorMapping[item.status]?.icon,
                      })}
                      <p className={`ml-2 text-xs font-medium ${statusColorMapping[item.status]?.text}`}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</p>
                    </div>
                  </div>
                </div>

                <BiChevronDown color="#667085" className={`transition-transform ${openAccordion?.activeIndex === item?.index ? 'rotate-180' : ''}`} />
              </div>

              {/* Accordion Content */}
              {openAccordion?.activeIndex === item?.index && (
                <div className="bg-[#F4F7F9] px-3 pb-1 pt-4">
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Current Policy</p>
                    <p className="mt-2 flex h-fit w-full items-center truncate whitespace-normal rounded border border-b-graybg bg-[#F8F6FF] px-1 py-1 pl-2 text-sm font-medium text-[#2B3674]">
                      <span className="truncate">
                        <p className="whitespace-pre-line break-words py-1">{Array.isArray(item.current_policy) && item.current_policy?.length ? item.current_policy.join('\n')?.trim() : item.current_policy?.length ? item?.current_policy : 'No data'}</p>
                      </span>
                    </p>
                  </div>
                  {item?.title !== 'MX' && (
                    <div className="mb-3 rounded-lg bg-white p-3">
                      <p className="text-sm font-semibold text-[#344054]">Recommended Policy</p>
                      <p className="mt-2 flex items-center break-words rounded border border-b-graybg bg-[#F8F6FF] px-1 py-1  pl-2 text-sm font-medium text-[#2B3674]">{'Recommended Policy'}</p>
                    </div>
                  )}
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Tips</p>
                    <p className="mt-1 flex items-center text-sm font-medium text-[#2B3674]">{'Tips Policy'}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* </div> */}
      </div>
      {isDrawerOpen && <DomainIPDrawer data={report} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />}
    </div>
  );
};

export default DomainMonitoring;
