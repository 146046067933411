import React from 'react';
// import { AppLoaderIcon2 } from 'assets/gif';
import AppLoaderIcon3 from 'assets/gif/sparkle-loader.gif';
const AppLoader: React.FC = () => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-50">
            <img
                src={AppLoaderIcon3}
                alt="Loading"
                className="w-30 h-30"
                style={{ 'height': '100px' }}
            />
        </div>
    );
};
export default AppLoader;
