const SkeletonLoader = (props: any) => {
    const { numColumns = 5, numRows = 10 } = props;
    return (
        <tbody>
            {Array.from({ length: numRows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array.from({ length: numColumns }).map((_, colIndex) => (
                        <td key={colIndex} className={"p-4"}>
                            <p className="py-1 bg-gray-500 dark:!bg-darkBorder animate-pulse"></p>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    )
};
export default SkeletonLoader
