import { FiSearch } from 'react-icons/fi';
import { useAppDispatch } from 'redux-store';
import CustomFilter from '../customFilter';
import { updateTableQueryState } from 'redux-store/common-table';
import { jwtEncrypt } from 'utility/utils';
import React from 'react';

const TableSearchFilter = (props: any) => {
  const dispatch = useAppDispatch();
  const { filterDataList, setState, isFilter = true, isSearch = true, filterApi } = props;
  const [handleState, setHandleState] = React.useState({
    open: false,
    apiHitting: false,
  });
  const { open, apiHitting } = handleState;

  const handleFilter = async (filteredData: any) => {
    const encryptedData = filteredData?.data?.length ? jwtEncrypt(filteredData) : '';
    dispatch(updateTableQueryState({ filter: encryptedData, offset: 1 }));
  };
  const handleTableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, search: event.target.value.trim().toLowerCase() }));
  };

  React.useEffect(() => {
    (async () => {
      if (open) {
        if (filterApi) {
          try {
            setHandleState((prev) => ({ ...prev, apiHitting: true }));
            await filterApi?.();
          } catch (error) {
            console.log('Fetch filterApi error: ', error);
          } finally {
            setHandleState((prev) => ({ ...prev, apiHitting: false }));
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <div className="flex gap-2.5">
        {isSearch && (
          <div className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-[#422AFB] dark:border-darkBorder dark:focus-within:outline-blue-400  md:w-[13rem] lg:w-[26rem] ">
            <FiSearch className="size-4 cursor-pointer dark:text-[#B0B7C3]" />
            <input type="text" placeholder="Search" id="campaign-search-input" className="w-full text-sm text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-[#B0B7C3] " onChange={handleTableSearch} />
          </div>
        )}
        {isFilter && <CustomFilter open={open} onOpen={() => setHandleState((prev) => ({ ...prev, open: true }))} onClose={() => setHandleState((prev) => ({ ...prev, open: false }))} apiHitting={apiHitting} optionData={filterDataList} handleFilter={handleFilter} />}
      </div>
    </>
  );
};

export default TableSearchFilter;
