import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import 'styles/mini-calendar.scss';

type miniCalendarProps = {
  height?: string;
  width?: string;
  value?: Date;
  range?: boolean;
  onChange?: (value: Date) => void;
  className?: string;
  parentClassName?: string;
  leftIconSize?: string;
  rightIconSize?: string;
};

const MiniCalendar = (props: miniCalendarProps) => {
  const { width, height, value, range, onChange, className, parentClassName, leftIconSize, rightIconSize } = props;

  return (
    <div className={parentClassName}>
      <div className={`flex ${width ? width : 'w-full md:w-[320px]'} ${height ? height : 'h-full'} max-w-full flex-col rounded-[20px] bg-white px-1 dark:border dark:!border-darkThree dark:!bg-darkOne dark:text-white ${className}`}>
        <Calendar className={"dark:bg-black"} selectRange={range} onChange={onChange} value={value} prevLabel={<MdChevronLeft className={`ml-1 ${leftIconSize || 'size-6'} text-darkOne dark:text-white bg-transparent dark:bg-darkOne`} />} nextLabel={<MdChevronRight className={`ml-1 ${rightIconSize || 'size-6'} text-black dark:text-white bg-transparent dark:bg-darkOne `} />} view={'month'} />
      </div>
    </div>
  );
};

export default MiniCalendar;