import { HighChart } from 'components/common/Chart/HighChart';
import { useAppSelector } from 'redux-store';
import TableSearchFilter from 'components/common/table/TableSearchFilter';
import Table from 'components/common/table/Table';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { SenderAccountBubbleChartOptions } from 'dto/initial-value/views/campaign-analytics';
import { BaseApi } from 'api/services/base-api';

interface RowData {
  resultName: string;
  sentPct: number;
  openedPct: number;
  repliedPct: number;
}

const SenderAccount = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const campaign_details = JSON.parse(sessionStorage?.getItem('campaign_details'));

  const [activeRadioBtn, setActiveRadioBtn] = React.useState('domain');
  const [state, setState] = React.useState({
    tableData: [],
  });
  const columnHelper = createColumnHelper<RowData>();

  // const data = React.useMemo(
  //   () => [
  //     { id: 1, domain: 'joytechnologies.com', sentRate: '45%', openRate: '45%', replyRate: '45%' },
  //     { id: 2, domain: 'joytechnologies.com', sentRate: '45%', openRate: '45%', replyRate: '45%' },
  //     { id: 3, domain: 'joytechnologies.com', sentRate: '45%', openRate: '45%', replyRate: '45%' },
  //     { id: 4, domain: 'joytechnologies.com', sentRate: '45%', openRate: '45%', replyRate: '45%' },
  //   ],

  //   []
  // );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('resultName', {
        header: 'Domain',
        cell: (info) => info.getValue(),
        meta: { customHeaderClass: 'text-left pl-4 text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('sentPct', {
        header: 'Sent Rate',
        cell: (info) => {
          const value = info.getValue();
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#b692f6">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('openedPct', {
        header: 'Open Rate',
        cell: (info) => {
          const value = info.getValue();
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#2E90FA">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('repliedPct', {
        header: 'Reply Rate',
        cell: (info) => {
          const value = info.getValue();
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#00D8B6">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useReactTable<RowData>({
    data: state?.tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const series = [
    {
      type: 'bubble',
      data: [
        [9, 81, 63],
        [98, 5, 89],
        [58, 24, 20],
        [78, 37, 34],
        [55, 56, 53],
        [18, 45, 70],
        [42, 44, 28],
        [3, 52, 59],
        [31, 18, 97],
        [79, 91, 63],
        [44, 83, 22],
      ],
    },
  ];

  React.useEffect(() => {
    (async () => {
      try {
        const response = await BaseApi.post('campaign/analytics/domain-sender-data', {
          campaign_id: campaign_details?.uuid,
          variant_type: 'email',
          from_date: '2023-05-19T14:34:39.723+00:00',
          to_date: '2026-09-19T14:34:39.723+00:00',
          date_type: 'duration',
          list_type: activeRadioBtn,
        });

        setState((prev) => ({ ...prev, tableData: response?.data || [] }));
      } catch (error) {
        console.log('Error', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRadioBtn]);
  console.log('SenderAccount state', state);

  return (
    <div className={`grid grid-cols-6 gap-4 overflow-hidden`}>
      <div className="col-span-6 flex items-center justify-between py-2">
        <div className="flex items-center space-x-4">
          <div className="align-items-center flex">
            <input className="mr-2" id="type-domain" name="senderAccount" type="radio" checked={activeRadioBtn === 'domain'} onClick={() => setActiveRadioBtn('domain')} />
            <label htmlFor="type-domain" className={`text-[13px] font-medium ${activeRadioBtn === 'domain' && 'text-[#422AFB]'}`}>
              Domain
            </label>
          </div>
          <div className="align-items-center flex">
            <input className="mr-2" id="type-sender" name="sender" type="radio" checked={activeRadioBtn === 'sender'} onClick={() => setActiveRadioBtn('sender')} />
            <label htmlFor="type-sender" className={`text-[13px] font-medium ${activeRadioBtn === 'sender' && 'text-[#422AFB]'}`}>
              Sender Account
            </label>
          </div>
        </div>
        <div className="flex items-center space-x-4 ">
          <select style={{ color: '#667085' }} className="rounded border px-3 py-1.5 text-[13px] font-medium focus:border-2 focus:border-[#422AFB] focus:outline-none">
            <option value="domains">Domains</option>
            <option value="senderAccount">Sender Account</option>
          </select>
          <select style={{ color: '#667085' }} className="rounded border px-3 py-1.5 text-[13px] font-medium focus:border-2 focus:border-[#422AFB] focus:outline-none">
            <option value="totalSent">Total Sent</option>
            <option value="connectionRequest">Connection Request</option>
          </select>
        </div>
      </div>
      <div className="col-span-6 flex h-80 flex-col rounded-md border-2 border-graybg p-3">
        <HighChart chartOptions={SenderAccountBubbleChartOptions(theme)} isDataNotExist={false} series={series} />
      </div>
      <div className="col-span-6 h-[26.875rem] rounded-md border-2 border-graybg">
        <div className="mb-4 ml-4 mr-0 mt-4">
          <TableSearchFilter />
        </div>
        <Table customClass="h-[22.5rem]" table={table} data={state?.tableData} tableDataListStatus={true} />
      </div>
    </div>
  );
};

export default SenderAccount;
