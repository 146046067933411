/* eslint-disable array-callback-return */
import * as XLSX from 'xlsx';
import { getErrorMessage } from 'redux-store/custom';
import { DeleteEDIPDomainData, GetDomainIPDataDownload } from '../api-hanlder';
import { updateTableQueryState } from 'redux-store/common-table';
import { toastState } from 'utility/utils';
import { toast } from 'react-toastify';
import { HEALTHCHECK_MESSAGES } from 'constants/messages';

export const validateSPF = (spf: string) => /^v=spf1(\s+\S+)+$/i.test(spf);
export const validateMX = (mx: string) => {
  const pattern = /^[a-zA-Z0-9_](?:[a-zA-Z0-9_-]{0,61}[a-zA-Z0-9_])?(?:\.[a-zA-Z0-9_](?:[a-zA-Z0-9_-]{0,61}[a-zA-Z0-9_])?)+\.?$/;;
  return pattern.test(mx);
};
export const validateMTASTS = (mta: string) => {
  const pattern = /^v=STSv1;\s*id=[a-zA-Z0-9._-]+(?:;.*)?$/;
  return pattern.test(mta);
};
export const validateDKIM = (dkim: string) => {
  const pattern = /^v=DKIM1;\s*k=(rsa|ed25519);\s*p=[A-Za-z0-9+/=]+$/;
  return pattern.test(dkim);
};
export const validateDMARC = (dmarc: string) => {
  const pattern = /^v=DMARC1;\s*.*p=(none|quarantine|reject)(;.*)?$/i;
  return pattern.test(dmarc);
};

export const getDnsStatus = (recordValue: string | undefined, validatorFn: (val: string) => boolean): 'success' | 'error' | 'medium' => {
  if (!recordValue) return 'error';
  return validatorFn(recordValue) ? 'success' : 'medium';
};

export const healthcheckCancelDeleteDataEvent = (setState: any) => {
  setState((prevState: any) => ({
    ...prevState,
    confirm: {
      ...prevState.confirm,
      isOpen: false,
    },
  }));
};

export const deleteMultipleHealthCheckDataEvent = (table: any, setState: any) => {
  return async (dispatch: any, getReduxStoreState: any) => {
    try {
      const selectedIds = table?.getSelectedRowModel()?.rows?.map((val: any) => val?.original?.uuid);
      const response = await DeleteEDIPDomainData(selectedIds);
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        toast.success(HEALTHCHECK_MESSAGES?.DELETE_HEALTHCHECK);
        const currentState = getReduxStoreState();
        const { flag_status } = currentState.commonTableList;
        table.resetRowSelection();
        dispatch(updateTableQueryState({ flag_status: !flag_status }));
        setState((prevState: any) => ({
          ...prevState,
          confirm: {
            ...prevState.confirm,
            isOpen: false,
            headerContent: '',
            bodyContent: '',
            bodyRedContent: '',
            descriptionContent: '',
            uuid: [],
          },
        }));
      }
    } catch (error) {
      console.log('Health Check List single delete error', error);
    }
  };
};

export const healthcheckConfirmDeleteFunctionEvent = (setState: React.Dispatch<React.SetStateAction<any>>, state: any, rowDetails: { uuid: string }) => {
  return async (dispatch: any, getReduxStoreState: any) => {
    try {
      setState((prevState: any) => ({ ...prevState, deleteloading: true }));
      const response = await DeleteEDIPDomainData([rowDetails?.uuid]);
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
        setState((prevState: any) => ({ ...prevState, deleteloading: false }));
      } else {
        setState((prevState: any) => ({
          ...prevState,
          confirm: {
            ...prevState.confirm,
            isOpen: false,
          },
        }));
        const currentState = getReduxStoreState();
        const { flag_status } = currentState.commonTableList;
        dispatch(updateTableQueryState({ flag_status: !flag_status }));
        setState((prevState: any) => ({ ...prevState, deleteloading: false }));
        toast.success('Domain/IP Deleted Successfully', toastState.success);
      }
    } catch (error) {
      console.error('Domain/IP single delete error', error);
    }
  };
};

export const DomainIPDownloadListData = async (rowDetails: Array<string>, state: any) => {
  const response = await GetDomainIPDataDownload(rowDetails);
  const listData = response?.data;
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(listData);
  XLSX.utils.book_append_sheet(wb, ws, 'Blacklist Validation');
  const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
  const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'blacklist_report.csv';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const getScoreFromStatus = (status: 'success' | 'medium' | 'error') => {
  switch (status) {
    case 'success':
      return 20;
    case 'medium':
      return 10;
    case 'error':
      return 0;
    default:
      return 0;
  }
};

// -------------------- DMARC HELPER --------------------
export const getDmarcStatus = (rowData: any) => {
  const dmarcArr = rowData?.dns_records?.dmarc;
  const dmarcRecord = Array.isArray(dmarcArr) && dmarcArr.length > 0 ? dmarcArr[0] : '';
  const dmarcRawStatus = getDnsStatus(dmarcRecord, validateDMARC); // 'success' | 'error'

  if (dmarcRawStatus === 'success') {
    return dmarcArr?.length > 1 ? 'medium' : 'success';
  } else if (dmarcRawStatus === 'medium') {
    return 'medium';
  } else {
    return 'error';
  }
};

// -------------------- DKIM HELPER --------------------
// export const getDkimStatus = (rowData: any) => {
//   const dkimArr = rowData?.dns_records?.dkim;
//   const dkimRecord = Array.isArray(dkimArr) && dkimArr.length > 0 ? dkimArr[0]?.records : '';
//   const dkimRawStatus = getDnsStatus(dkimRecord, validateDKIM); // 'success' | 'error'
//   if (dkimRawStatus === 'success') {
//     const firstRecordLength = dkimArr?.[0]?.records?.[0]?.[0]?.length || 0;
//     return firstRecordLength < 255 ? 'medium' : 'success';
//   } else if (dkimRawStatus === 'medium') {
//     return 'medium';
//   } else {
//     return 'error';
//   }
// };

export const getDkimStatus = (rowData: any) => {
  const dkimArr = rowData?.dns_records?.dkim;

  // 1. If no DKIM data, return 'error'
  if (!Array.isArray(dkimArr) || dkimArr.length === 0) {
    return 'error';
  }

  dkimArr.map((dkimItem) => {
    // Each item has a 'records' array (possibly an array of arrays)
    const records = dkimItem?.records || [];
    records.map((recordItem: any) => {
      if (Array.isArray(recordItem)) {
        // recordItem is an array of strings
        recordItem.map((str) => {
          if (str && str.length < 255) {
            return 'medium';
          }
        });
      } else {
        // recordItem is a single string
        if (recordItem && recordItem?.length < 255) {
          return 'medium';
        }
      }
    });
  });
  return 'success';
};

// -------------------- SPF HELPER --------------------
export const getSpfStatus = (rowData: any) => {
  const spfArr = rowData?.dns_records?.spf;
  const spfRecord = Array.isArray(spfArr) && spfArr.length > 0 ? spfArr[0] : '';
  const spfRawStatus = getDnsStatus(spfRecord, validateSPF); // 'success' | 'error'

  if (spfRawStatus === 'success') {
    return spfArr?.length > 1 ? 'medium' : 'success';
  } else if (spfRawStatus === 'medium') {
    return 'medium';
  } else {
    return 'error';
  }
};

// -------------------- MX HELPER --------------------
export const getMxStatus = (rowData: any) => {
  const mxArr = rowData?.dns_records?.mx;
  console.log("object",mxArr)

  if (!Array.isArray(mxArr) || mxArr.length === 0) {
    return 'medium';
  } else {
    const allValid = mxArr.every((mxObj: any) => {
      const mxString = `${mxObj.exchange}`;
      return validateMX(mxString);
    });
    return allValid ? 'success' : 'error';
  }
};