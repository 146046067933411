import { HighChart } from 'components/common/Chart/HighChart';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import React, { useState } from 'react';
import { LeadContactsOverTime, EngagementRateSecondChart, HeatMapFun, } from 'dto/initial-value/views/campaign-analytics';
import { useAppSelector } from 'redux-store';
import { BiChevronDown, BiDotsVerticalRounded } from 'react-icons/bi';
import { CustomMenu } from 'components/common/customMenu';
import useOutsideClick from 'hooks/useClickOutsideHook';
import classNames from 'classnames';


type DropdownItem = {
  name: string;
  icon: React.ReactNode | null;
  className: string;
};

const dropDownData: DropdownItem[] = [
  { name: 'Reply Rate', icon: null, className: '' },
  { name: 'Open Rate', icon: null, className: '' },
];

const timeZoneData: DropdownItem[] = [
  { name: 'UTC (GMT +0)', icon: null, className: '' },
  { name: 'PST (GMT -8)', icon: null, className: '' },
  { name: 'EST (GMT -5)', icon: null, className: '' },
];
const Engagement = (props: { option: any; title: any; series: any; constructorType?: string; isDataNotExist?: boolean }) => {
  const { constructorType, isDataNotExist } = props;
  const theme = useAppSelector((state) => state?.app?.theme);

  const getOverAllStatsSeriesmmsm = () => {
    return [
      {
        name: 'Total Sent',
        data: [
          ['Jan1', 1200],  // Oct 1
          // Oct 7
        ],
        color: '#FF5733', // Red
      },
      {
        name: 'Opened',
        data: [
          ['Jan1', 1100],  // Oct 1
          ['Jan2', 1150],  // Oct 2
          ['Jan3', 1200],  // Oct 3
          ['Jan4', 1300],  // Oct 4
          ['Jan5', 1350],  // Oct 5
          ['Jan6', 1400],  // Oct 6
          ['Jan7', 1450],  // Oct 7
        ],
        color: '#33FF57', // Green
      },
      {
        name: 'Replied',
        data: [
          ['Jan1', 300],  // Oct 1
          ['Jan2', 400],  // Oct 2
          ['Jan3', 350],  // Oct 3
          ['Jan4', 500],  // Oct 4
          ['Jan5', 550],  // Oct 5
          ['Jan6', 600],  // Oct 6
          ['Jan7', 650],  // Oct 7
        ],
        color: '#3357FF', // Blue
      },
      {
        name: 'Unsubscribed',
        data: [
          [0, 50],  // Oct 1
          [1, 60],  // Oct 2
          [2, 55],  // Oct 3
          [3, 80],  // Oct 4
          [4, 90],  // Oct 5
          [5, 100], // Oct 6
          [6, 120], // Oct 7
        ],
        color: '#F39C12', // Yellow
      },
      {
        name: 'Bounced',
        data: [
          [0, 100],  // Oct 1
          [1, 120],  // Oct 2
          [2, 150],  // Oct 3
          [3, 180],  // Oct 4
          [4, 160],  // Oct 5
          [5, 140],  // Oct 6
          [6, 130],  // Oct 7
        ],
        color: '#9B59B6', // Purple
      },
    ];
  };

  const getOverAllStatsSeriesmmsmss = () => {
    return [

      {
        name: 'Open Rate',
        data: [
          [0, 10],  // Oct 1
          [1, 11],  // Oct 2
          [2, 20],  // Oct 3
          [3, 30],  // Oct 4
          [4, 15],  // Oct 5
          [5, 40],  // Oct 6
          [6, 45],  // Oct 7
        ],
        color: '#33FF57', // Green
      },
      {
        name: 'Reply Rate',
        data: [
          [0, 30],  // Oct 1
          [1, 40],  // Oct 2
          [2, 30],  // Oct 3
          [3, 50],  // Oct 4
          [4, 55],  // Oct 5
          [5, 60],  // Oct 6
          [6, 65],  // Oct 7
        ],
        color: '#3357FF', // Blue
      },

    ];
  };
  const heatdata = () => {
    return [
      {
        borderWidth: 0,
        borderColor: '#7090B029',
        data: [
          [0, 0, 25], [0, 1, 15], [0, 2, 76], [0, 3, 10], [0, 4, 5], [0, 5, 80], // Mon
          [1, 0, 15], [1, 1, 25], [1, 2, 74], [1, 3, 12], [1, 4, 5], [1, 5, 88], // Tue
          [2, 0, 35], [2, 1, 25], [2, 2, 90], [2, 3, 12], [2, 4, 5], [2, 5, 75], // Wed
          [3, 0, 45], [3, 1, 24], [3, 2, 83], [3, 3, 8], [3, 4, 10], [3, 5, 60], // Thu
          [4, 0, 55], [4, 1, 34], [4, 2, 79], [4, 3, 10], [4, 4, 15], [4, 5, 70], // Fri
          [5, 0, 65], [5, 1, 15], [5, 2, 60], [5, 3, 12], [5, 4, 8], [5, 5, 45], // Sat
          [6, 0, 85], [6, 1, 15], [6, 2, 75], [6, 3, 25], [6, 4, 10], [6, 5, 30]  // Sun  // Sun
        ],
        dataLabels: {
          enabled: true,
          color: '#000000',
          format: '{point.value}%' // Display values with a percentage
        }
      }
    ];
  };
  // const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const [socialState, setSocialState] = useState({
    open: false,
    selectedSocialIndex: 0,
  });
  const [timeZoneState, setTimeZoneState] = useState({
    open: false,
    selectedTimeZoneIndex: 0,
  });

  const [dotMenuState, setDotMenuState] = useState({
    open: false,
  });

  const [dropdownOptions, setDropdownOptions] = useState([
    { label: 'Total Sent', toggle: true },
    { label: 'Total Opened', toggle: true },
    { label: 'Total Replied', toggle: true },
    { label: 'Total Unsubscribed', toggle: true },
    { label: 'Total Interest', toggle: false },
    { label: 'Total Appointments Set', toggle: false },
    { label: 'Total Deals Closed', toggle: false },
  ]);

  const cardsData = [
    { label: 'Total Sent', value: 2420, percentage: 98, color: '#00D8B6' },
    { label: 'Total Opened', value: 2657, percentage: 67, color: '#008FFB' },
    { label: 'Total Replied', value: 2420, percentage: 98, color: '#FFB01A' },
    { label: 'Total Unsubscribed', value: 2420, percentage: 98, color: '#775DD0' },
    { label: 'Total Interest', value: 300, percentage: 45, color: '#FF5733' },
    { label: 'Total Appointments Set', value: 500, percentage: 75, color: '#9B59B6' },
    { label: 'Total Deals Closed', value: 400, percentage: 60, color: '#F39C12' },
  ];

  const visibleCards = cardsData.filter((card) =>
    dropdownOptions.find((option) => option.label === card.label && option.toggle)
  );

  const toggleOption = (index: number) => {
    setDropdownOptions((prev) =>
      prev.map((option, i) => (i === index ? { ...option, toggle: !option.toggle } : option))
    );
  };
  return (
    <div className={`grid grid-cols-6 gap-4 overflow-y-auto dark:bg-darkTwo`}>

      {/* firstchatt */}
      <div
        className={`col-span-6 flex flex-col rounded-md border-2 border-graybg p-3 dark:border-darkThree dark:text-white ${isDataNotExist ? 'h-[550px]' : 'h-auto'
          }`}
      >
        <div className="flex justify-between items-center">
          <p className="p-4 text-xs font-medium dark:text-white">Lead Contacts Over Time</p>
          <div className="relative">
            <BiDotsVerticalRounded
              size={24}
              className="cursor-pointer text-column dark:text-gray-300"
              onClick={() => setDotMenuState((prev) => ({ ...prev, open: !prev.open }))}
            />
            {dotMenuState.open && (
              <div className="absolute right-0 top-full mt-2 z-50 w-64 bg-white dark:bg-darkTwo border border-gray-300 dark:border-darkThree rounded-md shadow-lg">
                {dropdownOptions.map((option, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-darkThree"
                  >
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      {option.label}
                    </span>
                    <TableColumnVisibility
                      handleChangeColumnVisibility={() => toggleOption(index)}
                      isColumnVisibility={option.toggle}
                      setState={() => { }}
                      key={index}
                    />

                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="col-span-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4 dark:bg-darkTwo">
          {visibleCards.map((card, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-4 rounded-lg border-2 border-graybg shadow-md bg-white dark:border-darkThree dark:bg-darkTwo dark:text-white"
            >
              <div className="mt-2 text-column text-sm dark:text-white">{card.label}</div>
              <CircularProgress value={card.percentage} size="90px" color={card.color}>
                <CircularProgressLabel>
                  <span
                    className="text-xs dark:text-white"
                    style={{ fontSize: '12px' }}
                  >
                    {card.percentage}%
                  </span>
                  <div
                    className="text-gray-500 dark:text-gray-400 text-sm"
                    style={{ fontSize: '8px' }}
                  >
                    {card.value}
                  </div>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          ))}
        </div>


        <HighChart title="" chartOptions={LeadContactsOverTime(theme)} series={getOverAllStatsSeriesmmsm()} constructorType={constructorType || 'chart'} isDataNotExist={false} />
      </div>

      {/* secondchrt */}
      <div className="col-span-2 flex h-[21.875rem]  flex-col rounded-md border-2 border-graybg p-3 dark:border-darkThree dark:text-white dark:bg-darkTwo">
        <HighChart title="Engagement rate" chartOptions={EngagementRateSecondChart(theme)} series={getOverAllStatsSeriesmmsmss()} constructorType={constructorType || 'chart'} isDataNotExist={false} />
      </div>

      {/* thirdchart */}

      <div className={`col-span-4 flex  flex-col rounded-md border-2 border-graybg p-3 dark:border-darkThree dark:text-white${isDataNotExist ? 'h-[21.875rem]' : 'h-auto'}`}>

        <div className="discla">
          <div className="disclaimer-container flex items-center justify-between mb-2">

            <div className="itlldiscl flex items-center  gap-1">
              <h2 className="text-xs font-medium dark:text-white">
                Engagement Heatmap by Day & Time
              </h2>

              {/* ///disclaimercode */}
              <div className="relative group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-gray-500 dark:text-gray-300 cursor-pointer"
                >
                  <circle cx="12" cy="12" r="10" stroke="currentColor" fill="none" strokeWidth="1.5" />
                  <text
                    x="12"
                    y="16"
                    textAnchor="middle"
                    fontSize="12"
                    fontWeight="bold"
                    fill="currentColor"
                  >
                    i
                  </text>
                </svg>
                <div className="absolute hidden  z-30 group-hover:block bg-heading text-white text-xs rounded-md px-3 py-2 shadow-md w-max top-full mt-1 left-1/2 transform -translate-x-1/2">
                  Displays peak engagement times throughout your week. Darker colors show higher activity
                  levels.
                </div>
              </div>
            </div>


            {/* ///doprdownx */}

            <div className="doprdwonn flex gap-1">
              <CustomMenu
                open={socialState.open}
                onOpen={() => setSocialState((prev) => ({ ...prev, open: true }))}
                onClose={() => setSocialState((prev) => ({ ...prev, open: false }))}
                btnContent={
                  <div className="flex select-none items-center gap-2">
                    <div className="text-xs font-medium text-column">
                      {dropDownData[socialState.selectedSocialIndex]?.name}
                    </div>
                  </div>
                }
                btnClassName="text-nowrap !h-8"
                menuListClassName="!p-0.5 !h-auto overflow-y-auto"
                rightIcon={<BiChevronDown size={18} />}
              >
                {dropDownData.map((ele, index) => (
                  <div
                    key={index}
                    className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground"
                    onClick={() => setSocialState((prev) => ({ ...prev, open: false, selectedSocialIndex: index }))}
                  >
                    <div className="text-sm font-medium text-column">{ele?.name}</div>
                  </div>
                ))}
              </CustomMenu>
              <CustomMenu
                open={timeZoneState.open}
                onOpen={() => setTimeZoneState((prev) => ({ ...prev, open: true }))}
                onClose={() => setTimeZoneState((prev) => ({ ...prev, open: false }))}
                btnContent={
                  <div className="flex select-none items-center gap-2">
                    <div className="text-xs font-medium text-column">
                      {timeZoneData[timeZoneState.selectedTimeZoneIndex]?.name}
                    </div>
                  </div>
                }
                btnClassName="text-nowrap !h-8"
                menuListClassName="!p-0.5 !h-auto overflow-y-auto"
                rightIcon={<BiChevronDown size={18} />}
              >
                {timeZoneData.map((ele, index) => (
                  <div
                    key={index}
                    className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground"
                    onClick={() => setTimeZoneState((prev) => ({ ...prev, open: false, selectedTimeZoneIndex: index }))}
                  >
                    <div className="text-sm font-medium text-column">{ele?.name}</div>
                  </div>
                ))}
              </CustomMenu>

            </div>

          </div>

          <HighChart
            title=""
            chartOptions={HeatMapFun(theme)}
            series={heatdata()}
            constructorType={constructorType || 'chart'}
            isDataNotExist={false}
          />
        </div>



      </div>
    </div>
  );
};


const TableColumnVisibility = (props: any) => {
  const { handleChangeColumnVisibility, isColumnVisibility = false, key } = props;
  const tableColumnVisibilityList = [{ id: "1234", disable: false, checked: true }];
  const [state, setState] = React.useState({
    column_visibility_flag: false
  })
  console.log("Engagement state",state)

  const columnVisibilityRef = useOutsideClick(() => {
    setState((prev: any) => ({ ...prev, column_visibility_flag: false }));
  });

  return (
    <>
      <div key={key} ref={columnVisibilityRef} className={`relative cursor-pointer rounded-md bg-white outline outline-2 dark:bg-darkTwo dark:outline-darkBorder  ${isColumnVisibility ? 'outline-menuBorder dark:outline-white' : 'outline-gray-300'}`}>
        <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setState((prev: any) => ({ ...prev, column_visibility_flag: !prev?.column_visibility_flag }))}>
          <BiDotsVerticalRounded
            size={24}
            className="cursor-pointer text-column dark:text-gray-300"
          />
        </button>
        {isColumnVisibility && (
          <div className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md border bg-white px-3 py-2 dark:border-darkBorder dark:bg-darkOne">
            {tableColumnVisibilityList?.map((col: any, index: number, arr: any) => (
              <>
                <div key={index} className="flex items-center justify-between p-2">
                  <h5 className="text-xs dark:text-white">{col.label}</h5>
                  <label className="inline-flex cursor-pointer items-center">
                    <input type="checkbox" value="" className="peer sr-only" checked={col?.[col?.id]} disabled={col?.disabled} onChange={() => handleChangeColumnVisibility(col.id)} />
                    <div
                      className={classNames(
                        `peer relative h-4 w-7 rounded-full bg-gray-200 
                        after:absolute after:start-[2px] after:top-[2px] after:h-3 after:w-3
                        after:rounded-full after:border after:border-gray-300 
                        after:bg-white after:transition-all after:content-[''] 
                        peer-checked:after:translate-x-full 
                        peer-checked:after:border-white peer-focus:outline-none
                        peer-focus:ring-4 dark:border-gray-600 dark:bg-gray-700 
                        dark:peer-focus:ring-blue-800 rtl:peer-checked:after:-translate-x-full`,
                        {
                          'cursor-pointer': !col?.disabled,
                          'cursor-not-allowed': col?.disabled,
                          'peer-checked:bg-blue-600': !col?.disabled,
                          'peer-checked:bg-blue-300': col?.disabled,
                        }
                      )}
                    ></div>
                  </label>
                </div>
                {arr?.length - 1 !== index && <hr />}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Engagement;
