import { flexRender } from '@tanstack/react-table';
const TableHeader = ({ table }: any) => {
    return (

        <thead className='h-12'>
            {table.getHeaderGroups().map((headerGroup: any) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any, index: number, arr: any) => {
                        return (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                onClick={header.column.getToggleSortingHandler()}
                                className={`${header?.column?.columnDef?.meta?.customClass || ''} ${header?.column?.columnDef?.meta?.customHeaderClass || ''} mb-2 cursor-pointer border-y font-medium border-graybg bg-grayBorder text-column  dark:border-white/10 dark:bg-darkOne`}
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getIsSorted() && (header.column.getIsSorted() === 'asc' ? ` 🔼` : ` 🔽`)}
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>

    )
}

export default TableHeader
