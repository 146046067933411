export const AUTH_MESSAGES = {
  LOGIN_SUCCESS: 'You have logged in successfully.',
  LOGIN_FAILURE: 'Invalid email or password. Please try again.',
  LOGOUT_SUCCESS: 'You have successfully logged out.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  REGISTER_SUCCESS: 'Account created successfully. Please check your email to verify your account.',
  REGISTER_FAILURE: 'Registration failed. Please try again later.',
  PASSWORD_RESET_EMAIL_SENT: 'Password reset email has been sent. Please check your inbox.',
  PASSWORD_RESET_SUCCESS: 'Your password has been successfully reset.',
  PASSWORD_RESET_FAILURE: 'Failed to reset the password. Please try again.',
  UNAUTHORIZED_ACCESS: 'You do not have permission to access this resource.',
};

export const CLIPBOARD_MESSAGES = {
  COPY_SUCCESS: 'Text copied to clipboard successfully.',
  COPY_FAILURE: 'Failed to copy text to clipboard.',
  PASTE_SUCCESS: 'Text pasted successfully.',
  PASTE_FAILURE: 'Failed to paste text.',
};

export const SYSTEM_MESSAGES = {
  SYSTEM_MAINTENANCE: 'The system is currently undergoing maintenance. Please try again later.',
  FEATURE_UNAVAILABLE: 'This feature is temporarily unavailable.',
  UNSUPPORTED_OPERATION: 'This operation is not supported.',
  COMING_SOON: 'This feature is coming soon!',
};

export const LOADING_MESSAGES = {
  LOADING_DATA: 'Loading data, please wait...',
  PROCESSING_REQUEST: 'Processing your request...',
  FETCHING_DETAILS: 'Fetching details...',
  SAVING_CHANGES: 'Saving changes...',
  UPDATING_STATUS: 'Updating status...',
};

export const LANGUAGE_MESSAGES = {
  LANGUAGE_CHANGE_SUCCESS: 'Language has been changed successfully.',
  LANGUAGE_CHANGE_FAILURE: 'Failed to change the language. Please try again.',
  TRANSLATION_ERROR: 'Translation error occurred. Please report this issue.',
};

export const NOTIFICATION_MESSAGES = {
  NEW_NOTIFICATION: 'You have a new notification.',
  NO_NOTIFICATIONS: 'No new notifications at the moment.',
  MARK_ALL_AS_READ: 'All notifications have been marked as read.',
};

export const CART_MESSAGES = {
  ITEM_ADDED: 'Item has been added to your cart.',
  ITEM_REMOVED: 'Item has been removed from your cart.',
  CART_UPDATED: 'Your cart has been updated.',
  CART_EMPTY: 'Your cart is empty.',
  ORDER_SUCCESS: 'Your order has been placed successfully.',
  ORDER_FAILURE: 'Failed to place your order. Please try again.',
};

export const PAYMENT_MESSAGES = {
  PAYMENT_SUCCESS: 'Your payment was successful.',
  PAYMENT_FAILURE: 'Payment failed. Please try again.',
  SUBSCRIPTION_SUCCESS: 'You have successfully subscribed.',
  SUBSCRIPTION_FAILURE: 'Failed to process the subscription. Please try again.',
  SUBSCRIPTION_CANCELED: 'Your subscription has been canceled.',
  SUBSCRIPTION_RENEWED: 'Your subscription has been renewed.',
};

export const PAGINATION_MESSAGES = {
  NO_MORE_ITEMS: 'No more items to display.',
  LOADING_ITEMS: 'Loading more items...',
  EMPTY_LIST: 'There are no items to display.',
  PAGE_OUT_OF_RANGE: 'The requested page is out of range.',
};

export const API_STATUS_MESSAGES = {
  SUCCESS: 'Request was successful.',
  FAILURE: 'Request failed. Please try again.',
  NOT_FOUND: 'The requested resource was not found.',
  UNAUTHORIZED: 'You are not authorized to make this request.',
  FORBIDDEN: 'You do not have permission to access this resource.',
  SERVER_ERROR: 'A server error occurred. Please try again later.',
  TIMEOUT_ERROR: 'The request timed out. Please try again.',
  VALIDATION_ERROR: 'Some of the fields are invalid. Please correct them and try again.',
  CONFLICT_ERROR: 'A conflict occurred. The resource already exists.',
};

export const FILE_MESSAGES = {
  FILE_UPLOAD_SUCCESS: 'File uploaded successfully.',
  FILE_UPLOAD_FAILURE: 'Failed to upload file. Please try again later.',
  FILE_DOWNLOAD_SUCCESS: 'File downloaded successfully.',
  FILE_DOWNLOAD_FAILURE: 'Failed to download file. Please try again later.',
  FILE_TOO_LARGE: 'The file size exceeds the allowed limit.',
  UNSUPPORTED_FILE_TYPE: 'This file type is not supported. Please upload a valid file.',
};

export const USER_MESSAGES = {
  PROFILE_UPDATE_SUCCESS: 'Your profile has been updated successfully.',
  PROFILE_UPDATE_FAILURE: 'Failed to update your profile. Please try again later.',
  EMAIL_UPDATE_SUCCESS: 'Your email address has been updated successfully.',
  EMAIL_UPDATE_FAILURE: 'Failed to update your email. Please try again later.',
  ACCOUNT_DEACTIVATED: 'Your account has been deactivated.',
  ACCOUNT_REACTIVATION_SUCCESS: 'Your account has been successfully reactivated.',
  ACCOUNT_REACTIVATION_FAILURE: 'Failed to reactivate your account. Please contact support.',
};

export const TOAST_MESSAGES = {
  SAVE_SUCCESS: 'Your changes have been saved successfully.',
  SAVE_FAILURE: 'Failed to save changes. Please try again.',
  DELETE_SUCCESS: 'The item has been deleted successfully.',
  DELETE_FAILURE: 'Failed to delete the item. Please try again.',
  UPLOAD_SUCCESS: 'File uploaded successfully.',
  UPLOAD_FAILURE: 'File upload failed. Please try again.',
  UPDATE_SUCCESS: 'Update was successful.',
  UPDATE_FAILURE: 'Failed to update. Please try again.',
  CONNECTION_ERROR: 'Network error, please check your connection.',
  FETCH_ERROR:'error while fetching data',
  NOTIFICATION_SUCCESS:'Browser notifications enabled successfully',
  NOTIFICATION_DENIED:'You have denied notifications. To reset, please check your browser settings',
  NOTIFICATION_NOT_FOUND_ERROR:'Service Worker or Push Manager not supported in this browser',
  INVITE_USER_SUCCESS:'Invite Sent Successfully'
};

export const VALIDATION_MESSAGES = {
  REQUIRED_FIELD: 'This field is required.',
  INVALID_EMAIL: 'Please enter a valid email address.',
  PASSWORD_TOO_SHORT: (length: number) => `Password must be at least ${length} characters long.`,
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
  INVALID_PHONE_NUMBER: 'Please enter a valid phone number.',
  MINIMUM_CHARACTERS: (min: number) => `This field must be at least ${min} characters long.`,
  MAXIMUM_CHARACTERS: (max: number) => `This field cannot exceed ${max} characters.`,
};

export const BRAND_MESSAGES = {
  DELETE_BRAND: 'Brand Deleted Sucessfully',
};

export const API_KEY_MESSAGE = {
  COPY: 'API key copied successfully',
};

export const OTP_MESSAGE={
  SUCCESS_OTP:'OTP Sent Successfully'
}

export const HEALTHCHECK_MESSAGES = {
  DELETE_HEALTHCHECK: 'Domain/IP Deleted Sucessfully',
};
