import { BaseApi } from 'api/services/base-api';
import CustomDatePicker from 'components/common/customDatePicker';
import moment from 'moment';
import React from 'react';

const DashBoardHeader = (props: any) => {
  const { smartStateUpdate, initialLoading } = props;
  const [loading, setLoading] = React.useState(false);

  const fetchDate = async (date: any[], setShowCalendar: any) => {
    if (initialLoading) return;
    try {
      setLoading(true);
      const start_date = moment(date[0]).format('YYYY/MM/DD');
      const end_date = moment(date[1]).format('YYYY/MM/DD');
      const response = await BaseApi.get(`/dashboard/email/verify?start_date=${start_date}&end_date=${end_date}`);
      smartStateUpdate({ stats: response?.data });
    } catch (error) {
      console.log('Stats fetch error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const date = React.useMemo(() => {
    return [moment().subtract(30, 'days').startOf('day').toDate(), moment().endOf('day').toDate()];
  }, []);

  return (
    <div className="flex items-center justify-between border-b px-4 py-5 dark:border-darkThree dark:bg-darkOne">
      <h4 className="text-sm font-bold text-heading dark:text-white">Dashboard</h4>
      <CustomDatePicker initial_date={date} onChange={async (date: any, setShowCalendar: any) => await fetchDate(date, setShowCalendar)} customSelectedDateClass={'text-xs text-colomn dark:text-white'} loading={loading} />
    </div>
  );
};

export default DashBoardHeader;
