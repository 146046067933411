/* eslint-disable @typescript-eslint/no-unused-vars */

import { _getQueryParams } from 'utility/utils';
import { IoIosArrowRoundBack, IoMdCloudDone, IoMdLock } from 'react-icons/io';
import Button from 'components/common/Button';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { ReportAccordationPopupStateIV } from 'dto/initial-value/views/health-check';
import { AccordionState } from 'dto/types/views/notification';
import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FaMedal } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { GetOneEDSingleValidateAPI } from 'shared/ed-ipdomain/api-hanlder';
import IPDrawer from './IP_BlackList_Drawer';
import domainData from '../../../helpers/json/health-check-calculation.json';
import { getMatchingValues } from '../../../utility/utils';

const IPMonitoring = () => {
  const [report, setReport] = useState(null);
  const navigate = useNavigate();

  const [openAccordion, setOpenAccordion] = React.useState<AccordionState>(ReportAccordationPopupStateIV);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const [ip_healthscore, setIPHealthScore] = React.useState<number>(0);

  const [ip_address,setIPAddress] = React.useState<string>('')

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState?.activeIndex === index ? null : index,
    }));
  };

  const iconMapping = {
    RDNS: FaMedal,
  };

  const statusIconMapping = {
    pass: IoMdCloudDone,
    warning: RiErrorWarningFill,
    'no records': IoCloseCircle,
  };

  const statusColorMapping = {
    pass: { bg: 'bg-green-100', text: 'text-green-600', icon: '#16A34A' }, // Green
    warning: { bg: 'bg-yellow-100', text: 'text-yellow-600', icon: '#F59E0B' }, // Yellow
    'no records': { bg: 'bg-red-100', text: 'text-red-600', icon: '#DC2626' }, // Red
  };

  type Status = 'pass' | 'no records' | 'warning';

  const accordionData: { title: keyof typeof iconMapping; index: number; status: Status }[] = [{ title: 'RDNS', index: 0, status: 'warning' }];

  useEffect(() => {
    const storedUuid = sessionStorage.getItem('healthCheckUUID');
    const fetchData  = async () => {
      const response = await GetOneEDSingleValidateAPI(storedUuid);
      if (!response?.error) {
        setReport(response?.data);
        setIPAddress(response?.data?.blacklist_name)
        let sum = getMatchingValues(response?.data?.blacklist_result, domainData, response?.data?.rdns_record?.fwd_rev_dns);
        sum = 100 - sum;
        setIPHealthScore(sum);
      } else {
        setReport({});
      }
    };
    storedUuid && fetchData();
  }, []);
  // console.log('ip_healthscore:', ip_healthscore);
  const handleBlacklistDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <div className="my-3 min-h-[88vh] w-full rounded-lg bg-white shadow-md">
      <div className="mb-4 flex items-center justify-between  px-5 pt-3">
        {/* Back Arrow and Title */}
        <div className="flex items-start space-x-3">
          <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-purple-100" style={{ color: '#5A33FF', backgroundColor: '#F6F2FF' }}>
            <IoIosArrowRoundBack size={24} onClick={() => {
              sessionStorage.setItem('healthCheck_type', 'ip');
              navigate('/ed-ipdomain-tracker')
              }} />
          </div>
          <div className="text-sm font-semibold" style={{ color: '#2B3674' }}>
            {ip_address}
          </div>
        </div>

        {/* Button */}
        <div>
          <Button id="manage_ip_with_sparkle_btn" name="Manage Domain with Sparkle" color="purple-600" customClass="text-xs border border-purple-600 bg-purple-50 px-4 py-2 rounded-lg" />
        </div>
      </div>
      <hr />
      <div className="flex flex-row">
        <div className=" my-5 ml-4 mr-4 w-[25%]">
          <div className="h-[252.8px] w-[312px] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <h5 className="py-4 pl-5 font-medium text-[#101828] ">Ip health score</h5>
            <hr />
            <div className="flex h-[192px] items-center justify-center">
              <CircularProgress value={Math.floor(ip_healthscore)} max={100} size="10.3125rem" color="#F08C02">
                <CircularProgressLabel top={75}>
                  <span className="text-2xl font-medium">{Math.floor(ip_healthscore)}/ 100</span>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          </div>
          <div className="mt-5 h-[144px] w-[312px] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <div className="flex flex-row items-center justify-between">
              <h5 className="py-4 pl-5 font-medium text-[#101828]">Blacklist</h5> <IoIosArrowRoundForward size={42} color="#4F26FF" className="pr-[1rem]" onClick={handleBlacklistDrawer} />
            </div>
            <hr />
            <div className="flex h-[5.3125rem] items-center pl-4" onClick={handleBlacklistDrawer}>
              <h5 className="text-center text-xl font-semibold">
                <span className="text-3xl">{report?.no_of_blacklist_count}</span> / {report?.total_count}
              </h5>
            </div>
          </div>
        </div>
        {/* <div className="my-5 mr-4 w-[75%] border border-pink-800 px-3 py-3 "> */}
        <div className="mt-5 flex flex-col items-center gap-1">
          {accordionData?.map((item, accordionIndex) => (
            <div key={accordionIndex} className="mb-4 w-[930px] rounded-lg border border-[#E5E5E5] bg-white dark:border-darkBorder dark:bg-darkBg">
              {/* Accordion Header */}
              <div
                className="flex cursor-pointer items-center justify-between px-4 py-4"
                onClick={() => {
                  handleAccordionClick(item?.index);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex w-[5.5rem] flex-row items-center">
                    {React.createElement(iconMapping[item.title], { color: '#667085' })}
                    <p className="ml-2 text-sm font-medium text-[#667085] dark:text-white"> {item.title}</p>
                  </div>
                  <div>
                    <div
                      className={`flex h-[1.25rem] flex-row items-center rounded-full px-2 py-1 ${statusColorMapping[item.status]?.bg}`} // Dynamically set background color
                    >
                      {React.createElement(statusIconMapping[item.status], {
                        color: statusColorMapping[item.status]?.icon, // Dynamically set icon color
                      })}
                      <p
                        className={`ml-2 text-xs font-medium ${statusColorMapping[item.status]?.text}`} // Dynamically set text color
                      >
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                      </p>
                    </div>
                  </div>
                </div>

                <BiChevronDown color="#667085" className={`transition-transform duration-300 ease-in-out ${openAccordion?.activeIndex === item?.index ? 'rotate-180' : ''}`} />
              </div>

              {/* Accordion Content */}
              {openAccordion?.activeIndex === item?.index && (
                <div className="bg-[#F4F7F9] px-3 pb-1 pt-4">
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">IP Address</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{report?.rdns_record?.ip || ``}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">FWD/REV DNS Match</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{`${report?.rdns_record?.fwd_rev_dns ? `Yes` : `No`}`}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Hostname</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{report?.rdns_record?.rdns?.[0] || ``}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Domain</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{report?.rdns_record?.rdns?.[0]?.split('.').slice(-2).join('.') || ``}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* </div> */}
      </div>
      {isDrawerOpen && <IPDrawer data={report} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />}
    </div>
  );
};

export default IPMonitoring;
