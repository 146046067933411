export const CONSTANTS = {
  DEFAULT_LANGUAGE: 'en',
  TOKEN: 'token',
  LOGIN_STORAGE_VAR: '_logged_user_details',
  EDIT_ID: '_edit_id',
  CHANNEL_ID: '_channel_id',

  AUTH_TOKEN: '_authToken',
  USER_ID: 'logged-in-user-id',
  USER_EMAIL: 'logged-in-user-email',

  GOOGLE_SIGNUP: 'Continue with Google',
  GOOGLE_LOGIN: 'Continue with Google',
};

export const TITLE_HEADINGS_LIST = [
  { path: '/sign-up', title: 'Sign Up | Sparkle', heading: '' },
  { path: '/sign-in', title: 'Login | Sparkle', heading: '' },
  { path: '/forgot-password', title: 'Forgot Password | Sparkle', heading: '' },
  { path: '/reset/password', title: 'Reset Password | Sparkle', heading: '' },
  { path: '/campaigns/insights', title: 'Campaigns Insights | Sparkle', heading: 'campaign_name' },
  { path: '/campaigns/analytics', title: 'Campaigns | Analytics', heading: 'Campaigns Analytics' },
  { path: '/campaigns', title: 'Campaigns | Sparkle', heading: 'Campaigns' },
  { path: '/blacklist-monitor', title: 'Blacklist Monitor | Sparkle', heading: 'Blacklist Monitor' },
  { path: '/sender-accounts', title: 'Sender Accounts | Sparkle', heading: 'Sender Accounts' },
  { path: '/email-verifier', title: 'Email Verifier | Sparkle', heading: 'Email Verifier' },
  { path: '/true-verifier', title: 'True Verifier | Sparkle', heading: 'True Verifier' },
  { path: '/live', title: 'Live | Sparkle', heading: 'Live' },
  { path: '/crm', title: 'CRM | Sparkle', heading: 'CRM', navigation: { title: 'Back', path: '/crm' } },

  { path: '/inbox', title: 'Unified Inbox | Sparkle', heading: 'Unified Inbox' },
  { path: '/brands', title: 'Brands | Sparkle', heading: 'Brands' },
  { path: '/unmatched-emails', title: 'Unmatched Emails | Sparkle', heading: 'Unmatched Emails' },
  { path: '/admin-tickets', title: 'Admin Tickets | Sparkle', heading: '' },
  { path: '/admin-accounts', title: 'Admin Accounts | Sparkle', heading: '' },
  { path: '/admin-settings', title: 'Admin Settings | Sparkle', heading: '' },
  { path: '/settings', title: 'Settings | Sparkle', heading: 'Settings', navigation: { title: 'Back', path: '' } },

  { path: '/single-email-verifier', title: 'Single Verifier | Sparkle', heading: 'Single Email Verifier' },

  { path: '/', title: 'Dashboard | Sparkle', heading: 'Dashboard' },
];

export const CONSTANT_TEXTS = {
  BILLINGS: 'billings',
};

export const LOGIN_STORAGE_VAR = '_logged_user_details';
