import Button from 'components/common/Button';
import { HighChart } from 'components/common/Chart/HighChart';
import { crmStageProgressOptions, overviewFunnelChartOptions, MultiChannelSequenceFlowOptions } from 'dto/initial-value/views/campaign-analytics';
import moment from 'moment';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { BsTable } from 'react-icons/bs';
import { FaCodeBranch } from 'react-icons/fa';
import { ImLinkedin } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { PiChartBarHorizontalFill } from 'react-icons/pi';
import { RiWhatsappFill } from 'react-icons/ri';
import { useAppSelector } from 'redux-store';

export const tableData: any = [
  {
    id: '70828637-daff-4343-a8e0-6b2f02eff8b4',
    type: 'email',
    delay: {
      days: 0,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: null,
    trigger: {
      yes: '8a963f51-bfef-476d-81a7-2b2ae0639691-yes',
      no: '8a963f51-bfef-476d-81a7-2b2ae0639691-no',
      trigger_variant: 'email',
      trigger_type: 'replied',
      trigger_waiting_period: {
        days: 1,
      },
    },
  },
  {
    id: '8a963f51-bfef-476d-81a7-2b2ae0639691-yes',
    type: 'email',
    delay: {
      days: 1,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div id="isPasted" fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: 'yes',
    trigger: {
      yes: '3ae163bc-4e04-4d91-87b5-432ffa3b4f07-yes',
      no: '3ae163bc-4e04-4d91-87b5-432ffa3b4f07-no',
      trigger_variant: 'email',
      trigger_type: 'click_on_link',
      trigger_waiting_period: {
        days: 1,
      },
    },
  },
  {
    id: '3ae163bc-4e04-4d91-87b5-432ffa3b4f07-yes',
    type: 'email',
    delay: {
      days: 1,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div id="isPasted" fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: 'yes',
    trigger: null,
  },
  {
    id: '3ae163bc-4e04-4d91-87b5-432ffa3b4f07-no',
    type: 'email',
    delay: {
      days: 1,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div id="isPasted" fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: 'no',
    trigger: null,
  },
  {
    id: '8a963f51-bfef-476d-81a7-2b2ae0639691-no',
    type: 'email',
    delay: {
      days: 1,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div id="isPasted" fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: 'no',
    trigger: {
      yes: 'd785b161-1334-4c15-b2a4-f68ffe481574-yes',
      no: 'd785b161-1334-4c15-b2a4-f68ffe481574-no',
      trigger_variant: 'email',
      trigger_type: 'opened',
      trigger_waiting_period: {
        days: 1,
      },
    },
  },
  {
    id: 'd785b161-1334-4c15-b2a4-f68ffe481574-yes',
    type: 'email',
    delay: {
      days: 1,
    },
    delivery_data: {
      version_a: {
        subject: 'Hi - {{ first_name }} ',
        message: '<div id="isPasted" fr-original-style="" style="border-width: 0px; border-style: solid; box-sizing: border-box; overflow-wrap: break-word; border-color: var(--chakra-colors-chakra-border-color);">sadasd</div>',
        attachments: [],
      },
      version_b: {
        subject: '',
        message: '',
        attachments: [],
      },
    },
    versionAB: false,
    next: null,
    trigger_on: 'yes',
    trigger: null,
  },
];

export const channelData = [
  {
    id: 'email',
    name: 'Email',
    icon: <MdEmail />,
    color: 'text-[#015AFF]',
  },
  {
    id: 'linkedin',
    name: 'Linkedin',
    icon: <ImLinkedin />,
    color: 'text-[#0A66C2]',
  },
  {
    id: 'whatsapp',
    name: 'Whatsapp',
    icon: <RiWhatsappFill />,
    color: 'text-[#12B76A]',
  },
  {
    id: 'call',
    name: 'Call',
    icon: <BiSolidPhoneCall />,
    color: 'text-[#12B76A]',
  },
  {
    id: 'sms',
    name: 'SMS',
    icon: <MdEmail />,
    color: 'text-[#015AFF]',
  },
];

const Overview = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const funnel = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.funnel);
  const crm_stage_progression = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.crm_stage_progression);
  const multisequence_flow_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.multisequence_flow_data);

  const [state, setState] = React.useState<{ multiChannelSequenceFlowTypeTable: boolean; trigger_type_obj: { [key: string]: 'yes' | 'no' }; sequence_data: any[]; sequence_table_data: any[]; crm_stage_progression_data: any[]; channel_performance_data: any[] }>({
    multiChannelSequenceFlowTypeTable: true,
    trigger_type_obj: {},
    sequence_data: tableData,
    sequence_table_data: [],
    channel_performance_data: [],
    crm_stage_progression_data: [],
  });

  const { multiChannelSequenceFlowTypeTable, trigger_type_obj, sequence_data, sequence_table_data } = state;

  const getTableData = (sequences: any) => {
    const finalTableData: any[] = [];
    if (sequences?.length) {
      finalTableData?.push(sequences[0]);
      if (sequences?.length > 1) {
        const recursiveFnToGetOrderSequenceData = (next: string, yes_trigger: string, no_trigger: string, trigger_type: 'yes' | 'no') => {
          if (!next && !yes_trigger && !no_trigger) return;
          if (next) {
            const findSequence = sequences?.find((sequence: any) => sequence?.id === next);
            if (findSequence) {
              finalTableData?.push(findSequence);
              recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
            }
          } else if (trigger_type === 'yes') {
            if (yes_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === yes_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          } else if (trigger_type === 'no') {
            if (no_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === no_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          }
        };
        recursiveFnToGetOrderSequenceData(sequences[0]?.next, sequences[0]?.trigger?.yes, sequences[0]?.trigger?.no, state?.trigger_type_obj[sequences[0]?.id] || 'yes');
      }
    }

    return finalTableData;
  };

  React.useEffect(() => {
    const sequence = getTableData(sequence_data);
    const sequence_trigger_type_obj = sequence_table_data?.reduce((acc, item) => {
      if (item?.trigger) acc[item?.id] = trigger_type_obj[item?.id] || 'yes';
      return acc;
    }, {});

    setState((prev) => {
      const prev_trigger_obj = JSON.stringify(prev?.trigger_type_obj);
      const new_trigger_obj = JSON.stringify(sequence_trigger_type_obj);
      const final_trigger_obj = prev_trigger_obj === new_trigger_obj ? prev?.trigger_type_obj : sequence_trigger_type_obj;

      return { ...prev, sequence_table_data: sequence, trigger_type_obj: final_trigger_obj };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger_type_obj]);

  const colors = ['#FF6347', '#87CEEB', '#3CB371', '#6A5ACD', '#6982B4'];
  const getCRMStageProgressionSeries = () => {
    return funnel?.map((stage: any, index: number) => ({
      name: stage?.stage_name,
      data: crm_stage_progression
        ?.filter((data: any) => data?.stages?.some((stage_data: any) => stage_data?.stage_name === stage?.stage_name))
        ?.map((item: any) => {
          const data = item?.stages?.find((item: any) => item?.stage_name === stage?.stage_name);
          return [moment(item?.date).valueOf(), data?.stage_count];
        }),
      stack: 'crm',
      color: colors[index],
    }));
  };

  // const getChannelPerformanceSeries = () => {
  //   return [
  //     {
  //       data: [
  //         { y: 0, color: '#25D366', name: 'WhatsApp' },
  //         { y: channel_performance_data?.[0]?.repliedPct || 0, z: channel_performance_data?.[0]?.totalReplied || 0, color: '#7E60FF', name: 'Email' },
  //         { y: 0, color: '#008FFB', name: 'Call' },
  //         { y: 0, color: '#FFB01A', name: 'SMS' },
  //         { y: 0, color: '#008FFB', name: 'LinkedIn' },
  //       ],
  //     },
  //   ];
  // };

  const funnelChartSeries = [
    {
      type: 'funnel',
      data: funnel?.map((item: any, index: number) => ({ name: item?.stage_name, y: item?.stage_count, color: colors[index] })),
    },
  ];

  const sequenceSeries = [
    {
      pointWidth: 30,
      pointBorderRadius: 0,
      dataLabels: {
        enabled: true,
        formatter(this: any) {
          return this.point.value;
        },
      },
      data: multisequence_flow_data
        ?.map((item: any) => {
          const data = [];
          data?.push({
            x: moment(item?.send_start_time).valueOf(),
            x2: moment(item?.send_end_time).valueOf(),
            value: `${item?.send_count || 0} Sent`,
            type: item?.type,
            totat_sent: item?.send_count || 0,
            opened_count: item?.opened_count || 0,
            replied_count: item?.replied_count || 0,
            clicked_count: item?.clicked_count || 0,
            bounced_count: item?.bounced_count || 0,
            unsubscribed_count: item?.unsub_count || 0,
            color: '#015AFF',
          });

          if (item?.schedule_count) {
            data?.push({
              x: moment(item?.schedule_start_time).valueOf(),
              x2: moment(item?.schedule_end_time).valueOf(),
              value: `${item?.schedule_count || 0} Scheduled`,
              type: item?.type,
              color: '#015AFF',
            });
          }

          if (item?.trigger) {
            data.push({
              x: Date.UTC(0, 0, 0),
              x2: Date.UTC(0, 0, 0),
            });
          }
          return data;
        })
        .flat()
        .map((item: any, index: number) => ({ ...item, y: index })),
    },
  ];

  console.log('sequenceSeries====>', sequenceSeries);

  return (
    <div className={`d grid grid-cols-6 gap-4 overflow-y-auto`}>
      <div className="relative col-span-3 flex h-[28.125rem] flex-col overflow-hidden rounded-md border-2 border-graybg p-3">
        <div className="flex items-center  gap-1">
          <div className="flex items-center gap-1">
            <h2 className="text-[13px] font-medium dark:text-white">Lead Conversion Funnel</h2>
            <div className="group relative inline-block">
              <AiOutlineInfoCircle className="cursor-pointer" color="#667085" size={13} />
              {/* <div className="absolute left-[26px] z-50 mb-2 mt-[2px] -translate-x-1/2 whitespace-nowrap rounded bg-gray-700 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">This funnel shows how leads convert through different stages.</div> */}
            </div>
          </div>
        </div>
        <HighChart chartOptions={overviewFunnelChartOptions(theme)} isDataNotExist={funnel === null || funnel?.length === 0} series={funnelChartSeries} />
      </div>
      <div className="col-span-3 flex h-[450px] flex-col rounded-md border-2 border-graybg p-3">
        <HighChart
          extraContent={
            <div className="flex flex-col items-center justify-center gap-4 pt-1">
              <p className="text-sm font-medium">Set up CRM Stages to visualize your sales pipeline and track conversions.</p>
              <Button name="Setup CRM Stages" customClass="w-fit text-sm dark:border-white/10" />
            </div>
          }
          chartOptions={crmStageProgressOptions(theme)}
          series={getCRMStageProgressionSeries()}
          isDataNotExist={crm_stage_progression === null || crm_stage_progression?.length === 0}
        />
      </div>
      {/* <div className="col-span-2 flex h-[21.875rem] flex-col rounded-md border-2 border-graybg p-3">
        <HighChart title="Channel Performance" chartOptions={channelPerformanceOptions(theme)} series={getChannelPerformanceSeries()} isDataNotExist={channel_performance_data?.length === 0} />
      </div> */}
      <div className="col-span-6 flex min-h-[26.875rem] flex-col rounded-md border-2 border-graybg">
        <div className="flex justify-between border-b p-3">
          <div className="mt-2 select-none text-[.8125rem] font-medium">Multichannel Sequence flow</div>
          <div className="flex cursor-pointer items-center justify-center text-column" onClick={() => setState((prev) => ({ ...prev, multiChannelSequenceFlowTypeTable: !prev?.multiChannelSequenceFlowTypeTable }))}>
            {multiChannelSequenceFlowTypeTable ? <PiChartBarHorizontalFill size={20} /> : <BsTable />}
          </div>
        </div>
        {multiChannelSequenceFlowTypeTable ? (
          <table className="w-full">
            <thead className="border-b bg-gray-750 text-[11px] text-column dark:border-white/10">
              <td className="w-12 p-3 font-[550]">Channel</td>
              <td className="w-36 p-3 font-[550]">Step no</td>
              <td className="p-3 font-[550]">Sent</td>
              <td className="p-3 font-[550]">Opened</td>
              <td className="p-3 font-[550]">Replied</td>
              <td className="p-3 font-[550]">Clicked</td>
              <td className="p-3 font-[550]">Bounced</td>
              <td className="p-3 font-[550]">Unsubscribed</td>
            </thead>
            <tbody>
              {multisequence_flow_data?.map((ele: any, index: number) => {
                const channel = channelData?.find((item) => item.id === ele?.type);
                return (
                  <>
                    <tr>
                      <td className="border-b p-4 text-xs dark:border-white/10">
                        <div className="w-full">
                          <div className={`${channel?.color} flex justify-center text-[14px]`}>{channel?.icon}</div>
                          <div className="text-center text-[12px] font-medium text-[#101828] dark:text-white">{channel?.name}</div>
                        </div>
                      </td>
                      <td className="border-b p-4 text-[12px] font-medium text-[#101828] dark:border-white/10 dark:text-white">Step {index + 1}</td>
                      <td className="border-b p-4 text-xs dark:border-white/10 ">
                        <span className="cursor-pointer text-blueSecondary underline">{ele?.send_count || 'NA'}</span>
                      </td>
                      <td className="border-b p-4 text-xs dark:border-white/10">
                        <span className="cursor-pointer text-blueSecondary underline">{ele?.opened_count || 'NA'}</span>
                      </td>
                      <td className="border-b p-4 text-xs dark:border-white/10">
                        <span className="cursor-pointer text-blueSecondary underline">{ele?.replied_count || 'NA'}</span>
                      </td>
                      <td className="border-b p-4 text-xs dark:border-white/10">
                        <span className="cursor-pointer text-blueSecondary underline">{ele?.clicked_count || 'NA'}</span>
                      </td>
                      <td className="border-b p-4 text-xs dark:border-white/10">
                        <span className="cursor-pointer text-blueSecondary underline">{ele?.bounced_count || 'NA'}</span>
                      </td>
                      <td className="border-b p-4 text-xs">
                        <span className="text-blueSecondary underline">{ele?.replied_count || 'NA'}</span>
                      </td>
                    </tr>
                    {ele?.trigger && (
                      <tr className="h-12 border-b dark:border-white/10">
                        <td className="bg-gray-750">
                          <div className="w-full">
                            <div className={`flex justify-center text-xs`}>
                              <FaCodeBranch className={`${theme === 'dark' ? 'text-white' : 'text-black'}`} />
                            </div>
                            <div className="text-center text-[12px] font-medium text-[#101828] dark:text-white">Cond.</div>
                          </div>
                        </td>
                        <td className="bg-gray-750">
                          <div className="w-full">
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'yes' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-white text-column' : 'bg-blueSecondary text-white'}`}>
                              Yes
                            </button>
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'no' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-blueSecondary text-white' : 'bg-white text-column'}`}>
                              No
                            </button>
                          </div>
                        </td>
                        <td className="bg-gray-750"></td>
                        <td className="bg-gray-750"></td>
                        <td className="bg-gray-750"></td>
                        <td className="bg-gray-750"></td>
                        <td className="bg-gray-750"></td>
                        <td className="bg-gray-750"></td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="flex h-full flex-col pl-4 pt-4">
            <div className="select-none text-[.8125rem] font-medium">Sequence Details</div>

            <div className="grid grid-cols-7 ">
              <div className="col-span-1 flex flex-col pb-[43px] pt-[10px]">
                {multisequence_flow_data?.map((ele: any, index: number) => {
                  const channel = channelData?.find((item) => item.id === ele?.type);
                  return (
                    <>
                      <div className="flex h-full items-center justify-center border-b border-r dark:border-white/10">
                        <div className="w-full">
                          <div className={`${channel?.color} flex justify-center text-[14px]`}>{channel?.icon}</div>
                          <div className="whitespace-pre text-center text-[12px] font-medium text-[#101828]">{channel?.name}</div>
                        </div>
                        <div className="whitespace-pre p-4 text-[12px] font-medium text-[#101828]">Step {index + 1}</div>
                      </div>

                      {ele?.trigger && (
                        <div className="flex h-full items-center justify-center border-b border-r bg-gray-750 dark:border-white/10">
                          <div className="w-full">
                            <div className={`flex justify-center text-xs`}>
                              <FaCodeBranch className={`${theme === 'dark' ? 'text-white' : 'text-black'}`} />
                            </div>
                            <div className="text-center text-[12px] font-medium text-[#101828]">Cond.</div>
                          </div>

                          <div className="w-full">
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'yes' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-white text-column' : 'bg-blueSecondary text-white'}`}>
                              Yes
                            </button>
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'no' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-blueSecondary text-white' : 'bg-white text-column'}`}>
                              No
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="col-span-6">
                <HighChart chartOptions={MultiChannelSequenceFlowOptions(theme)} series={sequenceSeries} isDataNotExist={multisequence_flow_data?.length === 0} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
