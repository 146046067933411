import axios, { AxiosInstance } from 'axios';
import { API_RESPONSE_TYPE, ENV_API_BASEURL } from 'config/envConfig';
import { CONSTANTS } from 'constants/constants';
import { toast } from 'react-toastify';
import { LocalStorage } from 'services/local.storage.service';
import { cryptoDecrypt, generateUUID, jwtDecrypt, toastState } from 'utility/utils';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: ENV_API_BASEURL,
});

axiosInstance.interceptors.request.use(
  async (request: any) => {
    const state: any = LocalStorage.getString(CONSTANTS.LOGIN_STORAGE_VAR) ? await cryptoDecrypt(LocalStorage.getString(CONSTANTS.LOGIN_STORAGE_VAR), true) : null;
    const prevHeaders = request.headers || {};
    request.headers = {
      ...prevHeaders,
      'Client-Path': window.location.pathname,
      'Idempotency-Key': generateUUID(),
      'X-Correlation-ID': generateUUID(),
      'Api-Response': prevHeaders['Api-Response'] || API_RESPONSE_TYPE,
    };
    if (state) {
      request.headers.Authorization = `Bearer ${state?.authToken}`;
    }
    return request;
  },
  async (error) => {
    await Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const apiResponseType = response.config.headers['Api-Response'];
    if (apiResponseType === 'jwt') {
      try {
        const decodedData = jwtDecrypt(response.data?.data);
        return { ...response?.data, data: decodedData };
      } catch (error) {
        console.error('Instance JWT decode Error:', error);
        return response?.data;
      }
    }
    return response?.data;
  },
  async (error) => {
    const status = error?.response?.status;
    const message = error?.response?.data?.message || 'Something went wrong';
    const errorMessages: Record<number, string> = {
      401: 'Unauthorized',
      400: 'Bad Request',
      403: 'Forbidden',
      404: 'Data not found',
      409: 'Conflict',
      500: 'Internal server error',
      502: 'Bad gateway',
      503: 'Service unavailable',
      504: 'Gateway timeout',
      429: 'Too many requests',
      422: 'Unprocessable Entity',
    };

    toast.error(message || errorMessages[status], toastState.error);
    if (status === 401) {
      LocalStorage.clearItem(CONSTANTS.LOGIN_STORAGE_VAR);
    }
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
