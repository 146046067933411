import { Suspense, lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from 'redux-store';
import useFetchSiteAccess from 'hooks/useSiteAccessHook';
import { CheckLoggedUserLoader, ProtectedHomeRouteMiddleware } from 'middleware/ProtectedRouteMiddleware';
import AppLoader from 'layouts/components/app-global-component/AppLoader';
import EmailVerifierDashBoard from 'views/email-verifier-dashboard/pages';
import CampaignAnalyticsHomePage from 'views/campaign-analytics/pages';
import DomainMonitoring from 'views/health-check/components/Domain_IP_Monitoring';
import IPMonitoring from 'views/health-check/components/IP_Monitoring';

// app admin routes for admin
const AppAdminControlCenter = lazy(() => import('views/admin/pages/ControlCenter'));
const AppAdminPAYGEmailAccountsProviders = lazy(() => import('views/admin/pages/PAYGEmailAccountsProviders'));
const AppAdminRoleAccessibilityMenus = lazy(() => import('views/admin/pages/RoleAccessibilityMenus'));
const AppAdminCreditPriceDiscount = lazy(() => import('views/admin/pages/credits-price-discount'));
const AppAdminCoupon = lazy(() => import('views/admin/pages/Coupon'));
const AppAdminEvToolsVerifyResultComparison = lazy(() => import('views/admin/pages/EvToolsVerifyResultComparison'));
const AppAdminEvThirdPartyToolsIntegration = lazy(() => import('views/admin/pages/EvThirdPartyToolsIntegration'));

const AddDoNotEmailList = lazy(() => import('views/do-not-email/AddDonotEmails'));
const AddEmailAccounts = lazy(() => import('views/sender-accounts/pages/add-email-account'));
const AllInboxData = lazy(() => import('views/Inbox/pages'));
const ApiKeyGeneratingComponent = lazy(() => import('views/api-key/pages'));
const Billing = lazy(() => import('views/billing/pages'));
const Blacklist = lazy(() => import('views/blacklist/pages'));
// const BrandList = lazy(() => import('views/brands/pages/index-old'));
const BrandList = lazy(() => import('views/brands/pages/index'));
const HealthCheck = lazy(() => import('views/health-check/pages/index'));
const CampaignCreatePage = lazy(() => import('views/campaigns/pages/campaign-creation/index'));
// const CampaignListv2 = lazy(() => import('views/campaigns-v2'));
const CampaignListv2 = lazy(() => import('views/campaigns/pages/campaign-list/index'));
const CRM = lazy(() => import('views/crm'));
const DonotEmailList = lazy(() => import('views/do-not-email'));
// const EmailSyncList = lazy(() => import('views/email-sync/pages'));
const EmailVerificationFlow = lazy(() => import('views/email-verifier/pages/EmailVerificationFlow'));
const EmailVerificationList = lazy(() => import('views/email-verifier/pages'));
const EmailVerificationResult = lazy(() => import('views/email-verifier/pages/EmailVerificationResult'));
const ErrorBoundary = lazy(() => import('layouts/ErrorBoundary'));
const ForgotPassword = lazy(() => import('views/auth/pages/forgot-password'));
const HomeLayout = lazy(() => import('layouts/HomeLayout'));
const Inbox = lazy(() => import('views/crm/features/inbox'));
const Notifications = lazy(() => import('views/notification/pages'));
const OnboardCloseStage = lazy(() => import('views/crm-onboarding/components/OnboardCloseStage'));
const OnboardConnectCalendar = lazy(() => import('views/crm-onboarding/components/OnboardConnectCalendar'));
const OnboardConnectSalesAccount = lazy(() => import('views/crm-onboarding/components/OnboardConnectSalesAccount'));
const OnboardCustomizePipeline = lazy(() => import('views/crm-onboarding/components/OnboardCustomizePipeline'));
const OnboardDefineStages = lazy(() => import('views/crm-onboarding/components/OnboardDefineStages'));
const OnboardSelectBrand = lazy(() => import('views/crm-onboarding/pages'));
const OnboardUploadProspects = lazy(() => import('views/crm-onboarding/components/OnboardUploadProspects'));
const PersonalDetails = lazy(() => import('views/personal-details/pages'));
// const ProtectedRoute = lazy(() => import('middleware/ProtectedRouteMiddleware'));
const ResetPassword = lazy(() => import('views/auth/pages/reset-password'));
const Rewards = lazy(() => import('views/rewards/pages'));
const Security = lazy(() => import('views/security/pages'));
const SenderAccountsList = lazy(() => import('views/sender-accounts/pages'));
const SignIn = lazy(() => import('views/auth/pages/signin'));
const SignUp = lazy(() => import('views/auth/pages/signup'));
const SingleEmailVerifier = lazy(() => import('views/single-email-verifier/pages'));
// const SyncFlowIntegrationsList = lazy(() => import('views/email-sync/components/SyncFlowIntegrationsList'));
const Team = lazy(() => import('views/team/pages'));
const TrueVerifierTable = lazy(() => import('views/true-verifier/pages'));
const UnifiedEmailInboxData = lazy(() => import('views/Inbox/component/inboxVariant/UnifiedEmailInboxData'));
const UnifiedNotificationInboxData = lazy(() => import('views/Inbox/component/inboxVariant/UnifiedNotificationInboxData'));
const UnmatchedEmails = lazy(() => import('views/unmatched-emails/pages'));

const AppBrowserRoutes = (): React.ReactElement => {
  const appAccessEnabledFeature = useFetchSiteAccess()
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedHomeRouteMiddleware>
          <HomeLayout />
        </ProtectedHomeRouteMiddleware>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={appAccessEnabledFeature?.app_default_feature?.base_url} />,
        },
        {
          path: '/campaigns',
          children: [
            {
              path: '',
              element: <CampaignListv2 />,
            },
            {
              path: '/campaigns/create',
              element: <CampaignCreatePage />,
            },
            {
              path:'analytics',
              element:<CampaignAnalyticsHomePage/>
            }
          ],
        },
        {
          path: '/blacklist-monitor',
          element: <Blacklist />,
        },
        {
          path: '/crm',
          children: [
            {
              path: '',
              element: <CRM />,
            },
            {
              path: 'inbox',
              element: <Inbox />,
            },
          ],
        },
        {
          path: '/unmatched-emails',
          children: [
            {
              path: '',
              element: <UnmatchedEmails />,
            },
          ],
        },
        {
          path: '/inbox',
          children: [
            {
              path: '',
              element: <AllInboxData />,
            },
            {
              path: 'email',
              element: <UnifiedEmailInboxData />,
            },
            {
              path: 'notification',
              element: <UnifiedNotificationInboxData />,
            },
          ],
        },
        {
          path: '/email-verifier',
          children: [
            {
              path: '',
              element: <EmailVerificationList />,
            },
            {
              path: 'create',
              element: <EmailVerificationFlow />,
            },
            {
              path: 'result',
              element: <EmailVerificationResult />,
            },
            {
              path: 'dashboard',
              element: <EmailVerifierDashBoard />,
            },
          ],
        },
        {
          path: '/true-verifier',
          element: <TrueVerifierTable />,
        },
        {
          path: 'sender-accounts',
          children: [
            {
              path: '',
              element: <SenderAccountsList />,
            },
            {
              path: 'add/email',
              element: <AddEmailAccounts />,
            },
          ],
        },
        {
          path: '/email-verifier/v2',
          children: [
            {
              path: '',
              element: <EmailVerificationList />,
            },
            {
              path: 'create',
              element: <EmailVerificationFlow />,
            },
            {
              path: 'result',
              element: <EmailVerificationResult />,
            },
            // {
            //   path: 'sync',
            //   element: <EmailSyncList />,
            // },
            // {
            //   path: 'sync/create',
            //   element: <SyncFlowIntegrationsList />,
            // },
          ],
        },
        {
          path: '/unmatched-emails',
          element: <UnmatchedEmails />,
        },
        {
          path: '/true-verifier',
          element: <TrueVerifierTable />,
        },
        {
          path: '/brands',
          element: <BrandList />,
        },
        {
          path: '/blacklist-monitor',
          element: <Blacklist />,
        },
        {
          path: '/inbox',
          children: [
            {
              path: '',
              element: <AllInboxData />,
            },
            {
              path: 'email',
              element: <UnifiedEmailInboxData />,
            },
            {
              path: 'notification',
              element: <UnifiedNotificationInboxData />,
            },
          ],
        },
        {
          path: '/single-email-verifier',
          element: <SingleEmailVerifier />,
        },
        {
          path: '/brands',
          element: <BrandList />,
        },
        {
          path: '/ed-ipdomain-tracker',
        
          children: [
            {
              path:'',
              element: <HealthCheck />,
            },
            {
              path: 'domain-report',
              element: <DomainMonitoring />,
            },
            {
              path: 'ip-report',
              element: <IPMonitoring />,
            },
          ]
        },
        {
          path: '/app-admin',
          children: [
            {
              path: 'coupon',
              element: <AppAdminCoupon />, //email-payg-accounts
            },
            {
              path: 'credits-price-discount', //app-live-keys
              element: <AppAdminCreditPriceDiscount />,
            },
            {
              path: 'ev-tools-verify-result-comparison',
              element: <AppAdminEvToolsVerifyResultComparison />,
            },
            {
              path: 'ev-third-party-tools-integration',
              element: <AppAdminEvThirdPartyToolsIntegration />,
            },
            {
              path: 'role-accessibility-menus',
              element: <AppAdminRoleAccessibilityMenus />,
            },
            {
              path: 'payg-email-accounts-providers',
              element: <AppAdminPAYGEmailAccountsProviders />,
            },
            {
              path: 'control-center',
              element: <AppAdminControlCenter />,
            },
            // {
            //   path: 'role-menus',
            //   element: <RoleMenuList />,
            // },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              path: 'personal/details',
              index: true,
              element: <PersonalDetails />,
            },
            {
              path: 'team',
              element: <Team />,
            },
            {
              path: 'api-keys',
              element: <ApiKeyGeneratingComponent />,
            },
            {
              path: 'notifications',
              element: <Notifications />,
            },
            {
              path: 'rewards',
              element: <Rewards />,
            },
            {
              path: 'security',
              element: <Security />,
            },
            {
              path: 'billing',
              element: <Billing />,
            },
            {
              path: 'donot/list',
              element: <DonotEmailList />,
            },
            {
              path: 'donot/list/add',
              element: <AddDoNotEmailList />,
            },
          ],
        },
      ],
    },
    {
      path: '/crm/onboard',
      children: [
        {
          path: '',
          element: <OnboardSelectBrand />,
        },
        {
          path: 'upload/prospect',
          element: <OnboardUploadProspects />,
        },
        {
          path: 'connect/accounts',
          element: <OnboardConnectSalesAccount />,
        },
        {
          path: 'connect/calendars',
          element: <OnboardConnectCalendar />,
        },
        {
          path: 'connect/pipeline',
          element: <OnboardCustomizePipeline />,
        },
        {
          path: 'pipeline/stages',
          element: <OnboardDefineStages />,
        },
        {
          path: 'stages/close',
          element: <OnboardCloseStage />,
        },
      ],
    },
    {
      path: '/sign-in',
      element: <SignIn />,
      loader: () => CheckLoggedUserLoader(appAccessEnabledFeature?.app_default_feature),
    },
    {
      path: '/sign-up',
      element: <SignUp />,
      loader: () => CheckLoggedUserLoader(appAccessEnabledFeature?.app_default_feature),
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
      loader: () => CheckLoggedUserLoader(appAccessEnabledFeature?.app_default_feature),
    },
    {
      path: '/reset/password/:token/:uuid',
      element: <ResetPassword />,
    },
    {
      path: '*',
      element: <Navigate to={appAccessEnabledFeature?.app_default_feature?.base_url || '/email-verifier'} />,
    },
  ]);
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <Suspense fallback={<AppLoader />}>
            <RouterProvider router={appRouter} />
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </HelmetProvider>
  );
};
export default AppBrowserRoutes;
