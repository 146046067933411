import { HighChart } from 'components/common/Chart/HighChart';
import { useAppDispatch, useAppSelector } from 'redux-store';
import TableSearchFilter from 'components/common/table/TableSearchFilter';
import Table from 'components/common/table/Table';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { senderAccountBubbleChartOptions } from 'dto/initial-value/views/campaign-analytics';
import { BaseApi } from 'api/services/base-api';
import { CustomMenu } from 'components/common/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import { setCommonDetailsInAnalytics, setGraphDataInAnalytics } from 'redux-store/campaign-analytics';
import { randomNumber } from 'utility/utils';
import { useSmartSetState } from 'hooks/useSmartSetState';

interface RowData {
  group_key: string;
  sentPct: number;
  openedPct: number;
  repliedPct: number;
}
const domainDropDownData = [
  { label: 'Domains', value: 'domains' },
  { label: 'Sender Account', value: 'senderAccount' },
];

const statsDropDownData = [
  { label: 'Sent Rate', value: 'sentPct' },
  { label: 'Replied Rate', value: 'repliedPct' },
  { label: 'Opened Rate', value: 'openedPct' },
];

const SenderAccount = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const campaign_details = useAppSelector((state) => state?.campaignAnalytics?.campaign_details);
  const sender_account_graph_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.sender_account_graph_data);
  const start_date = useAppSelector((state) => state?.campaignAnalytics?.common?.start_date);
  const end_date = useAppSelector((state) => state?.campaignAnalytics?.common?.end_date);
  const dispatch = useAppDispatch();
  const [state, setState] = React.useState({
    search: '',
    activeRadioBtn: 'domain',
    domainState: {
      open: false,
      selectedIndex: 0,
    },
    statsState: {
      open: false,
      selectedIndex: 0,
    },
  });
  const updateState = useSmartSetState(setState);
  const { search, activeRadioBtn, domainState, statsState } = state;

  const columnHelper = createColumnHelper<RowData>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('group_key', {
        header: activeRadioBtn === 'domain' ? "Domain" : "Sender Account",
        cell: (info) => info.getValue(),
        meta: { customHeaderClass: 'text-left pl-4 text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('sentPct', {
        header: 'Sent Rate',
        cell: (info) => {
          const value = Math.round(info.getValue());
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#b692f6">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('openedPct', {
        header: 'Open Rate',
        cell: (info) => {
          const value = Math.round(info.getValue());
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#2E90FA">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('repliedPct', {
        header: 'Reply Rate',
        cell: (info) => {
          const value = Math.round(info.getValue());
          return (
            <CircularProgress value={value || 0} size={'40px'} color="#00D8B6">
              <CircularProgressLabel>{value || 0}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-[13px] font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRadioBtn]
  );

  const filteredData = React.useMemo(() => {
    return (
      sender_account_graph_data?.filter((item: RowData) =>
        item?.group_key?.includes(search)
      ) ?? []
    );
  }, [sender_account_graph_data, search]);

  const table = useReactTable<RowData>({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const series = React.useMemo(
    () => [
      {
        type: 'bubble',
        data: sender_account_graph_data?.map((item: any) => {
          const randomX = randomNumber(1, 100);
          const randomY = randomNumber(1, 1000);
          return { x: randomX, y: randomY, z: item?.[statsDropDownData?.[statsState?.selectedIndex]?.value], title: statsDropDownData?.[statsState?.selectedIndex]?.label, account: item?.group_key };
        }),
      },
    ],
    [statsState?.selectedIndex, sender_account_graph_data]
  );

  React.useEffect(() => {
    (async () => {
      try {
        dispatch(setCommonDetailsInAnalytics({ loading: true }));

        const response = await BaseApi.post('campaign/analytics/domain-sender-data', {
          campaign_id: campaign_details?.uuid,
          variant_type: 'email',
          from_date: start_date,
          to_date: end_date,
          date_type: 'duration',
          list_type: activeRadioBtn,
        });
        dispatch(setGraphDataInAnalytics({ sender_account_graph_data: response?.data || null }));
      } catch (error) {
        console.log('Error', error);
      } finally {
        dispatch(setCommonDetailsInAnalytics({ loading: false }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRadioBtn]);

  return (
    <div className={`grid grid-cols-6 gap-4 overflow-hidden`}>
      <div className="col-span-6 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="align-items-center flex">
            <input className="mr-2" id="type-domain" name="senderAccount" type="radio" checked={activeRadioBtn === 'domain'} onClick={() => updateState({ activeRadioBtn: 'domain' })} />
            <label htmlFor="type-domain" className={`text-[13px] font-medium ${activeRadioBtn === 'domain' && 'text-[#422AFB]'} dark:text-blue-400`}>
              Domain
            </label>
          </div>
          <div className="align-items-center flex">
            <input className="mr-2" id="type-sender" name="sender" type="radio" checked={activeRadioBtn === 'sender'} onClick={() => updateState({ activeRadioBtn: 'sender' })} />
            <label htmlFor="type-sender" className={`text-[13px] font-medium ${activeRadioBtn === 'sender' && 'text-[#422AFB]'} dark:text-blue-400`}>
              Sender Account
            </label>
          </div>
        </div>
        <div className="flex items-center space-x-4 ">
          <CustomMenu
            open={domainState.open}
            onOpen={() => updateState({ domainState: { open: true } })}
            onClose={() => updateState({ domainState: { open: false } })}
            btnContent={domainDropDownData[domainState.selectedIndex].label}
            btnClassName="w-[12rem] justify-between !text-[13px] !rounded-md !font-medium truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF] dark-text-black text-center items-cemter campaign-search-btn"
            menuListClassName="w-full overflow-auto !mt-1 !pb-2 border border-menuBorder rounded-md dark:text-white"
            rightIcon={<BiChevronDown size={18} color="#667085" />}
          >
            {domainDropDownData.map((item, index) => (
              <div key={item.value} className="flex w-full cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() =>updateState({ domainState: { open: false, selectedIndex: index } })}>
                <span className="text-[13px] text-column dark:text-[#B0B7C3] dark:hover:text-black">{item.label}</span>
              </div>
            ))}
          </CustomMenu>
          <CustomMenu
            open={statsState.open}
            onOpen={() => updateState({ statsState: { open: true } })}
            onClose={() => updateState({ statsState: { open: false } })}
            btnContent={statsDropDownData[statsState.selectedIndex].label}
            btnClassName="w-[12rem] justify-between !text-[13px] !rounded-md !font-normal truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF]  text-center items-cemter campaign-search-btn"
            menuListClassName="w-full overflow-auto !mt-1 !pb-2 border border-menuBorder rounded-md "
            rightIcon={<BiChevronDown size={18} color="#667085" />}
          >
            {statsDropDownData.map((item, index) => (
              <div key={item.value} className="flex w-full cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() => updateState({ statsState: { open: false, selectedIndex: index } })}>
                <span className="text-[13px] text-column dark:text-[#B0B7C3] dark:hover:text-black">{item.label}</span>
              </div>
            ))}
          </CustomMenu>
        </div>
      </div>
      <div className="col-span-6 flex h-80 flex-col rounded-md border-2 border-graybg dark:border-white/10">
        <HighChart chartOptions={senderAccountBubbleChartOptions(theme)} isDataNotExist={sender_account_graph_data === null || sender_account_graph_data?.length === 0} series={series} />
      </div>
      <div className="col-span-6 h-[26.875rem] rounded-md border-2 border-graybg dark:border-white/10">
        <div className="mb-4 ml-4 mr-0 mt-4">
          <TableSearchFilter setState={setState} />
        </div>
        <Table customClass="h-[22.5rem]" table={table} data={filteredData} tableDataListStatus={true} />
      </div>
    </div>
  );
};

export default SenderAccount;
