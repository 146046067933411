/* eslint-disable @typescript-eslint/no-unused-vars */

import { _getQueryParams } from 'utility/utils';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Button from 'components/common/Button';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { ReportAccordationPopupStateIV } from 'dto/initial-value/views/health-check';
import { AccordionState } from 'dto/types/views/notification';
import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FaMedal } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GetOneEDSingleValidateAPI } from 'shared/ed-ipdomain/api-hanlder';
import domainData from '../../../helpers/json/health-check-calculation.json';
import { getMatchingValues } from '../../../utility/utils';
import DomainIPDrawer from './DomainIP_BlackList_Drawer';

const IPMonitoring = () => {
  const [report, setReport] = useState(null);
  const navigate = useNavigate();

  const [openAccordion, setOpenAccordion] = React.useState<AccordionState>(ReportAccordationPopupStateIV);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const [ip_healthscore, setIPHealthScore] = React.useState<number>(0);

  const [ip_address, setIPAddress] = React.useState<string>('');

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState?.activeIndex === index ? null : index,
    }));
  };

  const iconMapping = {
    RDNS: FaMedal,
  };

  type Status = 'pass' | 'no records' | 'warning';

  const accordionData: { title: keyof typeof iconMapping; index: number; status: Status }[] = [{ title: 'RDNS', index: 0, status: 'warning' }];

  useEffect(() => {
    const storedUuid = sessionStorage.getItem('healthCheckUUID');
    const fetchData = async () => {
      const response = await GetOneEDSingleValidateAPI(storedUuid);
      if (!response?.error) {
        setReport(response?.data);
        setIPAddress(response?.data?.blacklist_name);
        console.log('>response?.data?.rdns_record?.fwd_rev_dns', !response?.data?.rdns_record?.error ? response?.data?.rdns_record?.fwd_rev_dns : false);
        let sum = getMatchingValues(response?.data?.blacklist_result, domainData, !response?.data?.rdns_record?.error ? response?.data?.rdns_record?.fwd_rev_dns : false);
       if(sum<0){
        sum = 100 + sum;
       }else{

         sum = 100 - sum;
       }
        setIPHealthScore(Math.floor(sum));
      } else {
        setReport({});
      }
    };
    storedUuid && fetchData();
  }, []);
  const handleBlacklistDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <div className="my-3 min-h-[88vh] w-full rounded-lg bg-white shadow-md">
      <div className="mb-4 flex items-center justify-between  px-5 pt-3">
        {/* Back Arrow and Title */}
        <div className="flex items-center space-x-3">
          <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-purple-100" style={{ color: '#5A33FF', backgroundColor: '#F6F2FF' }}>
            <IoIosArrowRoundBack
              size={24}
              onClick={() => {
                sessionStorage.setItem('healthCheck_type', 'ip');
                navigate('/ed-ipdomain-tracker');
              }}
            />
          </div>
          <div className="text-sm font-semibold" style={{ color: '#2B3674' }}>
            {ip_address}
          </div>
        </div>

        {/* Button */}
        <div>
          <Button id="manage_ip_with_sparkle_btn" name="Manage Domain with Sparkle" color="purple-600" customClass="text-xs border border-purple-600 bg-purple-50 px-4 py-2 rounded-lg" />
        </div>
      </div>
      <hr />
      <div className="flex flex-row">
        <div className=" my-5 ml-4 mr-4 w-[25%]">
          <div className="h-[252.8px] w-[312px] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <h5 className="py-4 pl-5 font-medium text-[#101828] ">Ip health score</h5>
            <hr />
            <div className="flex h-[192px] items-center justify-center">
              <CircularProgress
                value={ip_healthscore}
                max={100}
                size="10.3125rem"
                color={
                  ip_healthscore >= 80
                    ? '#16A34A' // green
                    : ip_healthscore >= 51
                    ? '#F59E0B' // orange
                    : '#DC2626' // red
                }
              >
                <CircularProgressLabel top={75}>
                  <span className="text-2xl font-medium">{Math.floor(ip_healthscore)}/ 100</span>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          </div>
          <div className="mt-5 h-[144px] w-[312px] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <div className="flex flex-row items-center justify-between">
              <h5 className="py-4 pl-5 font-medium text-[#101828]">Blacklist</h5> <IoIosArrowRoundForward size={42} color="#4F26FF" className="pr-[1rem]" onClick={handleBlacklistDrawer} />
            </div>
            <hr />
            <div className="flex h-[5.3125rem] items-center pl-4" onClick={handleBlacklistDrawer}>
              <h5 className="text-center text-xl font-semibold">
                <span className="text-3xl">{report?.no_of_blacklist_count}</span> / {report?.total_count}
              </h5>
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-col items-center gap-1">
          {accordionData?.map((item, accordionIndex) => (
            <div key={accordionIndex} className="mb-4 w-[930px] rounded-lg border border-[#E5E5E5] bg-white dark:border-darkBorder dark:bg-darkBg">
              {/* Accordion Header */}
              <div
                className="flex cursor-pointer items-center justify-between px-4 py-4"
                onClick={() => {
                  handleAccordionClick(item?.index);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex w-[5.5rem] flex-row items-center">
                    {React.createElement(iconMapping[item.title], { color: '#667085' })}
                    <p className="ml-2 text-sm font-medium text-[#667085] dark:text-white"> {item.title}</p>
                  </div>
                </div>

                <BiChevronDown color="#667085" className={`transition-transform duration-300 ease-in-out ${openAccordion?.activeIndex === item?.index ? 'rotate-180' : ''}`} />
              </div>

              {/* Accordion Content */}
              {openAccordion?.activeIndex === item?.index && (
                <div className="bg-[#F4F7F9] px-3 pb-1 pt-4">
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">IP Address</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{(!report?.rdns_record?.error && report?.rdns_record?.ip) || `No Data`}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">FWD/REV DNS Match</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{`${!report?.rdns_record?.error ? (report?.rdns_record?.fwd_rev_dns ? `Yes` : `No`) : `No`}`}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Hostname</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{(!report?.rdns_record?.error && report?.rdns_record?.rdns?.[0]) || `-`}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Domain</p>
                    <p className="mt-1 flex h-7 items-center text-sm font-medium text-[#2B3674]">{(!report?.rdns_record?.error && report?.rdns_record?.rdns?.[0]?.split('.').slice(-2).join('.')) || `-`}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {isDrawerOpen && <DomainIPDrawer data={report} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />}
    </div>
  );
};

export default IPMonitoring;
