export const CampaignAnalyticsEngagementVSWordCountChartOption = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: 'transparent',
    zooming: {
      mouseWheel: {
        type: 'x',
      },
    },
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    lineWidth: 0,
    tickWidth: 0,
    ...(theme === 'dark'
      ? {
          labels: {
            style: {
              color: '#FFFFFF', // White in dark mode, Black in light mode
            },
          },
        }
      : {
          labels: {
            style: {
              color: '#000', // White in dark mode, Black in light mode
            },
          },
        }),
  },
  yAxis: [
    {
      title: {
        text: null,
      },
      min: 0,
      max: 100,
      ...(theme === 'dark'
        ? {
            labels: {
              style: {
                color: '#FFFFFF', // White in dark mode, Black in light mode
              },
              format: '{value}%',
            },
          }
        : {
            labels: {
              style: {
                color: '#000', // White in dark mode, Black in light mode
              },
              format: '{value}%',
            },
          }),
      showFirstLabel: true,
    },
  ],
  tooltip: {
    pointFormat: `{series.name} <b>{point.y:,.0f}%</b><br/> Words Count <b>{point.x}</b>`,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2,
      marker: {
        enabled: false,
      },
    },
  },
});

export const SenderAccountBubbleChartOptions = (theme: string): Highcharts.Options => ({
  chart: {
    type: 'bubble',
    plotBorderWidth: 0,
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    series: {
      zoneAxis: 'z',
      zones: [
        {
          value: 25,
          color: '#d8e6fd',
        },
        {
          value: 50,
          color: '#b1cdfb',
        },
        {
          color: '#5a6cff',
        },
      ],
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
    bubble: {
      minSize: 10,
      maxSize: '40%',
      zMin: 0,
      zMax: 100,
      marker: {
        lineWidth: 2,
        lineColor: '#3d83f6',
      },
    },
  },
  tooltip: {
    useHTML: true,
    backgroundColor: '#2b3674',
    borderWidth: 0,
    borderRadius: 4,
    shadow: false,
    style: {
      color: '#fff',
      fontFamily: 'sans-serif',
    },
    formatter: function () {
      return `
        <div>
            <div>johndoe@example.com</div>
            <div style="margin-top: 8px">Sent Rate: 75%</div>
        </div>
    `;
    },
  },
});

export const crmStageProgressOptions = (theme: string): any => ({
  chart: {
    type: 'column',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  xAxis: {
    categories: ['17/01/2024', '18/01/2024', '19/01/2024', '20/01/2024', '21/01/2024', '22/01/2024'],
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    crosshair: true,
  },

  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },

  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'horizontal',
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 40,
      borderWidth: 0,
    },
  },
});

export const channelPerformanceOptions = (theme: string): any => ({
  chart: {
    type: 'column',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },

  subtitle: {
    text: null,
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  xAxis: {
    categories: ['Whatsapp', ' email', '  call', ' SMS', 'LinkedIn'],
    crosshair: true,
    accessibility: {
      description: 'Countries',
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis label color
      },
    },
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis title color
      },
    },
  },

  yAxis: {
    allowDecimals: true,
    min: 0,
    max: 100,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis title color
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis label color
      },
    },
  },

  tooltip: {
    backgroundColor: '#2B3674',
    borderRadius: 8, // Rounded corners
    borderWidth: 0, // Remove border
    style: {
      color: '#ffffff',
      padding: '10px', // Add padding
    },
    headerFormat: '',
    pointFormat: `
      <div style="width: 260px; display: flex; flex-direction: column; gap: 8px;">
        <div style="display: flex; align-items: center; gap: 8px;">
          <span style="display: inline-block; width: 10px; height: 10px; background-color: {point.color}; border-radius: 50%;"></span>
          <span>{point.name}</span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <span>Reply rate:</span>
          <span><b>{point.y}%</b></span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <span>Total replies:</span>
          <span><b>{point.z}</b></span>
        </div>
        <a href="/email" style="color: #ffffff; text-decoration: none; font-weight: bold;">View details →</a>
      </div>
    `,
    useHTML: true,
  },

  legend: {
    enabled: false,
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 40,
      borderWidth: 0,
      pointPadding: 0.2,
    },
  },
});

export const EngagementRateOptions = (theme: string): any => ({
  chart: {
    type: 'areaspline',
  },
  title: {
    text: null,
  },
  legend: {
    enabled: true,
  },
  yAxis: {
    title: {
      text: null,
    },
    align: 'right',
    tickInterval: 10,
    min: 0,
    max: 100,
    // tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80],
  },
  xAxis: {
    type: 'datetime',
    min: 0,
    tickInterval: 1,
    title: {
      text: null,
    },
  },

  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0,
      marker: {
        enabled: false,
      },
    },
  },
});

// interface TooltipFormatterContext {
//   points: Array<{
//     x: any;
//     series: {
//       name: string;
//       color: string;
//     };
//     y: number;
//   }>;
// }

export const LeadContactsOverTime = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'top',
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  yAxis: {
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  xAxis: {
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2,
      marker: {
        enabled: false,
      },
    },
  },
});

export const EngagementRateSecondChart = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  yAxis: {
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  xAxis: {
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0,
      marker: {
        enabled: false,
      },
    },
  },
});

export const HeatMapFun = (theme: string): any => ({
  chart: {
    type: 'heatmap',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  xAxis: {
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  yAxis: {
    categories: ['12 PM', '4 AM', '8 AM', '12 PM', '4 AM', '8 AM'],
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  colorAxis: {
    dataClasses: [
      { from: 0, to: 15, color: '#E4F0FF', name: '0-15%' },
      { from: 15, to: 30, color: '#CFE4FF', name: '15-30%' },
      { from: 30, to: 45, color: '#A5F3C1', name: '30-45%' },
      { from: 45, to: 60, color: '#FEE875', name: '45-60%' },
      { from: 60, to: 75, color: '#FDAE6E', name: '60-75%' },
      { from: 75, color: '#F37373', name: '75%+' },
    ],
  },
  credits: {
    enabled: false,
  },
});

const todayMidnight = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

export const MultiChannelSequenceFlowOptions = (theme: string): any => ({
  chart: {
    type: 'xrange',
    backgroundColor: theme === 'dark' ? '#2c3e50' : '#ffffff',
  },
  plotOptions: {
    xrange: {
      borderRadius: 0,
    },
  },
  subtitle: {
    text: null,
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  xAxis: {
    type: 'datetime',
    plotLines: [
      {
        color: '#667085',
        width: 1,
        value: todayMidnight,
        label: {
          text: '',
        },
        zIndex: 5,
      },
    ],

    plotBands: [
      {
        from: todayMidnight,
        to: Infinity,
        color: '#fffcfcb5',
        zIndex: 7,
      },
    ],
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis label color
      },
    },
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis title color
      },
    },
  },

  yAxis: {
    categories: ['Prototyping', 'Development', 'Testing'],
    reversed: true,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis title color
      },
    },
    labels: {
      enabled: false,
    },
  },

  tooltip: {
    backgroundColor: '#2B3674',
    borderRadius: 8,
    borderWidth: 0,
    style: {
      zIndex: 999,
      color: '#ffffff',
      padding: '10px',
    },
    headerFormat: '',
    pointFormat: `
      <div style="width: 160px; display: flex; flex-direction: column; gap: 8px;">
        <div style="display: flex; align-items: center; gap: 8px;">
          <span style="display: inline-block; width: 10px; height: 10px; background-color: {point.color}; border-radius: 50%;"></span>
          <span>{point.type}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Total Sent</span>
          <span class='font-medium'>{point.totat_sent}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Opened</span>
          <span class='font-medium'>{point.opened_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Replied</span>
          <span class='font-medium'>{point.replied_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Clicked</span>
          <span class='font-medium'>{point.clicked_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-md'>
          <span class='font-thin'>Bounced</span>
          <span class='font-medium'>{point.bounced_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Unsubscribed</span>
          <span class='font-medium'>{point.unsubscribed_count}</span>
        </div>
        <a href="/email" class=''>View details <span class='text-[0.8rem]'>→</span></a>
      </div>
    `,
    useHTML: true,
  },

  legend: {
    enabled: false,
  },
});
