/* eslint-disable @typescript-eslint/no-unused-vars */

import { _getQueryParams } from 'utility/utils';
import { IoIosArrowRoundBack, IoMdCloudDone, IoMdLock } from 'react-icons/io';
import Button from 'components/common/Button';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { ReportAccordationPopupStateIV } from 'dto/initial-value/views/health-check';
import { AccordionState } from 'dto/types/views/notification';
import React, { useState } from 'react';
import { BiChevronDown, BiShieldQuarter } from 'react-icons/bi';
import { PiGlobe } from 'react-icons/pi';
import { MdMailLock } from 'react-icons/md';
import { FaMedal } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import DomainDrawer from './Domain_BlackList_Drawer';
import { GetOneEDSingleValidateAPI } from 'shared/ed-ipdomain/api-hanlder';

const DomainMonitoring = () => {
  const [domainName, setDomainName] = React.useState<string>('');
  const [report, setReport] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [domain_healthscore, setDomainHealthScore] = React.useState<number>(0);

  const navigate = useNavigate();

  const [openAccordion, setOpenAccordion] = React.useState<AccordionState>(ReportAccordationPopupStateIV);

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState?.activeIndex === index ? null : index,
    }));
  };

  const iconMapping = {
    DMARC: PiGlobe,
    DKIM: MdMailLock,
    SPF: IoMdLock,
    MX: FaMedal,
    'MTA-STS': BiShieldQuarter,
  };

  const statusIconMapping = {
    pass: IoMdCloudDone,
    warning: RiErrorWarningFill,
    'no records': IoCloseCircle,
  };

  const statusColorMapping = {
    pass: { bg: 'bg-green-100', text: 'text-green-600', icon: '#16A34A' }, // Green
    warning: { bg: 'bg-yellow-100', text: 'text-yellow-600', icon: '#F59E0B' }, // Yellow
    'no records': { bg: 'bg-red-100', text: 'text-red-600', icon: '#DC2626' }, // Red
  };

  type Status = 'pass' | 'no records' | 'warning';

  const accordionData: { title: keyof typeof iconMapping; index: number; status: Status; current_policy: any }[] = [
    { title: 'DMARC', index: 0, status: 'pass', current_policy: report?.dns_records?.dmarc },
    {
      title: 'DKIM',
      index: 1,
      status: 'warning',
      current_policy: report?.dns_records?.dkim?.map((item: any) => item?.records) || [],
    },
    { title: 'SPF', index: 2, status: 'no records', current_policy: report?.dns_records?.spf },
    {
      title: 'MX',
      index: 3,
      status: 'warning',
      current_policy: report?.dns_records?.mx?.map((item: any) => item?.exchange) || [],
    },
    { title: 'MTA-STS', index: 4, status: 'pass', current_policy: report?.dns_records?.mta_sts?.error },
  ];

  const handleBlacklistDrawer = () => {
    setIsDrawerOpen(true);
  };

  React.useEffect(() => {
    const storedUuid = sessionStorage.getItem('healthCheckUUID');
    const fetchData = async () => {
      const response = await GetOneEDSingleValidateAPI(storedUuid);
      console.log(response?.data, '===============>domainMonitoring');
      console.log(response?.data?.dns_records);
      if (!response?.error) {
        setReport(response?.data);
        setDomainName(response?.data?.blacklist_name);
        // let sum = getMatchingValues(response?.data?.blacklist_result, domainData, response?.data?.rdns_record?.fwd_rev_dns);
        // sum = 100 - sum;
        // setIPHealthScore(sum);
      } else {
        setReport({});
      }
    };
    storedUuid && fetchData();
  }, []);

  // console.log(openAccordion, 'openAccordion');

  return (
    <div className="my-3 min-h-[88vh] w-full rounded-lg bg-white shadow-md">
      <div className="mb-4 flex items-center justify-between  px-5 pt-3">
        {/* Back Arrow and Title */}
        <div className="flex items-start space-x-3">
          <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-purple-100" style={{ color: '#5A33FF', backgroundColor: '#F6F2FF' }}>
            <IoIosArrowRoundBack
              size={24}
              onClick={() => {
                sessionStorage.setItem('healthCheck_type', 'domain');
                navigate('/ed-ipdomain-tracker');
              }}
            />
          </div>
          <div className="text-sm font-semibold" style={{ color: '#2B3674' }}>
            {domainName}
          </div>
        </div>

        {/* Button */}
        <div>
          <Button id="add_run_test" name="Manage Domain with Sparkle" color="purple-600" customClass="text-xs border border-purple-600 bg-purple-50 px-4 py-2 rounded-lg" />
        </div>
      </div>
      <hr />
      <div className="flex flex-row">
        <div className=" my-5 ml-4 mr-4 w-[25%]">
          <div className="h-[15.8rem] w-[19.5rem] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <h5 className="py-4 pl-5 font-medium text-[#101828] ">Domain health score</h5>
            <hr />
            <div className="flex h-[12rem] items-center justify-center">
              <CircularProgress value={76} size={'165px'} color="#F08C02" capIsRound>
                <CircularProgressLabel top={75}>
                  <span className="text-2xl font-medium">{76}/ 100</span>
                </CircularProgressLabel>
              </CircularProgress>
            </div>
          </div>
          <div className="mt-5 h-[9rem] w-[19.5rem] rounded-lg border shadow-sm shadow-[#eaecf0]">
            <div className="flex flex-row items-center justify-between">
              <h5 className="py-4 pl-5 font-medium text-[#101828]">Blacklist</h5> <IoIosArrowRoundForward size={42} color="#4F26FF" className="pr-[16px]" onClick={handleBlacklistDrawer} />
            </div>
            <hr />
            <div className="flex h-[85px] items-center pl-4" onClick={handleBlacklistDrawer}>
              <h5 className="text-center text-xl font-semibold">
                <span className="text-3xl">{report?.no_of_blacklist_count}</span> / {report?.total_count}
              </h5>
            </div>
          </div>
        </div>
        {/* <div className="my-5 mr-4 w-[75%] border border-pink-800 px-3 py-3 "> */}
        <div className="mt-5 flex h-[75vh] flex-col items-center gap-1 overflow-auto">
          {accordionData?.map((item, accordionIndex) => (
            <div key={accordionIndex} className="mb-4 w-[58.125rem] rounded-lg border border-[#E5E5E5] bg-white dark:border-darkBorder dark:bg-darkBg">
              {/* Accordion Header */}
              <div
                className="flex cursor-pointer items-center justify-between px-4 py-4"
                onClick={() => {
                  handleAccordionClick(item?.index);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex w-[95px] flex-row items-center">
                    {React.createElement(iconMapping[item.title], { color: '#667085' })}
                    <p className="ml-2 text-sm font-medium text-[#667085] dark:text-white"> {item.title}</p>
                  </div>
                  <div>
                    <div
                      className={`flex h-[20px] flex-row items-center rounded-full px-2 py-1 ${statusColorMapping[item.status]?.bg}`} // Dynamically set background color
                    >
                      {React.createElement(statusIconMapping[item.status], {
                        color: statusColorMapping[item.status]?.icon, // Dynamically set icon color
                      })}
                      <p
                        className={`ml-2 text-xs font-medium ${statusColorMapping[item.status]?.text}`} // Dynamically set text color
                      >
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                      </p>
                    </div>
                  </div>
                </div>

                <BiChevronDown color="#667085" className={`transition-transform ${openAccordion?.activeIndex === item?.index ? 'rotate-180' : ''}`} />
              </div>

              {/* Accordion Content */}
              {openAccordion?.activeIndex === item?.index && (
                <div className="bg-[#F4F7F9] px-3 pb-1 pt-4">
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Current Policy</p>
                    <p className="mt-2 flex h-fit w-full items-center truncate whitespace-normal rounded border border-b-graybg bg-[#F8F6FF] px-1 py-1 pl-2 text-sm font-medium text-[#2B3674]">
                      <span className="truncate">
                        <p className="whitespace-pre-line break-words py-1">{Array.isArray(item.current_policy) ? item.current_policy.join('\n')?.trim() : item.current_policy ?? 'No data'}</p>
                      </span>
                    </p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Recommended Policy</p>
                    <p className="mt-2 flex items-center break-words rounded border border-b-graybg bg-[#F8F6FF] px-1 py-1  pl-2 text-sm font-medium text-[#2B3674]">{'Recommended Policy'}</p>
                  </div>
                  <div className="mb-3 rounded-lg bg-white p-3">
                    <p className="text-sm font-semibold text-[#344054]">Tips</p>
                    <p className="mt-1 flex items-center text-sm font-medium text-[#2B3674]">{'Tips Policy'}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* </div> */}
      </div>
      {isDrawerOpen && <DomainDrawer data={report} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />}
    </div>
  );
};

export default DomainMonitoring;
