/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from 'api/http/axios-instance';
import { AxiosRequestConfig } from 'axios';

export class BaseApi {
  public static async get(url: string, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await axiosInstance.get(url, config);

      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public static async post(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await axiosInstance.post(url, params, config);
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public static async put(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await axiosInstance.put(url, params, config);
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public static async patch(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await axiosInstance.patch(url, params, config);
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public static async delete(url: string, config?: AxiosRequestConfig): Promise<any> {
    try {
      const result = await axiosInstance.delete(url, config);
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
