// import { createCreditFormDataDTO, createModalHandleStateDTO } from 'dto/types/views/admin/creditaddons';
import { IoMdArchive } from 'react-icons/io';
import { ReportAccordationPopupStateDTO } from 'dto/types/views/healthcheck';

export const healthchecktableRowActionItemsListIV = [
  // {
  //   id: 'health_check_edit_row',
  //   icon: <MdEdit className="text-brand-500 group-hover:text-white"/>,
  //   mapping_key: 'edit',
  //   name: 'Edit',
  //   functionMap: 'edithealthcheckDataAction',
  //   source: 'health_check',
  //   action: 'edit',
  // },
  // {
  //   id: 'health_check_view_row',
  //   icon: <IoEyeSharp className="text-brand-500 group-hover:text-white"/>,
  //   mapping_key: 'view',
  //   name: 'View',
  //   functionMap: 'viewhealthcheckDataAction',
  //   source: 'health_check',
  //   action: 'view',
  // },
  {
    id: 'health_check_archieve_row',
    icon: <IoMdArchive className="text-brand-500 group-hover:text-white"/>,
    name: 'Delete',
    mapping_key: 'archieve_or_delete',
    functionMap: 'deletehealthcheckDataAction',
    source: 'health_check',
    action: 'delete',
  },
]

export const healthcheckBottomActionBarListIV = [
  {
    id: 'health_check_multi_delete_bottom',
    icon: <IoMdArchive/>,
    name: 'Delete',
    mapping_key: '',
    functionMap: 'deleteMultiSelectAction',
    action: 'delete',
    source: 'health_check',
  },
];

export const ReportAccordationPopupStateIV: ReportAccordationPopupStateDTO = {
  activeIndex: null,
  switches: {},
};