export const CampaignAnalyticsEngagementVSWordCountChartOption = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: 'transparent',
    zooming: {
      mouseWheel: {
        type: 'x',
      },
    },
  },
  title: null,
  legend: {
    enabled: false,
  },
  xAxis: [
    {
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  yAxis: [
    {
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      title: {
        text: null,
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  // tooltip: {
  //   pointFormat: `{series.name} <b>{point.y:,.0f}%</b><br/> Words Count <b>{point.x}</b>`,
  // },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2,
      // marker: {
      //   enabled: false,
      // },
    },
  },
});

export const overviewFunnelChartOptions = (theme: string): Highcharts.Options => ({
  chart: {
    type: 'funnel',
    height: 400,
    backgroundColor: theme === 'dark' ? '#1D2023' : '#FFFFFF',
  },
  title: {
    text: null,
  },
  plotOptions: {
    funnel: {
      dataLabels: {
        enabled: false,
        format: '<b>{point.name}</b> ({point.y:,.0f})',
        softConnector: true,
      },
      // neckWidth: '5%',
      // neckHeight: '50%',
      // width: '26%',
      center: ['50%', '50%'],
      borderWidth: 0,
    },
  },
  tooltip: {
    useHTML: true,
    backgroundColor: '#2b3674',
    borderWidth: 0,
    borderRadius: 4,
    shadow: false,
    style: {
      color: '#fff',
      fontFamily: 'sans-serif',
    },
    headerFormat: '',
    pointFormat: `
    <div style="width: fit-content; display: flex; flex-direction: column; gap: 10px;">
       <div style="display: flex; justify-content: space-between; gap:10px;">
        <span>Stage Name:</span>
        <span><b>{point.name}</b></span>
      </div>
      <div style="display: flex; justify-content: space-between; gap:10px;">
        <span>Count:</span>
        <span><b>{point.y}</b></span>
      </div>
    </div>
  `,
  },
});

export const senderAccountBubbleChartOptions = (theme: string): Highcharts.Options => ({
  chart: {
    type: 'bubble',
    plotBorderWidth: 0,
    backgroundColor: theme === 'dark' ? '#1D2023' : '#FFFFFF',
    margin: [0, 0, 0, 0],
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    series: {
      zoneAxis: 'z',
      zones: [
        {
          value: 25,
          color: '#d8e6fd',
        },
        {
          value: 50,
          color: '#b1cdfb',
        },
        {
          color: '#5a6cff',
        },
      ],
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
    bubble: {
      minSize: 10,
      maxSize: '30%',
      zMin: 0,
      zMax: 100,
      marker: {
        lineWidth: 2,
        lineColor: '#3d83f6',
      },
    },
  },
  tooltip: {
    useHTML: true,
    backgroundColor: '#2b3674',
    borderWidth: 0,
    borderRadius: 4,
    shadow: false,
    headerFormat: '',
    style: {
      color: '#fff',
      fontFamily: 'sans-serif',
    },
    pointFormat: `
    <div style="width: fit-content; display: flex; flex-direction: column; gap: 10px;">
       <div style="display: flex; justify-content: center;">
        <span><b>{point.account}</b></span>
      </div>
      <div style="display: flex; justify-content: space-between; gap:10px;">
        <span>{point.title}:</span>
        <span><b>{point.z}%</b></span>
      </div>
    </div>
  `,
  },
});

export const crmStageProgressOptions = (theme: string): any => ({
  chart: {
    type: 'column',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
  },
  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    crosshair: true,
    lineWidth: 0,
    tickWidth: 0,
  },

  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },

  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'horizontal',
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 40,
      borderWidth: 0,
    },
  },
});

export const channelPerformanceOptions = (theme: string): any => ({
  chart: {
    type: 'column',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
  },

  subtitle: {
    text: null,
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  xAxis: {
    categories: ['Whatsapp', ' email', '  call', ' SMS', 'LinkedIn'],
    crosshair: true,
    accessibility: {
      description: 'Countries',
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis label color
      },
    },
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis title color
      },
    },
    lineWidth: 0,
    tickWidth: 0,
  },
  yAxis: {
    allowDecimals: true,
    min: 0,
    max: 100,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis title color
      },
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis label color
      },
    },
  },

  tooltip: {
    backgroundColor: '#2B3674',
    borderRadius: 8, // Rounded corners
    borderWidth: 0, // Remove border
    style: {
      color: '#ffffff',
      padding: '10px', // Add padding
    },
    headerFormat: '',
    pointFormat: `
      <div style="width: 260px; display: flex; flex-direction: column; gap: 8px;">
        <div style="display: flex; align-items: center; gap: 8px;">
          <span style="display: inline-block; width: 10px; height: 10px; background-color: {point.color}; border-radius: 50%;"></span>
          <span>{point.name}</span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <span>Reply rate:</span>
          <span><b>{point.y}%</b></span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <span>Total replies:</span>
          <span><b>{point.z}</b></span>
        </div>
        <a href="/email" style="color: #ffffff; text-decoration: none; font-weight: bold;">View details →</a>
      </div>
    `,
    useHTML: true,
  },

  legend: {
    enabled: false,
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 40,
      borderWidth: 0,
      pointPadding: 0.2,
    },
  },
});

export const EngagementRateOptions = (theme: string): any => ({
  chart: {
    type: 'areaspline',
  },
  title: {
    text: null,
  },
  legend: {
    enabled: true,
  },
  yAxis: {
    title: {
      text: null,
    },
    align: 'right',
    tickInterval: 10,
    min: 0,
    max: 100,
    // tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80],
  },
  xAxis: {
    type: 'datetime',
    min: 0,
    tickInterval: 1,
    title: {
      text: null,
    },
    lineWidth: 0,
    tickWidth: 0,
  },

  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0,
      marker: {
        enabled: false,
      },
    },
  },
});

// interface TooltipFormatterContext {
//   points: Array<{
//     x: any;
//     series: {
//       name: string;
//       color: string;
//     };
//     y: number;
//   }>;
// }

export const LeadContactsOverTime = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'top',
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  xAxis: [
    {
      type: 'datetime',
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  yAxis: [
    {
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      title: {
        text: null,
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2,
      // marker: {
      //   enabled: false,
      // },
    },
  },
});

export const EngagementRateSecondChart = (theme: string): any => ({
  chart: {
    type: 'areaspline',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
    height: 305,
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    margin: 2,
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  yAxis: [
    {
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      title: {
        text: null,
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  xAxis: [
    {
      type: 'datetime',
      labels: {
        style: {
          color: theme === 'dark' ? '#FFFFFF' : '#000000',
        },
      },
      gridLineColor: theme === 'dark' ? '#444444' : '#e6e6e6',
      lineWidth: 0,
    },
  ],
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0,
    },
  },
});

export const HeatMapFun = (theme: string): any => ({
  chart: {
    type: 'heatmap',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
    height: 295,
  },
  title: {
    text: null,
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
    margin: 2,
  },
  xAxis: {
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    opposite: true,
    lineWidth: 0,
    tickWidth: 0,
  },
  yAxis: {
    categories: ['8pm', '4pm', '12pm', '8am', '4am', '12am'],
    title: {
      text: null,
    },
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
  },
  tooltip: {
    backgroundColor: theme === 'dark' ? '#2B3674' : '#ffffff',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },
  colorAxis: {
    dataClasses: [
      { from: 0, to: 15, color: '#E4F0FF', name: '0-15%' },
      { from: 15, to: 30, color: '#CFE4FF', name: '15-30%' },
      { from: 30, to: 45, color: '#A5F3C1', name: '30-45%' },
      { from: 45, to: 60, color: '#FEE875', name: '45-60%' },
      { from: 60, to: 75, color: '#FDAE6E', name: '60-75%' },
      { from: 75, color: '#F37373', name: '75%+' },
    ],
  },
  credits: {
    enabled: false,
  },
});

const todayMidnight = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

export const MultiChannelSequenceFlowOptions = (theme: string): any => ({
  chart: {
    type: 'xrange',
    backgroundColor: theme === 'dark' ? '#1D2023' : '#ffffff',
  },
  plotOptions: {
    xrange: {
      borderRadius: 0,
    },
  },
  subtitle: {
    text: null,
  },

  title: {
    text: null,
    align: 'left',
    style: {
      color: theme === 'dark' ? '#ffffff' : '#000000',
    },
  },

  xAxis: {
    type: 'datetime',
    plotLines: [
      {
        color: '#667085',
        width: 1,
        value: todayMidnight,
        label: {
          text: '',
        },
        zIndex: 5,
      },
    ],

    plotBands: [
      {
        from: todayMidnight,
        to: Infinity,
        color: '#fffcfcb5',
        zIndex: 7,
      },
    ],
    labels: {
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis label color
      },
    },
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set xAxis title color
      },
    },
    lineWidth: 0,
    tickWidth: 0,
  },

  yAxis: {
    categories: ['Prototyping', 'Development', 'Testing'],
    reversed: true,
    title: {
      text: null,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000', // Set yAxis title color
      },
    },
    labels: {
      enabled: false,
    },
  },

  tooltip: {
    backgroundColor: '#2B3674',
    borderRadius: 8,
    borderWidth: 0,
    style: {
      zIndex: 999,
      color: '#ffffff',
      padding: '10px',
    },
    headerFormat: '',
    pointFormat: `
      <div style="width: 160px; display: flex; flex-direction: column; gap: 8px;">
        <div style="display: flex; align-items: center; gap: 8px;" class="justify-between">
          <div>
            <span style="display: inline-block; width: 10px; height: 10px; 
                         background-color: {point.color}; border-radius: 50%;">
            </span>
            <span class="capitalize">{point.type}</span>
          </div>
          <span class="text-xs">{point.x:%e %B %Y}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Total Sent</span>
          <span class='font-medium'>{point.totat_sent}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Opened</span>
          <span class='font-medium'>{point.opened_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Replied</span>
          <span class='font-medium'>{point.replied_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Clicked</span>
          <span class='font-medium'>{point.clicked_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-md'>
          <span class='font-thin text-[.6875rem]'>Bounced</span>
          <span class='font-medium'>{point.bounced_count}</span>
        </div>
        <div style="display: flex; justify-content: space-between;" class='text-xs'>
          <span class='font-thin text-[.6875rem]'>Unsubscribed</span>
          <span class='font-medium'>{point.unsubscribed_count}</span>
        </div>
        <a href="/email">View details <span class='text-[0.8rem]'>&rarr;</span></a>
      </div>
    `,
    useHTML: true,
  },

  legend: {
    enabled: false,
  },
});
