import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HeaderStepsData, CampaignSetupListData } from 'dto/initial-value/views/campaign';
import { CampaignStoreState } from 'dto/types/views/campaigns';
import { randomString } from 'utility/utils';

const initialState: CampaignStoreState = {
  campaignName: '',
  headerSteps: HeaderStepsData,
  currentHeaderStep: 1,
  campaignSetupList: CampaignSetupListData,
  campaignSetupBoxAddItem: {},
  campaignSetupBoxAddItemEvent: '',
  sideMenuToggle: false,
  sideDrawerMenuToggle: false,
  initialBtnToggle: true,
  versionAB: false,
  sideDelayDrawerMenuToggle: false,
  campaignProspectsAddManuallyEmailList: [],
  campaignProspectsDonotEmailList: [],
  campaignProspectsEmailListUploadCSVData: [],
  campaignProspectsEmailListUploadCSVFinalData: [],
  campaignProspectsEmailListUploadCSVNameAndCount: {},
  campaignProspectsEmailListUploadCSVEnrichmentData: {},
  campaignSettingSenderEmailList: [],
  campaignSettingSenderEmailGroupsList: [],
  CampaignScheduleAndTrackingData: {},
  CampaignSeriesChartData: [],
  scalerZoomInOut: 100, //100%,
  campaignScale: 70,
  versionCopyDrawer: false,
  campaignSelectedCSVData: [],
  subjectVariable: '',
  variableToggle: false,
  campaignOverallData: {},
  lastSelectedItem: '',
  customCampaignID: '',
};

const headerSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    // camapaign header
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setCurrentHeaderSteps: (state, action: PayloadAction<number>) => {
      state.currentHeaderStep = action.payload;
    },
    // scaler zoom in or out
    setScalerZoomInOut: (state, action: PayloadAction<string>) => {
      if (action.payload === 'zoomIn' && state.scalerZoomInOut < 100) {
        state.scalerZoomInOut += 1;
      } else if (action.payload === 'zoomOut' && state.scalerZoomInOut > 1) {
        state.scalerZoomInOut -= 1;
      }
    },

    // campaign diagram setup
    setSideMenuToggle: (state, action: PayloadAction<boolean>) => {
      state.sideMenuToggle = action.payload;
    },
    setDrawerSideMenuToggle: (state, action: PayloadAction<boolean>) => {
      state.sideDrawerMenuToggle = action.payload;
    },
    setCampaignSetupBoxAddItem: (state, action: PayloadAction<object>) => {
      state.campaignSetupBoxAddItem = { ...state.campaignSetupBoxAddItem, ...action.payload };
    },
    setCampaignSetupBoxAddItemEvent: (state, action: PayloadAction<string>) => {
      state.campaignSetupBoxAddItemEvent = action.payload;
    },
    updateCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    updateCampaignSetupList: (state, action) => {
      state.campaignSetupList = action.payload;
    },
    setCampaignSetupBoxVersionDelete: (state, action) => {
      const { event, campaignItem } = action.payload;
      state.campaignSetupList = state.campaignSetupList.map((item, index) => {
        if (item?.id === campaignItem?.id) {
          if (event === 'A') {
            return { ...item, version_a: { message: '', subject: '' } };
          } else {
            return { ...item, version_b: { message: '', subject: '' } };
          }
        } else {
          return item;
        }
      });
    },
    setCampaignSetupBoxUpdateIntoList: (state, action) => {
      const { key, value } = action.payload;
      state.campaignSetupList = state.campaignSetupList.map((item, index) => {
        if (item?.id === state.campaignSetupBoxAddItem?.id) {
          if (state.campaignSetupBoxAddItemEvent === 'A') {
            state.campaignSetupBoxAddItem = { ...item, version_a: { ...item.version_a, [key]: value } };
            return { ...item, version_a: { ...item.version_a, [key]: value } };
          } else {
            state.campaignSetupBoxAddItem = { ...item, version_b: { ...item.version_b, [key]: value } };
            return { ...item, version_b: { ...item.version_b, [key]: value } };
          }
        } else {
          return item;
        }
      });
    },
    setCampaignSetupList: (state) => {
      const campaignSetupItem: any = {
        id: randomString(),
        type: 'email',
        delay: { days: '', hours: '', minutes: '' },
        version_a: { subject: '', message: '', reply_type: 'new thread' },
        version_b: { subject: '', message: '', reply_type: 'new thread' },
        versionStatus: 0,
      };
      state?.campaignSetupList?.length === 0 && delete campaignSetupItem?.version_a?.reply_type;
      state?.campaignSetupList?.length === 0 && delete campaignSetupItem?.version_b?.reply_type;
      const appendIndex = state.campaignSetupList.findIndex((item) => item?.id === state?.campaignSetupBoxAddItem?.id);
      state.campaignSetupList.splice(appendIndex + 1, 0, campaignSetupItem);
    },
    setCampaignSetupBoxDelayItem: (state, action: PayloadAction<object>) => {
      state.campaignSetupList = state.campaignSetupList.map((item, index) => {
        if (item?.id === state.campaignSetupBoxAddItem?.id) {
          state.campaignSetupBoxAddItem = { ...item, delay: action.payload };
          return { ...item, delay: action.payload };
        } else {
          return item;
        }
      });
    },
    setCampaignDuplicateList: (state, action) => {
      const { id } = action.payload;
      const appendIndex = state.campaignSetupList.findIndex((item) => item?.id === id);
      const campaignSetupDuplicateItem: object = { ...action.payload, id: randomString() };
      state.campaignSetupList.splice(appendIndex + 1, 0, campaignSetupDuplicateItem);
    },
    setRemoveData: (state, action) => {
      const { id } = action.payload;
      const appendIndex = state.campaignSetupList.findIndex((item) => item?.id === id);
      state.campaignSetupList.splice(appendIndex, 1);
    },
    setVersionAB: (state, action) => {
      const { id } = action.payload;
      console.log(id)
      console.log(state.campaignSetupList)
      const appendIndex = state.campaignSetupList.findIndex((item) => item?.id === id);
      console.log(appendIndex);
      console.log(state.campaignSetupList[appendIndex]);
      state.campaignSetupList[appendIndex].versionStatus = state.campaignSetupList[appendIndex].versionStatus === 0 ? 1 : 0;
    },
    setDelayDrawerSideMenuToggle: (state, action: PayloadAction<boolean>) => {
      state.sideDelayDrawerMenuToggle = action.payload;
    },
    setCampaignScale: (state, action) => {
      switch (action.payload) {
        case 'Increase':
          if (state.campaignScale === 100) return;
          state.campaignScale += 10;
          break;
        case 'Decrease':
          if (state.campaignScale === 20) return;
          state.campaignScale -= 10;
      }
    },
    setVersionCopyDrawer: (state, action) => {
      state.versionCopyDrawer = action.payload;
    },
    setVersionCopy: (state) => {
      state.campaignSetupList = state.campaignSetupList.map((item) => {
        if (item?.id === state?.campaignSetupBoxAddItem?.id) {
          state.campaignSetupBoxAddItem.version_b = { message: item?.version_a?.message, subject: item?.version_a?.subject, reply_type: item?.version_a?.reply_type };
          return { ...item, version_b: { message: item?.version_a?.message, subject: item?.version_a?.subject, reply_type: item?.version_a?.reply_type } };
        } else {
          return item;
        }
      });
    },

    // campaign prospects
    setCampaignProspectsAddManuallyEmailList: (state, action) => {
      state.campaignProspectsAddManuallyEmailList = action.payload;
    },
    setCampaignProspectsDonotEmailList: (state, action) => {
      state.campaignProspectsDonotEmailList = action.payload;
    },
    setCampaignProspectsEmailListUploadCSVData: (state, action) => {
      state.campaignProspectsEmailListUploadCSVData = action.payload;
    },
    setCampaignProspectsEmailListUploadCSVFinalData: (state, action) => {
      state.campaignProspectsEmailListUploadCSVFinalData = action.payload;
    },
    setCampaignProspectsEmailListUploadCSVNameAndCount: (state, action) => {
      state.campaignProspectsEmailListUploadCSVNameAndCount = action.payload;
    },
    setCampaignProspectsEmailListUploadCSVEnrichmentData: (state, action) => {
      state.campaignProspectsEmailListUploadCSVEnrichmentData = action.payload;
    },
    // campaign setting
    setCampaignSettingSenderEmailList: (state, action) => {
      state.campaignSettingSenderEmailList = action.payload;
    },
    setCampaignSettingSenderEmailGroupsList: (state, action) => {
      state.campaignSettingSenderEmailGroupsList = action.payload;
    },
    clearOldCampaign: (state) => {
      state.campaignName = '';
      state.customCampaignID = '';
      state.campaignSetupList = [];
      state.campaignProspectsAddManuallyEmailList = [];
      state.campaignProspectsDonotEmailList = [];
      state.currentHeaderStep = 1;
      state.campaignProspectsEmailListUploadCSVData = [];
      state.CampaignScheduleAndTrackingData = {
        time_schedule_id: '',
        tracking_options: {},
        gap_between_email: {},
      };
      state.campaignSettingSenderEmailList = [];
      state.campaignSettingSenderEmailGroupsList = [];
    },
    setCampaignSeriesChartData: (state, action) => {
      state.CampaignSeriesChartData = action.payload;
    },
    // Campaign schedule and tracking
    setCampaignScheduleAndTrackingData: (state, action) => {
      state.CampaignScheduleAndTrackingData = { ...state.CampaignScheduleAndTrackingData, ...action.payload };
    },
    setEmailCampaignDraft: (state, action) => {
      const { campaign_name, prospects, campaign_setup, schedule_and_tracking, campaign_settings } = action.payload;
      state.campaignName = campaign_name;
      state.campaignProspectsAddManuallyEmailList = prospects?.add_manually_email_list;
      state.campaignSetupList = campaign_setup;
      state.campaignProspectsDonotEmailList = prospects?.donot_email_list;
      state.campaignProspectsEmailListUploadCSVFinalData = prospects?.total_email_list;
      state.CampaignScheduleAndTrackingData.gap_between_email = schedule_and_tracking?.gap_between_email;
      state.CampaignScheduleAndTrackingData.time_schedule_id = schedule_and_tracking?.time_schedule_id;
      state.CampaignScheduleAndTrackingData.tracking_options = schedule_and_tracking?.tracking_options;
      state.campaignSettingSenderEmailList = campaign_settings?.sender_emails?.email_list;
      state.campaignSettingSenderEmailGroupsList = campaign_settings?.sender_emails?.group_list;
    },

    setCampaignSelectedCSVData: (state, action) => {
      state.campaignSelectedCSVData = action.payload;
    },
    setSubjectVariable: (state, action) => {
      state.subjectVariable = action.payload;
    },
    setVariableToggle: (state, action) => {
      state.variableToggle = action.payload;
    },
    setCampaignOverallData: (state, action) => {
      state.campaignOverallData = action.payload;
    },
    setLastSelectedItem: (state, action) => {
      state.lastSelectedItem = action.payload;
    },
    setCustomCampaignID: (state, action) => {
      state.customCampaignID = action.payload;
    },
  },
});

export const {
  updateCampaignName,
  updateCampaignSetupList,
  setCampaignName,
  setCurrentHeaderSteps,
  setSideMenuToggle,
  setCampaignSetupList,
  setCampaignSetupBoxAddItem,
  setCampaignSetupBoxDelayItem,
  setCampaignSetupBoxAddItemEvent,
  setDrawerSideMenuToggle,
  setCampaignSetupBoxUpdateIntoList,
  setCampaignSetupBoxVersionDelete,
  setVersionAB,
  setDelayDrawerSideMenuToggle,
  setCampaignDuplicateList,
  setRemoveData,
  setScalerZoomInOut,
  setCampaignScale,
  setVersionCopyDrawer,
  setVersionCopy,

  // campaign prospects
  setCampaignProspectsAddManuallyEmailList,
  setCampaignProspectsDonotEmailList,
  setCampaignProspectsEmailListUploadCSVData,
  setCampaignProspectsEmailListUploadCSVFinalData,
  setCampaignProspectsEmailListUploadCSVNameAndCount,
  setCampaignProspectsEmailListUploadCSVEnrichmentData,

  // campaign setting
  setCampaignSettingSenderEmailList,
  setCampaignSettingSenderEmailGroupsList,

  // campaign schedule and tracking
  setCampaignScheduleAndTrackingData,

  // clearing campaign for new creation
  clearOldCampaign,

  // Campaign Chart Data
  setCampaignSeriesChartData,
  setEmailCampaignDraft,

  // Campaign List page

  setCampaignSelectedCSVData,

  setSubjectVariable,
  setVariableToggle,
  setCampaignOverallData,
  setLastSelectedItem,
  setCustomCampaignID,
} = headerSlice.actions;

export default headerSlice.reducer;
