import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux-store';
import { cryptoDecrypt } from 'utility/utils';

const useFetchSiteAccess = () => {
  const {
    userAppConfiguration: { app_live_feature },
  } = useAppSelector((state) => state);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchDecryptedFeature = async () => {
      const decryptedFeature = await cryptoDecrypt(app_live_feature, true, true);
      setData(decryptedFeature);
      return decryptedFeature;
    };
    app_live_feature && fetchDecryptedFeature()
  }, [app_live_feature]);

  return data;
};

export default useFetchSiteAccess;
