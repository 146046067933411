import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { HighChart } from 'components/common/Chart/HighChart';
import Table from 'components/common/table/Table';
import TableSearchFilter from 'components/common/table/TableSearchFilter';
import { CampaignAnalyticsEngagementVSWordCountChartOption } from 'dto/initial-value/views/campaign-analytics';
import React from 'react';
import { useAppSelector } from 'redux-store';
import { channelData } from './Overview';
import { FaCodeBranch } from 'react-icons/fa';

const Content = () => {
  const theme = useAppSelector((state) => state?.app?.theme);
  const ab_testing_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.ab_testing_data);
  const engagement_vs_word_count_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.engagement_vs_word_count_data);
  const subject_table_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data?.subject_table_data);

  const columnHelper = createColumnHelper<any>();
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });
  const [height, setHeight] = React.useState(window.innerHeight);
  const [abTestingTooltip, setAbTestingTooltip] = React.useState(false);
  const [state, setState] = React.useState<{ search: string; trigger_type_obj: { [key: string]: 'yes' | 'no' }; sequence_data: any; sequence_table_data: any[] }>({
    trigger_type_obj: {},
    sequence_data: {},
    sequence_table_data: [],
    search: '',
  });

  const { search, trigger_type_obj, sequence_data, sequence_table_data } = state;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('subjectVal', {
        header: 'Subject Line Performance',
        cell: (info) => info.getValue() || 'Subject Line',
        meta: { customHeaderClass: 'text-left pl-4 text-xs font-medium w-40p', customBodyClass: 'text-left pl-4 text-[13px] font-medium w-40p' },
      }),
      columnHelper.accessor('openedPct', {
        header: 'Open Rate',
        cell: (info) => {
          const value = info.getValue() || 0;
          const percentage = Number(value);
          return (
            <CircularProgress value={percentage} size={'40px'} color="#2E90FA">
              <CircularProgressLabel>{percentage}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-xs font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
      columnHelper.accessor('repliedPct', {
        header: 'Reply Rate',
        cell: (info) => {
          const value = info.getValue() || 0;
          const percentage = Number(value);
          return (
            <CircularProgress value={percentage} size={'40px'} color="#00D8B6">
              <CircularProgressLabel>{percentage}%</CircularProgressLabel>
            </CircularProgress>
          );
        },
        meta: { customHeaderClass: 'text-left text-xs font-medium', customBodyClass: 'text-left pl-4 text-[13px] font-medium' },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filteredData = React.useMemo(() => {
    return subject_table_data?.filter((item: any) => item?.group_key?.includes(search)) ?? [];
  }, [subject_table_data, search]);

  const table = useReactTable<any>({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getTableData = (sequences: any) => {
    const finalTableData: any[] = [];
    if (sequences?.length) {
      finalTableData?.push(sequences[0]);
      if (sequences?.length > 1) {
        const recursiveFnToGetOrderSequenceData = (next: string, yes_trigger: string, no_trigger: string, trigger_type: 'yes' | 'no') => {
          if (!next && !yes_trigger && !no_trigger) return;
          if (next) {
            const findSequence = sequences?.find((sequence: any) => sequence?.id === next);
            if (findSequence) {
              finalTableData?.push(findSequence);
              recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
            }
          } else if (trigger_type === 'yes') {
            if (yes_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === yes_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          } else if (trigger_type === 'no') {
            if (no_trigger) {
              const findSequence = sequences?.find((sequence: any) => sequence?.id === no_trigger);
              if (findSequence) {
                finalTableData?.push(findSequence);
                recursiveFnToGetOrderSequenceData(findSequence?.next, findSequence?.trigger?.yes, findSequence?.trigger?.no, state?.trigger_type_obj[findSequence?.id] || 'yes');
              }
            }
          }
        };
        recursiveFnToGetOrderSequenceData(sequences[0]?.next, sequences[0]?.trigger?.yes, sequences[0]?.trigger?.no, state?.trigger_type_obj[sequences[0]?.id] || 'yes');
      }
    }

    return finalTableData;
  };

  React.useEffect(() => {
    const sequence = getTableData(ab_testing_data);
    const sequence_trigger_type_obj = sequence_table_data?.reduce((acc, item) => {
      if (item?.trigger) acc[item?.id] = trigger_type_obj[item?.id] || 'yes';
      return acc;
    }, {});

    setState((prev) => {
      const prev_trigger_obj = JSON.stringify(prev?.trigger_type_obj);
      const new_trigger_obj = JSON.stringify(sequence_trigger_type_obj);
      const final_trigger_obj = prev_trigger_obj === new_trigger_obj ? prev?.trigger_type_obj : sequence_trigger_type_obj;

      return { ...prev, sequence_table_data: sequence, trigger_type_obj: final_trigger_obj };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger_type_obj, ab_testing_data]);
  console.log('sequence_table_data', sequence_table_data);

  const handleMouseMove = (e: any, sequence: any) => {
    setAbTestingTooltip(true);
    setCoords({ x: e.clientX, y: e.clientY });
    setState((prev) => ({ ...prev, sequence_data: sequence }));
  };
  const handleMouseLeave = () => {
    setAbTestingTooltip(false);
    setCoords({ x: 0, y: 0 });
    setState((prev) => ({ ...prev, sequence_data: {} }));
  };

  React.useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tooltipCondition = height < coords?.y + 140;

  const engagementVsWorcountSeries = [
    {
      name: 'Engagement',
      data: engagement_vs_word_count_data?.map((item: any) => [item?.wordCount || 0, item?.repliedPct || 0]).filter((item: any) => item[0]),
    },
  ];

  return (
    <div className={`grid grid-cols-2 gap-4 overflow-y-auto`}>
      <div className="col-span-1">
        <h4 className="px-1 text-[13px] font-bold dark:text-white">A/B Testing</h4>
      </div>
      <div className="col-span-1">
        <h4 className="px-1 text-[13px] font-bold dark:text-white">Engagement VS Word Count</h4>
      </div>
      <div className="col-span-1 flex h-[21.875rem] flex-col overflow-y-auto rounded-md border-2 border-graybg dark:border-white/10">
        <table className="w-full rounded-md">
          <thead className="border-b text-[11px] text-column dark:border-white/10 dark:bg-gray-750">
            <th className="w-52 bg-grayBorder p-3 dark:bg-gray-750 ">Channel</th>
            <th className="w-20 bg-grayBorder p-3 dark:bg-gray-750 ">Step no</th>
            <th className="bg-grayBorder p-3 dark:bg-gray-750 ">Replied Rate</th>
          </thead>
          <tbody>
            {sequence_table_data?.map((ele: any, index: number) => {
              const channel = channelData?.find((item) => item.id === ele?.sequenceType);
              return (
                <>
                  <tr key={index} className="h-12 border-b dark:border-white/10">
                    <td className="p-4 text-xs">
                      <div className="flex w-full gap-1">
                        <div className={`${channel?.color} text-[14px]`}>{channel?.icon}</div>
                        <div className="text-[12px] font-medium text-[#101828] dark:text-white">{channel?.name}</div>
                      </div>
                    </td>
                    <td className="p-4 text-[12px] font-medium text-[#101828] dark:text-white">Step {index + 1}</td>
                    <td className="py-2 text-xs">
                      {ele?.versionStats?.map((sequence: any, index: number) => {
                        return (
                          <div key={index} className="mx-2" style={{ width: `${(sequence?.repliedPct < 1 ? 1 : sequence?.repliedPct) || 0}%` }} onMouseMove={(e) => handleMouseMove(e, { ...sequence, step: index + 1, channel: ele?.sequenceType })} onMouseLeave={handleMouseLeave}>
                            <div className="mt-1.5 h-4 w-full bg-skyBlue">{ele?.versionStats?.length > 0 && <span className="pl-3 font-medium capitalize">{sequence?.messageVersion?.split('_')[1]}</span>}</div>
                          </div>
                        );
                      })}
                      <div className="mt-1.5 border dark:border-white/10"></div>
                      {/* <div className="px-4" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                        <div className="mt-1.5 h-4 w-full bg-darkSkyBlue">
                          <span className="pl-3 font-medium">B</span>
                        </div>
                      </div> */}
                    </td>
                  </tr>
                  {ele?.trigger && (
                    <tr className="h-12 border-b dark:border-white/10">
                      <td className="bg-gray-750 p-4">
                        <div className="flex w-full items-center justify-start gap-3">
                          <div className={`flex justify-center text-xs`}>
                            <FaCodeBranch className={`${theme === 'dark' ? 'text-white' : 'text-black'}`} />
                          </div>
                          <div className="text-center text-[12px] font-medium text-[#101828] dark:text-white">Cond.</div>
                          <div className="w-full">
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'yes' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-white text-column' : 'bg-blueSecondary text-white'}`}>
                              Yes
                            </button>
                            <button onClick={() => setState((prev) => ({ ...prev, trigger_type_obj: { ...trigger_type_obj, [ele?.id]: 'no' } }))} className={`h-6 w-9 rounded-md text-[10px] ${trigger_type_obj[ele?.id] === 'no' ? 'bg-blueSecondary text-white' : 'bg-white text-column'}`}>
                              No
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="bg-gray-750"></td>
                      <td className="bg-gray-750"></td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-span-1 flex h-[21.875rem] flex-col rounded-md border-2 border-graybg p-3 dark:border-white/10">
        <HighChart chartOptions={CampaignAnalyticsEngagementVSWordCountChartOption(theme)} isDataNotExist={false} series={engagementVsWorcountSeries} />
      </div>
      <div className="col-span-2 h-[26.875rem] overflow-y-auto rounded-md border-2 border-graybg dark:border-white/10">
        <div className="mb-4 ml-4 mr-0 mt-4">
          <TableSearchFilter setState={setState} />
        </div>
        <Table customClass="h-[22.5rem]" table={table} data={filteredData} tableDataListStatus={true} />
      </div>
      {abTestingTooltip && (
        <div className="absolute z-50 h-[115px] w-[7.5rem] rounded-md bg-heading p-2 text-[10px] font-normal text-white" style={{ left: coords?.x - 60, top: tooltipCondition ? coords?.y - 125 : coords?.y + 15 }}>
          <div className="mt-2 flex justify-between">
            <span>Channel</span>
            <span className="capitalize">{sequence_data?.channel || 'email'}</span>
          </div>
          <div className="mt-2 flex justify-between">
            <span>Step</span>
            <span>{sequence_data?.step || 0}</span>
          </div>
          <div className="mt-2 flex justify-between border-b border-[#FFFFFF29] dark:border-white/10"></div>
          <div className="mt-2 flex justify-between">
            <span>Variation</span>
            <span className="capitalize">{sequence_data?.messageVersion?.split('_')[1]}</span>
          </div>
          <div className="mt-2 flex justify-between">
            <span>Reply Rate</span>
            <span>{sequence_data?.repliedPct || 0}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
