import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  source: '',
  flag_status: false,
  loading: false,
  infinite_scroll_loading: false,
  search: '',
  filter: '',
  limit: 25,
  offset: 1,
  sorting: [],
  column_visibility: [],
  table_row_action_items_list: [],
};
const commonTableListSlice = createSlice({
  name: 'commontable',
  initialState,
  reducers: {
    updateTableQueryState: (state, action) => {
      Object.assign(state, action.payload);
    },
    setDynamicTableListData(state, action: PayloadAction<{ key: string; value: any }>) {
      const { key, value } = action.payload;
      let storeResponse: any = value;
      if (state.hasOwnProperty(key) && state?.offset > 1) {
        const storeData = state?.[key];
        const updateStoreData = state?.infinite_scroll_loading ? [...storeData?.data, ...value?.data] : [...storeData?.data];
        storeResponse = { ...value, data: updateStoreData, total_records: value?.total_records, };
      }
      return { ...state, [key]: storeResponse };
    },
    removeKeyFromState(state: any, action: PayloadAction<string | string[]>): any {
      const keysToRemove = Array.isArray(action.payload) ? action.payload : [action.payload];
      return keysToRemove.reduce((newState, key) => {
        if (key.includes('.')) {
          const [parentKey, childKey] = key.split('.');
          if (newState[parentKey]) {
            newState[parentKey] = { ...newState[parentKey] };
            delete newState[parentKey][childKey]; 
            if (Object.keys(newState[parentKey]).length === 0) delete newState[parentKey]; 
          }
        } else {
          delete newState[key];
        }
        return newState;
      }, { ...state }); 
    },
  },
});

export const { updateTableQueryState, setDynamicTableListData, removeKeyFromState } = commonTableListSlice.actions;
export default commonTableListSlice.reducer;
