import React from 'react';
import CustomDatePicker from 'components/common/customDatePicker';
import moment from 'moment';
import { CustomMenu } from 'components/common/customMenu';
import { ImLinkedin } from 'react-icons/im';
import { BiChevronDown, BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { RiWhatsappFill } from 'react-icons/ri';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { BaseApi } from 'api/services/base-api';
import { setCommonDetailsInAnalytics, setGraphDataInAnalytics } from 'redux-store/campaign-analytics';
import { Spinner } from '@chakra-ui/react';

const tabsData = [
  { id: 'overview', name: 'Overview', path: 'overview' },
  { id: 'engagement', name: 'Engagement', path: 'engagement' },
  { id: 'senderaccount', name: 'Sender Account', path: 'sender-account' },
  { id: 'content', name: 'Content', path: 'content' },
];
const dropDownData = [
  {
    name: 'Emails',
    icon: <MdEmail />,
    className: 'text-[#015AFF] bg-[#015AFF0F]',
  },
  {
    name: 'Linkedin',
    icon: <ImLinkedin />,
    className: 'text-[#0A66C2] bg-[#015AFF0F]',
  },
  {
    name: 'Whatsapp',
    icon: <RiWhatsappFill />,
    className: 'text-[#12B76A] bg-[#12B76A0F]',
  },
  {
    name: 'Call',
    icon: <BiSolidPhoneCall />,
    className: 'text-[#12B76A] bg-[#12B76A0F]',
  },
  {
    name: 'SMS',
    icon: <MdEmail />,
    className: 'text-[#015AFF] bg-[#015AFF0F]',
  },
];

const AnalyticsTab = () => {
  const campaign_details = useAppSelector((state) => state?.campaignAnalytics?.campaign_details);
  const original_graph_data = useAppSelector((state) => state?.campaignAnalytics?.graph_data);
  const rout_url = useAppSelector((state) => state?.campaignAnalytics?.common?.children_path);
  const isInitiallyFetching = useAppSelector((state) => state?.campaignAnalytics?.common?.loading);
  const selectedDate = React.useMemo(() => [moment(campaign_details?.campaign_start_date).toDate(), moment().endOf('day').toDate()], [campaign_details?.campaign_start_date]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [socialState, setSocialState] = React.useState({
    selectedSocialIndex: 0,
    open: false,
  });
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (index: number, path: string) => {
    setActiveTab(index);
    navigate(path);
  };

  const fetchDate = async (date: any[], setShowCalendar: any) => {
    // setSelectedDate(date)
    dispatch(setCommonDetailsInAnalytics({ start_date: date[0], end_date: date[1] }));
    if (Object?.values(original_graph_data)?.filter(Boolean)?.length === 0) return;
    const start_date = moment(date[0]).format();
    const end_date = moment(date[1]).format();
    const initially_loaded_data = [];
    const graph_data: any = {};
    try {
      setLoading(true);
      // 1) Funnel Api && CRM Stage Progression
      if (rout_url === 'overview') {
        const params = {
          campaign_id: campaign_details?.uuid,
          from_date: start_date,
          to_date: end_date,
        };
        const response = await Promise.all([BaseApi.post('campaign/analytics/crm-count', params), BaseApi.post('campaign/analytics/sequence-data', params)]);
        initially_loaded_data?.push('overview');
        if (response?.length) {
          graph_data['funnel'] = response[0]?.data?.total || null;
          graph_data['crm_stage_progression'] = response[0]?.data?.date_wise || null;
          graph_data['multisequence_flow_data'] = response[1]?.data || null;
        }
      }
      // 1) Sender Account
      if (rout_url === 'sender-account') {
        const params = {
          campaign_id: campaign_details?.uuid,
          from_date: start_date,
          to_date: end_date,
          variant_type: 'email',
          list_type: 'domain',
        };
        const response = await BaseApi.post('campaign/analytics/domain-sender-data', params);
        initially_loaded_data?.push('sender-account');
        if (response?.data) {
          graph_data['sender_account_graph_data'] = response?.data || null;
        }
      }

      // 1) Engagement Rate Api
      if (rout_url === 'engagement') {
        const params = {
          campaign_id: campaign_details?.uuid,
          from_date: start_date,
          to_date: end_date,
          crm_chart: 'true',
        };
        const params2 = {
          campaign_id: campaign_details?.uuid,
          from_date: start_date,
          to_date: end_date,
        };

        const response = await Promise.all([BaseApi.post('campaign/analytics/lead-contact', params), BaseApi.post('campaign/analytics/heat-map', params2)]);
        initially_loaded_data?.push('engagement');
        if (response?.length) {
          graph_data['engagement_total_data'] = response[0]?.data?.leads?.total || null;
          graph_data['engagement_rate_data'] = response[0]?.data?.leads?.date_wise || null;
          graph_data['engagement_crm_data'] = response[0]?.data?.crm?.date_wise || null;
          graph_data['engagement_crm_total_data'] = response[0]?.data?.crm?.total || null;
          graph_data['heat_map_data'] = response[1]?.data || null;
        }
      }

      // // 1) Content Api
      // if (rout_url === 'content') {
      //   const params = {
      //     campaign_id: campaign_details?.uuid,
      //     from_date: start_date,
      //     to_date: end_date,
      //     variant_type: 'email',
      //   };

      //   const response = await Promise.all([BaseApi.post('campaign/analytics/sequence-data-version', params), BaseApi.post('campaign/analytics/word-count', params), BaseApi.post('campaign/analytics/subject-count', params)]);
      //   initially_loaded_data?.push('content');
      //   if (response?.length) {
      //     graph_data['ab_testing_data'] = response[0]?.data || null;
      //     graph_data['engagement_vs_word_count_data'] = response[1]?.data || null;
      //     graph_data['subject_table_data'] = response[2]?.data || null;
      //   }
      // }
    } catch (error) {
      console.log('CampaignAnalyticsHomePage campaign details fetching error: ', error);
    } finally {
      dispatch(setGraphDataInAnalytics(graph_data));
      setLoading(false);
    }
  };
  return (
    <div className={`relative mt-2 rounded-md bg-white p-3 dark:bg-darkTwo`}>
      <div className="mb-3 flex items-center gap-5">
        <div className="whitespace-pre text-[1.01rem] font-bold text-heading dark:text-white">Campaign Progress</div>
        <div className="mt-0.5 h-[.4rem] w-full rounded-full bg-gray-200 dark:bg-gray-700">
          <div className={`h-[0.4rem] rounded-full bg-textGreen`} style={{ width: `${(Number(campaign_details?.sent_count || 0) / Number(campaign_details?.overall_campaign_prospects_count || 0)) * 100}%` }}></div>
        </div>
        <div className="whitespace-pre text-[1.01rem] font-medium text-paused-color dark:text-darkPaused-color">{`${campaign_details?.sent_count || 0}/${campaign_details?.overall_campaign_prospects_count || 0} (${Math.floor(
          (Number(campaign_details?.sent_count || 0) / Number(campaign_details?.overall_campaign_prospects_count) || 0) * 100
        )}%)`}</div>
      </div>
      <div className="flex justify-between border-b-2 dark:border-darkBorder">
        <div className={`flex w-full gap-4`}>
          {tabsData?.map((item, index) => {
            return (
              <button
                key={item?.id}
                onClick={() => handleClick(index, item?.path)}
                className={`group relative p-2 text-[13px] font-medium ${rout_url === item?.path ? 'bg-buttonBackground text-blueSecondary dark:bg-[#1F1F2E] dark:text-blue-400' : 'text-column hover:bg-buttonBackground hover:text-blueSecondary dark:text-[#D0D5DD] dark:hover:bg-[#1F1F2E] dark:hover:text-blue-400'}`}
              >
                <span className="px-1">{item?.name}</span>
                <div className={`absolute -bottom-[.105rem] -left-0 h-[.0925rem] w-full rounded-md bg-blueSecondary dark:hover:text-blue-400 ${rout_url === item?.path ? '' : 'hidden group-hover:block'}`}></div>
              </button>
            );
          })}
        </div>
        <div className="flex gap-2">
          {activeTab !== 3 && <CustomDatePicker loading={loading} initial_date={selectedDate} onChange={async (date: any, setShowCalendar: any) => await fetchDate(date, setShowCalendar)} customSelectedDateClass={'text-xs text-paused-color dark:text-white w-fit whitespace-pre'} />}
          {activeTab !== 0 && (
            <CustomMenu
              open={socialState?.open}
              onOpen={() => setSocialState((prev) => ({ ...prev, open: true }))}
              onClose={() => setSocialState((prev) => ({ ...prev, open: false }))}
              btnContent={
                <div className="flex select-none items-center gap-2">
                  <div className={`flex items-center justify-center rounded-full ${dropDownData[socialState?.selectedSocialIndex]?.className} p-1.5`}>{dropDownData[socialState?.selectedSocialIndex]?.icon}</div>
                  <div className="text-xs font-medium text-column ">{dropDownData[socialState?.selectedSocialIndex]?.name}</div>
                </div>
              }
              btnClassName="text-nowrap !h-8"
              menuListClassName="!p-0.5 !h-36 overflow-y-auto"
              rightIcon={<BiChevronDown size={18} />}
            >
              {dropDownData?.map((ele, index) => (
                <div className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() => setSocialState((prev) => ({ ...prev, open: false, selectedSocialIndex: index }))}>
                  <div className={`flex items-center justify-center rounded-full ${ele?.className} p-1.5`}>{ele?.icon}</div>
                  <div className="text-sm font-medium text-column ">{ele?.name}</div>
                </div>
              ))}
            </CustomMenu>
          )}
        </div>
      </div>
      <div className={`h-[28.75rem] overflow-y-auto py-4 pl-4 pr-1 ${isInitiallyFetching ? 'pointer-events-none blur-sm' : ''}`}>
        {/* {activeTab === 0 && <Overview />}
        {activeTab === 1 && (
          <Engagement
            option={{}} // Provide valid chart options here
            title="Overview Chart"
            series={[]}
          />
        )}
        {activeTab === 2 && <SenderAccount />}
        {activeTab === 3 && <Content />} */}
        <Outlet />
      </div>
      {isInitiallyFetching ? (
        <div className="absolute left-1/2 top-1/2">
          <Spinner size={'lg'} textColor={'GrayText'} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnalyticsTab;
