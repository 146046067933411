import { flexRender } from '@tanstack/react-table';
const TableBody = ({ data, table, handleClickAction }: any) => {
    return (
        <tbody className="divide-y-[1px] dark:divide-darkBorder ">
            {data?.length > 0 ? (
                table.getRowModel().rows.map((row: any) => {
                    return (
                        <tr key={row.id} className={`hover:bg-hover dark:hover:bg-darkOne ${handleClickAction ? "cursor-pointer" : ''}`} onClick={(e) => handleClickAction?.(e,row)}>
                            {row.getVisibleCells().map((cell: any, i: number) => {
                                return (
                                    <td key={cell.id} className={`${cell?.column?.columnDef?.meta?.customClass || ''} ${cell?.column?.columnDef?.meta?.customBodyClass || ''} border-white/0 py-3 text-heading dark:text-white`}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                        <h6>No Data Found</h6>
                    </td>
                </tr>
            )}
        </tbody>
    )
}

export default TableBody
