import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import HighchartsXrange from 'highcharts/modules/xrange';

HighchartsXrange(Highcharts);

export const HighChart = ({ chartOptions, series, constructorType, title, isDataNotExist, extraContent, customClass }: { chartOptions?: any; series?: any; constructorType?: string; title?: string; isDataNotExist?: boolean; extraContent?: any; customClass?: string }) => {
  const option: any = {
    ...chartOptions,
    series,
    credits: { enabled: false },
  };
  return (
    <>
      {title && <h4 className="mt-2 mb-1 text-[13px] font-medium">{title}</h4>}
      {!isDataNotExist ? (
        <HighchartsReact highcharts={Highcharts} options={option} constructorType={constructorType || 'chart'} />
      ) : (
        // <HighChart chartOptions={option} series={series} constructorType={constructorType || 'chart'} />
        <div className="mt-2 flex h-full w-full items-center justify-center rounded-md border bg-bgNotification p-2 dark:border-darkThree dark:bg-darkOne dark:text-white">
          <div>
            <h4 className="text-center font-bold">Chart Not Available</h4>
            {extraContent}
          </div>
        </div>
      )}
    </>
  );
};
