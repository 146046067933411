import { ED_IPDOMAIN_VALIDATE, GET_ONE_ED_IPDOMAIN } from 'api/endpoints/edIpDomainEndpoints';
import { BaseApi } from 'api/services/base-api';
import { AxiosResponse } from 'axios';

export const EDSingleValidateAPI = async (data: any) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(ED_IPDOMAIN_VALIDATE, data);
    return response;
  } catch (error) {
    console.error('ED ipdomain singlevalidation error occured ', error);
    throw error;
  }
};

export const GetOneEDSingleValidateAPI = async (data: string) => {
     try {
        const getUrl = GET_ONE_ED_IPDOMAIN(data);
        const response = await BaseApi.get(getUrl);
        return response;
      } catch (error) {
        console.error('EdSingkeIpDomainListGetOneApi Error:', error);
        throw error;
      }
  };
