import React from 'react';
import Content from './Content';
import SenderAccount from './SenderAccount';
import Engagement from './Engagement';
import Overview from './Overview';
import CustomDatePicker from 'components/common/customDatePicker';
import moment from 'moment';
import { CustomMenu } from 'components/common/customMenu';
import { ImLinkedin } from 'react-icons/im';
import { BiChevronDown, BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { RiWhatsappFill } from 'react-icons/ri';

const AnalyticsTab = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [socialState, setSocialState] = React.useState({
    selectedSocialIndex: 0,
    open: false,
  });

  const tabsData = [
    { id: 'overview', name: 'Overview' },
    { id: 'engagement', name: 'Engagement' },
    { id: 'senderaccount', name: 'Sender Account' },
    { id: 'content', name: 'Content' },
  ];
  const dropDownData = [
    {
      name: 'Emails',
      icon: <MdEmail />,
      className: 'text-[#015AFF] bg-[#015AFF0F]',
    },
    {
      name: 'Linkedin',
      icon: <ImLinkedin />,
      className: 'text-[#0A66C2] bg-[#015AFF0F]',
    },
    {
      name: 'Whatsapp',
      icon: <RiWhatsappFill />,
      className: 'text-[#12B76A] bg-[#12B76A0F]',
    },
    {
      name: 'Call',
      icon: <BiSolidPhoneCall />,
      className: 'text-[#12B76A] bg-[#12B76A0F]',
    },
    {
      name: 'SMS',
      icon: <MdEmail />,
      className: 'text-[#015AFF] bg-[#015AFF0F]',
    },
  ];

  const handleClick = (index: number) => {
    setActiveTab(index);
  };
  const campaign_details = JSON.parse(sessionStorage?.getItem('campaign_details'));
  React.useEffect(() => {
    return () => {
      sessionStorage?.removeItem('campaign_details');
    };
  }, []);

  return (
    <div className="mt-2 rounded-md bg-white p-3">
      {/* Campaign Progress */}
      <div className="mb-3 flex items-center gap-5">
        <div className="whitespace-pre text-[1.01rem] font-bold text-heading">Campaign Progress</div>
        <div className="mt-0.5 h-[.4rem] w-full rounded-full bg-gray-200 dark:bg-gray-700">
          <div
            className={`h-[0.4rem] rounded-full bg-textGreen`}
            style={{ width: `${campaign_details?.campaign_statistics?.total_prospects_count ? (Number(campaign_details?.campaign_statistics?.sent_count) / Number(campaign_details?.campaign_statistics?.total_prospects_count)) * 100 : campaign_details?.campaign_statistics?.sent_count}%` }}
          ></div>
          {/* <div className={`h-[4px] rounded-full bg-green-600`}></div> */}
        </div>
        <div className="whitespace-pre text-[1.01rem] font-medium text-paused-color">{`${campaign_details?.campaign_statistics?.sent_count}/${campaign_details?.campaign_statistics?.total_prospects_count} (${
          (Number(campaign_details?.campaign_statistics?.sent_count) / Number(campaign_details?.campaign_statistics?.total_prospects_count) || 0) * 100
        }%)`}</div>
      </div>
      {/* Tabs Header */}
      <div className="flex justify-between border-b-2">
        <div className={`flex w-full gap-4`}>
          {tabsData?.map((item, index) => {
            return (
              <button key={item?.id} onClick={() => handleClick(index)} className={`group relative p-2 text-[13px] font-medium ${activeTab === index ? 'bg-buttonBackground text-blueSecondary' : 'text-column hover:bg-buttonBackground hover:text-blueSecondary'}`}>
                <span className="px-1">{item?.name}</span>
                <div className={`absolute -bottom-[.105rem] -left-0 h-[.0925rem] w-full rounded-md bg-blueSecondary ${activeTab === index ? '' : 'hidden group-hover:block'}`}></div>
              </button>
            );
          })}
        </div>
        <div className="flex gap-2">
          <CustomDatePicker initial_date={[moment().subtract(30, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]} onChange={async (date: any, setShowCalendar: any) => {}} customSelectedDateClass={'text-xs text-paused-color dark:text-white w-fit whitespace-pre'} loading={false} />
          <CustomMenu
            open={socialState?.open}
            onOpen={() => setSocialState((prev) => ({ ...prev, open: true }))}
            onClose={() => setSocialState((prev) => ({ ...prev, open: false }))}
            btnContent={
              <div className="flex select-none items-center gap-2">
                <div className={`flex items-center justify-center rounded-full ${dropDownData[socialState?.selectedSocialIndex]?.className} p-1.5`}>{dropDownData[socialState?.selectedSocialIndex]?.icon}</div>
                <div className="text-xs font-medium text-column ">{dropDownData[socialState?.selectedSocialIndex]?.name}</div>
              </div>
            }
            btnClassName="text-nowrap !h-8"
            menuListClassName="!p-0.5 !h-36 overflow-y-auto"
            rightIcon={<BiChevronDown size={18} />}
          >
            {dropDownData?.map((ele, index) => (
              <div className="flex cursor-pointer select-none items-center gap-2 p-1 hover:bg-buttonBackground" onClick={() => setSocialState((prev) => ({ ...prev, open: false, selectedSocialIndex: index }))}>
                <div className={`flex items-center justify-center rounded-full ${ele?.className} p-1.5`}>{ele?.icon}</div>
                <div className="text-sm font-medium text-column ">{ele?.name}</div>
              </div>
            ))}
          </CustomMenu>
        </div>
      </div>

      {/* Tabs */}
      <div className="h-[28.75rem] overflow-y-auto py-4 pl-4 pr-1">
        {activeTab === 0 && <Overview />}
        {activeTab === 1 && <Engagement  option={{}} // Provide valid chart options here
            title="Overview Chart"
            series={[]} />}
        {activeTab === 2 && <SenderAccount />}
        {activeTab === 3 && <Content />}
      </div>
    </div>
  );
};

export default AnalyticsTab;
