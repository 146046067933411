import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  campaign_details: {},
  common: {
    initially_loaded: [],
    loading:false,
    path: null,
    children_path: null,
    start_date: null,
    end_date: null,
  },
  graph_data: {
    funnel: null,
    crm_stage_progression: null,
    channel_performance: null,
    multisequence_flow_data: null,
    sender_account_graph_data: null,
    sender_or_domain_list: null,
    engagement_rate_data: null,
    engagement_total_data: null,
    engagement_crm_data: null,
    engagement_crm_total_data: null,
    heat_map_data: null,
    ab_testing_data: null,
    engagement_vs_word_count_data: null,
    subject_table_data: null,
  },
};

const campaignAnalyticsSlice = createSlice({
  name: 'campaig_analytics',
  initialState,
  reducers: {
    setCampaignDetilsInANalytics: (state, action) => {
      state.campaign_details = { ...state?.campaign_details, ...action?.payload };
    },
    setCommonDetailsInAnalytics: (state, action) => {
      state.common = { ...state?.common, ...action?.payload };
    },
    setInitiallyLoadedComponentDataInAnalytics: (state, action) => {
      state.common.initially_loaded = [...state?.common?.initially_loaded, ...action?.payload];
    },
    setGraphDataInAnalytics: (state, action) => {
      state.graph_data = { ...state?.graph_data, ...action?.payload };
    },
    clearAllData: (state) => {
      state.campaign_details = {};
      state.common = {
        initially_loaded: [],
        path: null,
        children_path: null,
        start_date: null,
        end_date: null,
      };
      state.graph_data = {
        funnel: null,
        crm_stage_progression: null,
        channel_performance: null,
        multisequence_flow_data: null,
        sender_account_graph_data: null,
        sender_or_domain_list: null,
        engagement_rate_data: null,
        heat_map_data: null,
        engagement_total_data: null,
        engagement_crm_data: null,
        engagement_crm_total_data: null,
        ab_testing_data: null,
        engagement_vs_word_count_data: null,
        subject_table_data: null,
      };
    },
  },
});

export const { setCampaignDetilsInANalytics, setCommonDetailsInAnalytics, setInitiallyLoadedComponentDataInAnalytics, setGraphDataInAnalytics, clearAllData } = campaignAnalyticsSlice?.actions;
export default campaignAnalyticsSlice?.reducer;
