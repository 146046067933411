export const ENV_NODE_ENV = process.env.NODE_ENV || 'local';
export const ENV_PORT = process.env.PORT || 5000

export const ENV_API_BASEURL = process.env.REACT_APP_API_URL || `http ://localhost:5000/api/v1`;
// export const ENV_API_BASEURL = 'https://sparkle-dev.azurewebsites.net/api/v1';

export const ENV_JWT_SECRET = process.env.REACT_APP_JWT_SECRET;
export const ENV_CRYPTO_SECRET = process.env.REACT_APP_CRYPTO_SECRET;

export const ENV_OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

export const ENV_PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY; // browser notification secret key

export const ENV_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const ENV_INTERCOM_USER_ID = process.env.REACT_APP_INTERCOM_USER_ID;
export const ENV_INTERCOM_NAME = process.env.REACT_APP_INTERCOM_NAME;
export const ENV_INTERCOM_EMAIL = process.env.REACT_APP_INTERCOM_EMAIL;
export const ENV_INTERCOM_CREATED_AT = Number(process.env.REACT_APP_INTERCOM_CREATED_AT);

export const PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY;
export const API_BASEURL = process.env.REACT_APP_API_URL || `http ://localhost:5000/api/v1`;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const ENV_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const FOALA_TEXT_EDITOR_KEY = process.env.REACT_APP_FROALA_TEXT_EDITOR_KEY;

export const API_RESPONSE_TYPE = "json";
