import { formatDateIntoDDMYYYY, getDateRange } from 'utility/utils';
import MiniCalendar from 'components/common/calendar/MiniCalendar';
import useOutsideClick from 'hooks/useClickOutsideHook';

type DateRangeSelectorProps = {
  selectedDate: any;
  setSelectedDate: (val: any) => void;
  setShowCalendar: (val: boolean) => void;
  right?: string;
  top?: string;
};

const DateRangeSelector = (props: DateRangeSelectorProps) => {
  const { selectedDate, setSelectedDate, setShowCalendar, right, top } = props;
  const ref = useOutsideClick(() => setShowCalendar(false));

  const handleCalendarDate = (range: string) => {
    if (range === 'all') {
      setSelectedDate([new Date('1970-01-01T00:00:00.000Z'), new Date()]);
      return;
    }
    const dateRange = getDateRange(range);
    dateRange && setSelectedDate(dateRange);
  };

  // const handleDateChange = () => {
  //     setSelectedDate(localDate)
  //     setShowCalendar(false)
  // }
  return (
    <div className={`absolute z-10 ${top || 'top-0'} ${right || 'right-[7.2rem]'} flex cursor-pointer rounded-md border border-[#EAECF0] bg-white shadow-bottom dark:bg-darkOne dark:border-darkThree dark:hover:border-darkTwo`} ref={ref}>
      <div className="flex w-32 cursor-pointer flex-col gap-2.5 border-[1px] border-[#EAECF0] p-3">
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('today')}>
          Today
        </h5>
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('yesterday')}>
          Yesterday
        </h5>
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('this_week')}>
          This week
        </h5>
        {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold dark:hover:text-black dark:text-white hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('last_week')}>Last week</h5> */}
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('this_month')}>
          This month
        </h5>
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('last_month')}>
          Last month
        </h5>
        {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold dark:hover:text-black dark:text-white hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('this_year')}>This year</h5> */}
        {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold dark:hover:text-black dark:text-white hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('last_year')}>Last year</h5> */}
        <h5 className="rounded-sm px-3 text-[0.76rem] font-medium text-heading hover:bg-[#f3f3f5] hover:font-semibold dark:hover:text-black dark:text-white" onClick={() => handleCalendarDate('all')}>
          All time
        </h5>
        <div className="flex w-full flex-col items-center justify-center gap-2.5 p-3 text-column">
          <div className="w-full rounded-md border border-[#7090B04F] px-1 py-1">
            <h5 className="text-[0.7rem] font-medium">{formatDateIntoDDMYYYY(selectedDate?.[0])}</h5>
          </div>
          <div className="w-full rounded-md border border-[#7090B04F] px-1 py-1">
            <h5 className="text-[0.7rem] font-medium">{formatDateIntoDDMYYYY(selectedDate?.[1])}</h5>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1 py-1">
        {/* <h5 className='text-sm font-semibold text-heading px-3 pt-2 pb-1'>Date Range</h5> */}
        {/* <div className='border border-[#7090B04F] rounded-md px-2 py-1 mx-3 w-42'>
                        <h5 className='text-[0.8rem] font-medium text-column'>Custom Range</h5>
                    </div>
                <div className='flex justify-center items-center p-3 gap-2.5 w-full text-column'>
                    <div className='border border-[#7090B04F] rounded-md px-2 py-1 w-full'>
                        <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.from)}</h5>
                    </div>
                    <div className='border border-[#7090B04F] rounded-md px-2 py-1 w-full'>
                        <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.to)}</h5>
                    </div>
                </div> */}
        <MiniCalendar
          range={true}
          width="w-[250px]"
          value={selectedDate}
          onChange={(val: any) => {
            setSelectedDate(val);
          }}
        />
        {/* <MiniCalendar width='w-[250px]' parentClassName='border border-[1px] border-[#EAECF0]' value={localDate.to} onChange={(val: any) => setLocalDate((prev: any) => ({ ...prev, to: val }))} /> */}
        {/* <div className='flex justify-between border-[1px] border-[#EAECF0]'>
                    <div className='flex items-center p-3 gap-2'>
                        <div className='border border-[#7090B04F] rounded-md px-2 py-1'>
                            <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.from)}</h5>
                        </div>
                        <span>-</span>
                        <div className='border border-[#7090B04F] rounded-md px-2 py-1'>
                            <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.to)}</h5>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center p-3'>
                        <Button name='Cancel' color='heading' bg='white' customClass='px-2 font-medium' onClick={()=> setShowCalendar(false)}/>
                        <Button name='Apply' customClass='px-3 font-medium' onClick={handleDateChange} />
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default DateRangeSelector;