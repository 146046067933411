/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { updateTableQueryState } from "redux-store/common-table";

const InfiniteScrollSkeletonLoader = (props: any) => {
    const dispatch = useAppDispatch();
    const commonTableList = useAppSelector((state) => state?.commonTableList);
    const { numColumns = 5, numRows = 10, tableRef, source } = props;
    useEffect(() => {
        const handleScroll = () => {
            if (!tableRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && commonTableList?.[source]?.data?.length < commonTableList?.[source]?.total_records?.count) {
                dispatch(updateTableQueryState({ infinite_scroll_loading: true, offset: Math.ceil(commonTableList?.[source]?.data?.length / commonTableList?.limit) + 1 }))
            }
        };
        const currentRef = tableRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [tableRef, commonTableList]);
    return (
        <>
            {(commonTableList?.[source] && commonTableList?.infinite_scroll_loading) && Array.from({ length: numRows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array.from({ length: numColumns }).map((_, colIndex) => (
                        <td key={colIndex} className={"p-4"}>
                            <p className="py-1 bg-gray-500 dark:!bg-darkBorder animate-pulse"></p>
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
};
export default InfiniteScrollSkeletonLoader
