import { emailVerifierDashboardPieChartOption, EVDashboardBulkVerifierBarChartOption, EVDashboardSingleVerifierBarChartOption, EVDashboardWorldMapChartOptionFn } from 'dto/initial-value/views/dashboard';
import React from 'react';
import { useSmartSetState } from 'hooks/useSmartSetState';
import DashBoardHeader from '../components/DashBoardHeader';
import { BaseApi } from 'api/services/base-api';
import moment from 'moment';
import { _isArray } from 'utility/utils';
import { Spinner } from '@chakra-ui/react';
import { HighChart } from 'components/common/Chart/HighChart';
import { useAppSelector } from 'redux-store';

const EmailVerifierDashBoard = () => {
  const [EVDashboardWorldMapChartOption, setOption] = React.useState(null);
  const [dashBoardStats, setDashboardStats] = React.useState<any>({
    loading: true,
    stats: {},
  });
  const theme = useAppSelector((state) => state?.app?.theme);

  const smartStateUpdate = useSmartSetState(setDashboardStats);

  React.useEffect(() => {
    (async () => {
      const option = await EVDashboardWorldMapChartOptionFn();
      setOption(option);
    })();

    // Stats fetch
    (async () => {
      try {
        smartStateUpdate({ loading: true });
        const start_date = moment().subtract(30, 'days').format('YYYY/MM/DD');
        const end_date = moment().format('YYYY/MM/DD');
        const response = await BaseApi.get(`/dashboard/email/verify?start_date=${start_date}&end_date=${end_date}`);
        smartStateUpdate({ stats: response?.data });
      } catch (error) {
        console.log('Stats fetch error: ', error);
      } finally {
        smartStateUpdate({ loading: false });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOverAllStatsSeries = () => {
    return [
      {
        name: 'Emails',
        data: [
          { name: 'Safe', y: dashBoardStats?.stats?.overall_stats?.safe || 0, color: '#14B8A6' },
          { name: 'Unsafe', y: dashBoardStats?.stats?.overall_stats?.unsafe || 0, color: '#F50B0B' },
          { name: 'Unknown', y: dashBoardStats?.stats?.overall_stats?.unknown || 0, color: '#F59E0B' },
        ],
      },
    ];
  };

  const getGeoLocationSeries = () => {
    return [
      {
        data: dashBoardStats?.stats?.geo_location_stats?.map((item: any) => {
          const colors: any = { safe: '#14B8A6', unsafe: '#F50B0B', unknown: '#F59E0B' };

          let choseColor: string = 'safe';
          if (item?.unsafe > item[choseColor]) {
            choseColor = 'unsafe';
          }
          if (item?.unknown > item[choseColor]) {
            choseColor = 'unknown';
          }
          return {
            'hc-key': item?.country_code?.toLowerCase(),
            color: colors[choseColor],
            safe: item?.safe || 0,
            unsafe: item?.unsafe || 0,
            unknown: item?.unknown || 0,
          };
        }),
        name: 'Email',
      },
    ];
  };

  const getBulkEVStats = () => {
    return [
      {
        name: 'Safe',
        color: '#14B8A6',
        data: dashBoardStats?.stats?.bulk_verifier_stats?.map((item: any) => item?.safe) || [],
      },
      {
        name: 'UnSafe',
        color: '#F50B0B',
        data: dashBoardStats?.stats?.bulk_verifier_stats?.map((item: any) => item?.unsafe) || [],
      },
      {
        name: 'Unknown',
        color: '#F59E0B',
        data: dashBoardStats?.stats?.bulk_verifier_stats?.map((item: any) => item?.unknown) || [],
      },
    ];
  };

  const getSingleEVStats = () => {
    return [
      {
        name: 'Safe',
        color: '#14B8A6',
        data: dashBoardStats?.stats?.single_verifier_stats?.map((item: any) => [item?.date, item?.safe]) || [],
      },
      {
        name: 'UnSafe',
        color: '#F50B0B',
        data: dashBoardStats?.stats?.single_verifier_stats?.map((item: any) => [item?.date, item?.unsafe]) || [],
      },
      {
        name: 'Unknown',
        color: '#F59E0B',
        data: dashBoardStats?.stats?.single_verifier_stats?.map((item: any) => [item?.date, item?.unknown]) || [],
      },
    ];
  };

  return (
    <div className="mt-3 rounded-md bg-white shadow-md">
      {/* Header */}
      <DashBoardHeader initialLoading={dashBoardStats?.loading} smartStateUpdate={smartStateUpdate} />

      {/* Graphs */}
      <div className={`grid h-[568px] grid-cols-6 gap-4 overflow-y-auto p-4 ${dashBoardStats?.loading ? 'blur-sm' : ''} dark:bg-darkBlack dark:border-darkThree dark:text-white`}>
        {/* PIE CHART */}
        <div className="dark:bg-darkBlack col-span-2 flex h-[21.25rem] flex-col items-center justify-center rounded-md border p-2 dark:border-darkThree dark:text-white">
          <HighChart
            title="Overall Email Verification Analytics"
            extraContent={<p className="text-sm">Please start verification to view this graph.</p>}
            chartOptions={emailVerifierDashboardPieChartOption(theme)}
            series={getOverAllStatsSeries()}
            isDataNotExist={(_isArray(dashBoardStats?.stats?.overall_stats) && dashBoardStats?.stats?.overall_stats?.length === 0) || dashBoardStats.loading}
          />
        </div>
        {/* WORLD MAP CHART */}
        <div className="dark:bg-darkBlack col-span-4 flex h-[21.25rem] flex-col items-center justify-center rounded-md border p-2 dark:border-darkThree dark:text-white">
          {EVDashboardWorldMapChartOption && (
            <HighChart title="Geo-Heatmap" extraContent={<p className="text-sm">Please start verification to view this graph.</p>} chartOptions={EVDashboardWorldMapChartOption} constructorType="mapChart" series={getGeoLocationSeries()} isDataNotExist={dashBoardStats?.stats?.geo_location_stats?.length === 0 || dashBoardStats.loading} />
          )}
        </div>
        {/* BULK VERIFIER BAR CHART */}
        <div className="dark:bg-darkBlack col-span-3 flex h-[21.25rem] flex-col items-center justify-center rounded-md border p-2 dark:border-darkThree dark:text-white">
          <HighChart
            title="Bulk Verification"
            extraContent={<p className="text-sm">Please start verification to view this graph.</p>}
            chartOptions={EVDashboardBulkVerifierBarChartOption(
              dashBoardStats?.stats?.bulk_verifier_stats?.map((item: any) => item?.list_name),
              theme
            )}
            series={getBulkEVStats()}
            isDataNotExist={dashBoardStats?.stats?.bulk_verifier_stats?.length === 0 || dashBoardStats.loading}
          />
        </div>
        {/* SINGLE VERIFIER BAR CHART */}
        <div className="dark:bg-darkBlack col-span-3 flex h-[21.25rem] flex-col items-center justify-center rounded-md border p-2 dark:border-darkThree dark:text-white">
          <HighChart title="Single verification" extraContent={<p className="text-sm">Please start verification to view this graph.</p>} chartOptions={EVDashboardSingleVerifierBarChartOption(theme)} series={getSingleEVStats()} isDataNotExist={dashBoardStats?.stats?.single_verifier_stats?.length === 0 || dashBoardStats.loading} />
        </div>
      </div>
      {dashBoardStats?.loading ? (
        <div className="absolute left-1/2 top-1/2">
          <Spinner size={'lg'} textColor={'GrayText'} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailVerifierDashBoard;
