import React from 'react';

const Tabs = (props: { tabs: { id: string; name: string }[]; onChange?: (index: number) => void }) => {
  const { tabs, onChange } = props;
  const [activeTab, setActiveTab] = React.useState(0);
  const handleClick = (index: number) => {
    setActiveTab(index);
    onChange?.(index);
  };

  return (
    <div className={`flex w-full gap-3 rounded-md bg-white p-3 dark:bg-darkTwo`}>
      {tabs?.map((item, index) => {
        return (
          <button key={item?.id} onClick={() => handleClick(index)} className={`group relative pb-2 text-[12px] ${activeTab === index ? 'text-blueSecondary  dark:text-blue-400' : 'text-column hover:text-blueSecondary dark:text-[#B0B7C3]'}`}>
            <span className="px-1 font-medium ">{item?.name}</span>
            <div className={`absolute bottom-0 h-[.0825rem] w-full rounded-md bg-blueSecondary dark:bg-blue-400 ${activeTab === index ? '' : 'hidden group-hover:block'}`}></div>
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
