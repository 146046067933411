export const emailVerifierDashboardPieChartOption = (theme: string): Highcharts.Options => ({
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    height: 310,
    width: 320,
  },
  title: {
    text: null as unknown as string, // Explicitly setting null with type assertion
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: theme === 'dark' ? '#FFFFFF' : '#000000', // Legend text color
      fontSize: '12px',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      borderRadius: 0,
      dataLabels: {
        enabled: false,
        useHTML: true, // Allows inline styling
        formatter: function (this: any) {
          return `<span style="color: ${theme === 'dark' ? '#FFFFFF' : '#000000'}; font-size: 12px; font-weight: bold;">${this.name}: ${this.y}</span>`;
        },
      },
      showInLegend: true,
    },
  },
});

export const EVDashboardSingleVerifierBarChartOption = (thems: string): any => ({
  chart: {
    backgroundColor: 'transparent',
    zooming: {
      mouseWheel: {
        type: 'x',
      },
    },
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    type: 'datetime',
    lineWidth: 0,
    tickWidth: 0,
    ...(thems === 'dark'
      ? {
          labels: {
            style: {
              color: '#FFFFFF', // White in dark mode, Black in light mode
            },
          },
        }
      : {
          labels: {
            style: {
              color: '#000', // White in dark mode, Black in light mode
            },
          },
        }),
  },
  yAxis: [
    {
      title: {
        text: null,
      },
      ...(thems === 'dark'
        ? {
            labels: {
              style: {
                color: '#FFFFFF', // White in dark mode, Black in light mode
              },
            },
          }
        : {
            labels: {
              style: {
                color: '#000', // White in dark mode, Black in light mode
              },
            },
          }),
      showFirstLabel: false,
    },
  ],
});
export const EVDashboardBulkVerifierBarChartOption = (lists: string[], theme: string): any => ({
  chart: {
    type: 'bar',
    height: 310,
    backgroundColor: 'transparent',
    // zooming: {
    //   mouseWheel: {
    //     type: 'xy',
    //   },
    // },
    // scrollablePlotArea: {
    //   minHeight: 800,
    //   opacity: 1,
    // },
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    // type: 'datetime',
    categories: lists,
    lineWidth: 0,
    tickWidth: 0,
    ...(theme === 'dark'
      ? {
          labels: {
            style: {
              color: '#FFFFFF', // White in dark mode, Black in light mode
            },
          },
        }
      : {
          labels: {
            style: {
              color: '#000', // White in dark mode, Black in light mode
            },
          },
        }),
  },
  yAxis: {
    title: {
      text: null,
    },
    ...(theme === 'dark'
      ? {
          labels: {
            style: {
              color: '#FFFFFF', // White in dark mode, Black in light mode
            },
          },
        }
      : {
          labels: {
            style: {
              color: '#000', // White in dark mode, Black in light mode
            },
          },
        }),
    showFirstLabel: false,
  },
  plotOptions: {
    //   bar: {
    //     pointPadding: 0.1,
    //     groupPadding: 0,
    //     minPointLength: 1
    // },
    series: {
      stacking: 'normal',
      // minPointLength: 1,
      // maxPointLength: 1,
      groupPadding: 0.2,
      /* dataLabels: {
            enabled: false
        } */
    },
  },
});
export async function EVDashboardWorldMapChartOptionFn(): Promise<any> {
  const topology = await fetch('https://code.highcharts.com/mapdata/custom/world-highres.topo.json').then((response) => response.json());
  return {
    chart: {
      map: topology,
      width: 800,
      backgroundColor: 'transparent',
      //   height: 330,
    },
    title: {
      text: null,
    },
    // mapNavigation: {
    //   enabled: true,
    //   buttonOptions: {
    //     verticalAlign: 'bottom',
    //   },
    // },
    legend: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<b>{point.key}</b>:<br/>',
      pointFormat: `<div>
      <div class='py-1 flex items-center gap-1'> <div class='rounded-full size-2 bg-teal-500'></div><b>Safe:</b> {point.safe}</div>
      <div class='py-1 flex items-center gap-1'> <div class='rounded-full size-2 bg-red-error'></div><b>Unsafe:</b> {point.unsafe}</div>
      <div class='py-1 flex items-center gap-1'> <div class='rounded-full size-2 bg-amber-500'></div><b>Unknown:</b> {point.unknown}</div>
      </div>`,
    },
  };
}
